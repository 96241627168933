import React, { useEffect, useState } from 'react'
import { defaultImagePath, timeconvert } from '../../../utility/utility'
import Oux from '../../../hoc/Oux/Oux'
import Skeleton from 'react-loading-skeleton';
import Timer from 'react-compound-timer'
import { OrderStatus, ViewOrderLimitPerPage, routes, UserRoles } from '../../../utility/constants/constants';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment'
import LoadingBtn from '../../UI/LoadingButton/LoadingButton';
import SpinnerLoader from '../../UI/SpinnerLoader/SpinnerLoader';

const CustomersArrived = props => {
    console.log(props, "arrivedPage")
    let [timerCount, setTimerCount] = useState(1)
    useEffect(() => {
        let status = props.match.params.status;
        if (status === OrderStatus.CUSTOMER_ARRIVED.key) {
            const interval = setInterval(() => {
                setTimerCount(timerCount => timerCount + 1);
            }, 60000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [timerCount])

    const orderDetails = (order, event) => {
        // debugger;
        if (event.target.id !== "action_btn") {
            props.history.push({pathname: `${routes.ORDER_DETAIL}/${order.OrderNumber}`, state: {isInvoice: false}});
        }
        props.orderSummeryDetails(order);
    }

    return (
        <div class="row">
            <div class="col-lg-12">
                <div class="table-responsive">
                    {
                        props.isLoading
                            ? <div style={{ lineHeight: 2 }} className="mb-2">
                                <Skeleton count={5} height={100} />
                            </div>
                            : props.orders && props.orders.length > 0 && props.orders.filter(item => item.LocalOrderStatus === OrderStatus.CUSTOMER_ARRIVED.apiKey && !(item.OrderStatus === OrderStatus.INVOICED.apiKey || item.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)).length > 0
                                ? <table class="table table-borderless text-nowrap ims_table ims_grid_table">
                                    <tbody>
                                        {
                                            props.orders.filter(item => item.LocalOrderStatus === OrderStatus.CUSTOMER_ARRIVED.apiKey && !(item.OrderStatus === OrderStatus.INVOICED.apiKey || item.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)).map((order, index) => {
                                                let arrivedTime = moment(order.ArrivedTime).format('YYYY-MM-DDTHH:mm:ss.000')
                                                arrivedTime = new Date(arrivedTime)
                                                let currentTime = new Date()
                                                console.log(arrivedTime, "arrivedTime", currentTime, "currentTime")
                                                console.log(order.OrderNumber)
                                                // console.log(arrivedTime, "arrivedTime")
                                                // console.log(currentTime, "currentTime")
                                                let diffTime = moment(currentTime).diff(moment(arrivedTime), "minutes")
                                                // console.log(diffTime, "diffTime")
                                                let arrivedBorderColorClass = "ims_Lborder Lborder_succsess"
                                                if (diffTime > 10 && diffTime <= 20) {
                                                    arrivedBorderColorClass = "ims_Lborder Lborder_warning"
                                                } else if (diffTime > 20 && diffTime <= 40) {
                                                    arrivedBorderColorClass = "ims_Lborder Lborder_red"
                                                } else if (diffTime > 40) {
                                                    arrivedBorderColorClass = "ims_Lborder Lborder_blue"
                                                }
                                                let disableTableRow = false
                                                if (props.orderToUpdate) {
                                                    disableTableRow = props.orderToUpdate.OrderNumber === order.OrderNumber && props.isOrderUpdating
                                                }

                                                return (
                                                    <Oux key={index}>
                                                        <tr style={disableTableRow ? { opacity: 0.5 } : null} className="non_href_a_tag" onClick={(event) => orderDetails(order, event)}>
                                                            <td class={arrivedBorderColorClass}>
                                                                <article class="ims_art">
                                                                    <h6 class="font-weight-bold text-uppercase text-primary mb-0">Arrived <Moment format="hh:mm A">{arrivedTime}</Moment> <img class="ml-1" src={defaultImagePath('custom_images/icn_clock.svg')} /></h6>
                                                                    <h2 class="font-weight-bold mx_eql">
                                                                        {order.CompanyName}
                                                                    </h2>
                                                                    <h5 class="ft_Weight_600 mb-0">
                                                                        {order.ContactName}
                                                                        {
                                                                            order.ContactNumber
                                                                                ? <button type="button" class="tooltip_btn" data-toggle="tooltip" data-placement="top" title="" data-original-title={order.ContactNumber}>
                                                                                    <img src={defaultImagePath('icons/icn_not.png')} alt="Info Icon" />
                                                                                </button>
                                                                                : null
                                                                        }
                                                                    </h5>
                                                                </article>
                                                            </td>
                                                            <td>
                                                                <div class="ims_grid_id">
                                                                    <h4 class="ft_Weight_600 text-graydark1">Order Number</h4>
                                                                    <h1 class="ft_Weight_600 text-graydark1 mb-0">{order.OrderNumber}</h1>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="ims_grid_id">
                                                                    <div class="ims_info my-0">
                                                                        <h4 class="ft_Weight_600"><span class="ims_info_title">Parking Slot</span></h4>
                                                                        <h1 class="ft_Weight_600 mb-0">{order.ParkingSlot ? `${order.ParkingSlot}` : '-'}</h1>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="text-center">
                                                                <div class="ims_grid_id">
                                                                    <div class="ims_info ims_time_info my-0">
                                                                        <h4 class="ft_Weight_600"><span class="ims_info_title">Waiting time</span></h4>
                                                                        <h4 class="ft_Weight_600 text-graylight mb-0">
                                                                            <span class="ims_info_val text-tertiary mx-auto">
                                                                                <Timer initialTime={diffTime}
                                                                                    direction="forward">
                                                                                    {() => (
                                                                                        <span>{diffTime} Minutes</span>
                                                                                    )}
                                                                                </Timer>
                                                                            </span>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {
                                                                props.user && props.user.role.toLowerCase() === UserRoles.manager.toLowerCase()
                                                                    ? <td id="action_btn">
                                                                        <div class="dropdown ims_drop_inner" id="action_btn">
                                                                            {
                                                                                disableTableRow
                                                                                    ? <SpinnerLoader className="ht_30" />
                                                                                    : <a class="dropdown-toggle" id="action_btn" role="button" id="actionMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                        <span class="icn_w20" id="action_btn">
                                                                                            <img id="action_btn" src={defaultImagePath('icons/icn_more.png')} alt="More" />
                                                                                        </span>
                                                                                    </a>
                                                                            }
                                                                            <div id="action_btn" class="dropdown-menu" aria-labelledby="actionMenu">
                                                                                <a id="action_btn" class="dropdown-item non_href_a_tag" onClick={() => {
                                                                                    window.$('#reason_text_modal').modal('toggle');
                                                                                    props.storeOrderToUpdate(order)
                                                                                    props.toggleReasonToMoveOrdersModalStatus(true, OrderStatus.PICK_CONFIRMED.key)
                                                                                }}>Pick Confirmed</a>
                                                                                <a id="action_btn" class="dropdown-item non_href_a_tag" onClick={() => {
                                                                                    window.$('#reason_text_modal').modal('toggle');
                                                                                    props.storeOrderToUpdate(order)
                                                                                    props.toggleReasonToMoveOrdersModalStatus(true, OrderStatus.INVOICED.key)
                                                                                }}>Order Completed </a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    : null
                                                            }
                                                        </tr>
                                                        <tr class="ims_tr_gap" style={{ height: '15px' }}></tr>
                                                    </Oux>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                : <div class="table table-borderless text-nowrap ims_table ims_grid_table">
                                    <h2 className="text-center"><b>No results found</b></h2>
                                </div>
                    }
                </div>
            </div>
        </div >
    )
}

export default CustomersArrived