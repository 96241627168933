import { branch } from 'recompose';
import axios, { API_VERSION } from '../config';

export const fetchOrdersByBranchID = (branchID, limit, pageNumber, status, start_date, end_date) => {
    if (!limit && !pageNumber) {
        return axios.get(API_VERSION + `orders?BranchID=${branchID}&status=[${status}]&start_date=${start_date}&end_date=${end_date}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            // defaultErrorHandler: false
        });
    } else if (start_date && end_date) {
        return axios.get(API_VERSION + `orders?limit=${limit}&page=${pageNumber}&status=[${status}]&start_date=${start_date}&end_date=${end_date}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            // defaultErrorHandler: false
        });
    }
    return axios.get(API_VERSION + `orders?limit=${limit}&page=${pageNumber}&status=[${status}]`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const updateOrder = (orderID, order) => {
    return axios.put(API_VERSION + `orders/${orderID}`, order, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const searchOrderByOrderNumber = (orderNumber, directSearch, status) => {
    if (directSearch) {
        var regExp = /[a-zA-Z]/g;
        let searchType;
        regExp.test(orderNumber) ? searchType = '?name' : searchType = '?OrderNumber'
        return axios.get(API_VERSION + `orders/search${searchType}=${orderNumber}&status=(${status})`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            // defaultErrorHandler: false
        });
    }
    return axios.get(API_VERSION + `orders/search?OrderNumber=${orderNumber}&status=(${status})`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const searchOrderByPhoneNumber = (phoneNumber) => {
    return axios.get(API_VERSION + `search?phone=${phoneNumber}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const fetchOrderSummary = (start_date, end_date, branchID) => {
    return axios.get(API_VERSION + `orders/summary?start_date=${start_date}&end_date=${end_date}&BranchID=${branchID}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
}


export const toggleNotifycation = (status) => {
    return axios.put(API_VERSION + `cron/notifications?status=${status}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const toggleBranchNotification = (status, branchCode) => {
    return axios.put(API_VERSION + `branch/${branchCode}/settings`, status, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const fetchSettings = () => {
    return axios.get(API_VERSION + `cron/settings`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const fetchBranchSettings = (branchCode) => {
    return axios.get(API_VERSION + `branch/${branchCode}/settings`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const exportData = (start_date, end_date, BranchID) => {
    return axios.get(API_VERSION + `orders/export?BranchCode=${BranchID}&start_date=${start_date}&end_date=${end_date}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const fetchOrderDetails = (orderNumber) => {
    return axios.get(API_VERSION + `orders/detail/${orderNumber}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    });
}


export const fetchOrderLogs = (start_date, end_date, pageNumber, limit, branchCode) => {
    return axios.get(API_VERSION + `orders/logs?start_date=${start_date}&end_date=${end_date}&page=${pageNumber}&limit=${limit}&BranchCode=${branchCode}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const fetchNotificationLogs = (start_date, end_date, pageNumber, limit, branchCode) => {
    return axios.get(API_VERSION + `orders/notification/logs?start_date=${start_date}&end_date=${end_date}&page=${pageNumber}&limit=${limit}&BranchCode=${branchCode}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    });
}

export const fetchUserAuditLogs = (start_date, end_date, pageNumber, limit, id, branchCode) => {
    return axios.get(API_VERSION + `user/logs?start_date=${start_date}&end_date=${end_date}&page=${pageNumber}&limit=${limit}&BranchCode=${branchCode}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    });
}