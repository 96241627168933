import React, { useEffect, useState } from 'react'
import DashboardPage from '../../components/DashboardPage/DashboardPage'
import { connect } from 'react-redux'
import * as actions from '../../redux/actions/index'
import moment from 'moment'
import { DateFilterTypes } from '../../utility/constants/constants'
import ComingSoon from '../ComingSoon/ComingSoon'
import Oux from '../../hoc/Oux/Oux'
import ExportDataForm from '../../components/ExportDataForm/ExportDataForm'

const Dashboard = (props) => {

    const [pageNumber, setPageNumber] = useState(1)
    const [activeDateFilter, setActiveDateFilter] = useState(DateFilterTypes.MONTH)

    useEffect(() => {
        if (props.selectedBranch) {
            let end_date = new Date()
            let start_date = new Date().setDate(end_date.getDate() - 30)
            start_date = new Date(start_date)
            start_date = moment(start_date).format('MM-DD-YYYY')
            end_date = moment(end_date).format('MM-DD-YYYY')
            console.log(end_date, start_date, "dateDahsboard")
            props.fetchOrderSummary(start_date, end_date, props.selectedBranch.BranchID)
        }
    }, [props.selectedBranch])

    const changePageNumber = () => {
        setPageNumber(pageNumber + 1)
    }

    const onClickDateFilter = (dateFilterType) => {
        setActiveDateFilter(dateFilterType)
        if (dateFilterType === DateFilterTypes.MONTH) {
            let end_date = new Date()
            let start_date = new Date().setDate(end_date.getDate() - 30)
            start_date = new Date(start_date)
            start_date = moment(start_date).format('MM-DD-YYYY')
            end_date = moment(end_date).format('MM-DD-YYYY')
            props.fetchOrderSummary(start_date, end_date, props.selectedBranch.BranchID)
        } else if (dateFilterType === DateFilterTypes.WEEK) {
            let end_date = new Date()
            let start_date = new Date().setDate(end_date.getDate() - 7)
            start_date = new Date(start_date)
            start_date = moment(start_date).format('MM-DD-YYYY')
            end_date = moment(end_date).format('MM-DD-YYYY')
            props.fetchOrderSummary(start_date, end_date, props.selectedBranch.BranchID)
        } else if (dateFilterType === DateFilterTypes.YEAR) {
            let end_date = new Date()
            let start_date = new Date().setFullYear(end_date.getFullYear() - 1)
            start_date = new Date(start_date)
            start_date = moment(start_date).format('MM-DD-YYYY')
            end_date = moment(end_date).format('MM-DD-YYYY')
            props.fetchOrderSummary(start_date, end_date, props.selectedBranch.BranchID)
        }
        else if (dateFilterType === DateFilterTypes.TODAY) {
            let end_date = new Date()
            let start_date = new Date()
            start_date = new Date(start_date)
            start_date = moment(start_date).format('MM-DD-YYYY')
            end_date = moment(end_date).format('MM-DD-YYYY')
            props.fetchOrderSummary(start_date, end_date, props.selectedBranch.BranchID)
        }
    }

    return (
        <Oux>
            {/* <DashboardPage {...props} activeDateFilter={activeDateFilter} onClickDateFilter={onClickDateFilter} changePageNumber={changePageNumber} pageNumber={pageNumber} /> */}

            <ComingSoon selectedBranch={props.selectedBranch} />
        </Oux>

    )

}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        branches: state.configReducer.branches,
        orders: state.orderReducer.orders,
        ready_count: state.orderReducer.ready_count,
        arrived_count: state.orderReducer.arrived_count,
        delivered_count: state.orderReducer.delivered_count,
        orderSummaryDataSet: state.orderReducer.orderSummaryDataSet,
        orderSummarResults: state.orderReducer.orderSummarResults,
        isOrderLoading: state.orderReducer.isLoading,
        selectedBranch: state.orderReducer.selectedBranch,
        isConfigLoading: state.configReducer.isConfigLoading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrderSummary: (start_date, end_date, branchID) => dispatch(actions.fetchOrderSummary(start_date, end_date, branchID)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)