import React from 'react'
import ExportData from '../../containers/ExportData/ExportData'
import { defaultImagePath } from '../../utility/utility'


const ComingSoonPage = props => {
    return (
        <section className="theme_nav_mt ims_sec">
            <div className="container-fluid ims_mx30">
                <div id="wrap">
                    <ExportData />
                    <div class="big_art text-center">
                        <img src={defaultImagePath('background/coming_soon.png')} className="img-fluid" alt="Arrived" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ComingSoonPage