import { UserActionTypes } from '../../actions/usersActions/actionType';

export const initialState = {
    usersList: [],
    isLoading: false,
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case UserActionTypes.ADD_USER_PENDING:
            return updateObject(state, { isLoading: true });
        case UserActionTypes.ADD_USER_FULFILLED:
            return updateObject(state, { isLoading: action.payload && action.payload.stopLoader ? false : true });

        case UserActionTypes.UPDATE_USER_PENDING:
            return updateObject(state, { isLoading: true });
        case UserActionTypes.UPDATE_USER_FULFILLED:
            return updateObject(state, { isLoading: action.payload && action.payload.stopLoader ? false : true });

        case UserActionTypes.DELETE_USER_PENDING:
            return updateObject(state, { isLoading: true });
        case UserActionTypes.DELETE_USER_FULFILLED:
            return updateObject(state, { isLoading: action.payload && action.payload.stopLoader ? false : true });

        case UserActionTypes.FETCH_USERS_LIST_PENDING:
            return updateObject(state, { isLoading: true });
        case UserActionTypes.FETCH_USERS_LIST_FULFILLED:
            return updateObject(state, { isLoading: false, usersList: action.payload && action.payload.users ? action.payload.users : [] });

        default: return state;
    }
}