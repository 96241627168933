import { HistoryActionTypes, MiscActionTypes } from '../../actions/usersActions/actionType';

const initialState = {
    history: null,
    arrivedForDeliveryModalStatus: false,
    reasonToMoveOrdersModalStatus: false,
    movingOrderType: null,
    isSocketDisconnected: false
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case HistoryActionTypes.ADD_HISTORY:
            return updateObject(state,
                {
                    history: action.payload
                });

        case MiscActionTypes.ARRIVED_FOR_DELIVERY_MODAL_STATUS:
            return updateObject(state,
                {
                    arrivedForDeliveryModalStatus: action.payload
                });

        case MiscActionTypes.REASON_TO_MOVE_ORDERS_MODAL_STATUS:
            return updateObject(state,
                {
                    reasonToMoveOrdersModalStatus: action.payload.status,
                    movingOrderType: action.payload.orderType
                });

        case MiscActionTypes.SOCKET_STAUS:
            return updateObject(state,
                {
                    isSocketDisconnected: action.payload
                });

        default: return state;
    }
}