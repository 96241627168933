import * as Yup from 'yup';

export const validateLoginForm =
    Yup.object().shape({
        email: Yup.string().email('Please Enter a Valid Email').required('This field is required').nullable(),
        password: Yup.string().min(6, "Minimum 6 characters").required("This field is required").nullable(),
    })
export const validateAddUserForm =
    Yup.object().shape({
        email: Yup.string().email('Please Enter a Valid Email').required('This field is required').nullable(),
        first_name: Yup.string().required("This field is required").nullable(),
        password: Yup.string().min(6, "Minimum 6 characters").nullable(),
        last_name: Yup.string().required("This field is required").nullable(),
        role: Yup.string().required("This field is required").nullable(),
        branch_name: Yup.string().min(4, "Minimum 4 characters").required("This field is required").nullable(),
    })
export const exportDataForm =
    Yup.object().shape({
        start_date: Yup.string().required("This field is required").nullable(),
        end_date: Yup.string().required("This field is required").nullable(),
    })