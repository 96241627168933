import React from 'react'
import { Form, Formik, Field } from 'formik'
import { validateLoginForm } from '../../utility/validator/formValidation'
import { routes, formInputTextErrorStyle, formInputErrorStyle } from '../../utility/constants/constants';
import LoadingBtn from '../UI/LoadingButton/LoadingButton';

const loginForm = props => {

    let initialFormValues = { 
        email: '',
        password: ''
    }

    return (
        <section class="home_hero" style={{ backgroundImage: 'url(/images/background/hero.png)' }}>
            <div class="wrap-login100">
                <Formik
                    enableReinitialize={true}
                    initialValues={initialFormValues}
                    onSubmit={(values) => {
                        console.log(values, 'Values')
                        props.login({ ...values })
                    }}
                    validationSchema={validateLoginForm}>
                    {(formik_props) => {
                        const errors = formik_props.errors
                        const touched = formik_props.touched
                        return (
                            <Form className="login100-form validate-form">
                                <span className="login100-form-title">
                                    Sign in
                                </span>
                                <div className="form_fields">
                                    <div className="form_group_modify">
                                        <label className="label_modify">Email</label>
                                        <Field className="input_modify" style={errors.email && touched.email ? formInputErrorStyle : null} type="email" name="email" />
                                        <span style={formInputTextErrorStyle}>{errors.email && touched.email && errors.email}</span>
                                    </div>
                                    <div className="form_group_modify">
                                        <label className="label_modify">Password</label>
                                        <Field className="input_modify" style={errors.password && touched.password ? formInputErrorStyle : null} type="password" name="password" />
                                        <span style={formInputTextErrorStyle}>{errors.password && touched.password && errors.password}</span>
                                    </div>
                                </div>
                                <div className="text-center">
                                    {
                                        props.isLoading
                                            ? <LoadingBtn btnClassName="theme_btn theme_tertiary" btnTitle="Signing In" />
                                            : <button type="submit" className="theme_btn theme_tertiary">Sign in</button>
                                    }
                                </div>
                                {/* <div className="text-right">
                            <a href="forgot_password.html" className="form_link">Forgot Password?</a>
                        </div> */}
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </section>
    )
}

export default loginForm