export const AuthActionTypes = {

    LOGIN: "LOGIN",
    LOGIN_PENDING: "LOGIN_PENDING",
    LOGIN_FULFILLED: "LOGIN_FULFILLED",

    FETCH_CSRF_TOKEN: 'FETCH_CSRF_TOKEN',
    FETCH_CSRF_TOKEN_PENDING: 'FETCH_CSRF_TOKEN_PENDING',
    FETCH_CSRF_TOKEN_FULFILLED: 'FETCH_CSRF_TOKEN_FULFILLED',

    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    FORGOT_PASSWORD_PENDING: "FORGOT_PASSWORD_PENDING",
    FORGOT_PASSWORD_FULFILLED: "FORGOT_PASSWORD_FULFILLED",

    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_PENDING: "RESET_PASSWORD_PENDING",
    RESET_PASSWORD_FULFILLED: "RESET_PASSWORD_FULFILLED",

    AUTHORIZE: "AUTHORIZE",
    LOGOUT: "LOGOUT",

    UPDATE_ROLE: "UPDATE_ROLE",

    UPDATE_USER: "UPDATE_USER",
    UPDATE_USER_PENDING: "UPDATE_USER_PENDING",
    UPDATE_USER_FULFILLED: "UPDATE_USER_FULFILLED",
}

export const HistoryActionTypes = {
    ADD_HISTORY: "ADD_HISTORY"
}

export const MiscActionTypes = {
    ARRIVED_FOR_DELIVERY_MODAL_STATUS: "ARRIVED_FOR_DELIVERY_MODAL_STATUS",
    REASON_TO_MOVE_ORDERS_MODAL_STATUS: "REASON_TO_MOVE_ORDERS_MODAL_STATUS",
    SOCKET_STAUS: 'SOCKET_STAUS'
}


export const ConfigActionTypes = {
    PULL_CONFIG: "PULL_CONFIG",
    PULL_CONFIG_PENDING: "PULL_CONFIG_PENDING",
    PULL_CONFIG_FULFILLED: "PULL_CONFIG_FULFILLED",

    REPORT_ERROR: "REPORT_ERROR",
    REPORT_ERROR_PENDING: "REPORT_ERROR_PENDING",
    REPORT_ERROR_FULFILLED: "REPORT_ERROR_FULFILLED",
}

export const UserActionTypes = {
    ADD_USER: "ADD_USER",
    ADD_USER_PENDING: "ADD_USER_PENDING",
    ADD_USER_FULFILLED: "ADD_USER_FULFILLED",

    UPDATE_USER: "UPDATE_USER",
    UPDATE_USER_PENDING: "UPDATE_USER_PENDING",
    UPDATE_USER_FULFILLED: "UPDATE_USER_FULFILLED",

    DELETE_USER: "DELETE_USER",
    DELETE_USER_PENDING: "DELETE_USER_PENDING",
    DELETE_USER_FULFILLED: "DELETE_USER_FULFILLED",

    FETCH_USERS_LIST: "FETCH_USERS_LIST",
    FETCH_USERS_LIST_PENDING: "FETCH_USERS_LIST_PENDING",
    FETCH_USERS_LIST_FULFILLED: "FETCH_USERS_LIST_FULFILLED",
}

export const OrderActionTypes = {
    FETCH_ORDER_BRANCH_ID: "FETCH_ORDER_BRANCH_ID",
    FETCH_ORDER_BRANCH_ID_PENDING: "FETCH_ORDER_BRANCH_ID_PENDING",
    FETCH_ORDER_BRANCH_ID_FULFILLED: "FETCH_ORDER_BRANCH_ID_FULFILLED",

    FETCH_ORDER_BY_PHONE_NUMBER: "FETCH_ORDER_BY_PHONE_NUMBER",
    FETCH_ORDER_BY_PHONE_NUMBER_PENDING: "FETCH_ORDER_BY_PHONE_NUMBER_PENDING",
    FETCH_ORDER_BY_PHONE_NUMBER_FULFILLED: "FETCH_ORDER_BY_PHONE_NUMBER_FULFILLED",

    UPDATE_ORDER: 'UPDATE_ORDER',
    UPDATE_ORDER_PENDING: 'UPDATE_ORDER_PENDING',
    UPDATE_ORDER_FULFILLED: 'UPDATE_ORDER_FULFILLED',

    FETCH_ORDER_SUMMARY: "FETCH_ORDER_SUMMARY",
    FETCH_ORDER_SUMMARY_PENDING: "FETCH_ORDER_SUMMARY_PENDING",
    FETCH_ORDER_SUMMARY_FULFILLED: "FETCH_ORDER_SUMMARY_FULFILLED",

    STORE_ORDER_TO_UPDATE: 'STORE_ORDER_TO_UPDATE',
    SELECTED_BRANCH: 'SELECTED_BRANCH',
    STORE_LATEST_ORDERS: 'STORE_LATEST_ORDERS',

    SEARCH_ORDER_BY_ORDER_NUMBER: 'SEARCH_ORDER_BY_ORDER_NUMBER',
    SEARCH_ORDER_BY_ORDER_NUMBER_PENDING: 'SEARCH_ORDER_BY_ORDER_NUMBER_PENDING',
    SEARCH_ORDER_BY_ORDER_NUMBER_FULFILLED: 'SEARCH_ORDER_BY_ORDER_NUMBER_FULFILLED',

    LOAD_MORE_ORDERS: 'LOAD_MORE_ORDERS',
    LOAD_MORE_ORDERS_PENDING: 'LOAD_MORE_ORDERS_PENDING',
    LOAD_MORE_ORDERS_FULFILLED: 'LOAD_MORE_ORDERS_FULFILLED',

    TOGGLE_NOTIFY: 'TOGGLE_NOTIFY',
    TOGGLE_NOTIFY_PENDING: 'TOGGLE_NOTIFY_PENDING',
    TOGGLE_NOTIFY_FULFILLED: 'TOGGLE_NOTIFY_FULFILLED',

    TOGGLE_BRANCH_NOTIFICATION: 'TOGGLE_BRANCH_NOTIFICATION',
    TOGGLE_BRANCH_NOTIFICATION_PENDING: 'TOGGLE_BRANCH_NOTIFICATION_PENDING',
    TOGGLE_BRANCH_NOTIFICATION_FULFILLED: 'TOGGLE_BRANCH_NOTIFICATION_FULFILLED',

    FETCH_SETTINGS: 'FETCH_SETTINGS',
    FETCH_SETTINGS_PENDING: 'FETCH_SETTINGS_PENDING',
    FETCH_SETTINGS_FULFILLED: 'FETCH_SETTINGS_FULFILLED',

    FETCH_BRANCH_SETTINGS: 'FETCH_BRANCH_SETTINGS',
    FETCH_BRANCH_SETTINGS_PENDING: 'FETCH_BRANCH_SETTINGS_PENDING',
    FETCH_BRANCH_SETTINGS_FULFILLED: 'FETCH_BRANCH_SETTINGS_FULFILLED',

    EXPORT_DATA: 'EXPORT_DATA',
    EXPORT_DATA_PENDING: 'EXPORT_DATA_PENDING',
    EXPORT_DATA_FULFILLED: 'EXPORT_DATA_FULFILLED',

    UPDATE_TOTAL_COUNT: 'UPDATE_TOTAL_COUNT',

    FETCH_ORDER_DETAILS: 'FETCH_ORDER_DETAILS',
    FETCH_ORDER_DETAILS_PENDING: 'FETCH_ORDER_DETAILS_PENDING',
    FETCH_ORDER_DETAILS_FULFILLED: 'FETCH_ORDER_DETAILS_FULFILLED',

    ORDER_ALL_DETAILS: "ORDER_ALL_DETAILS",

    FETCH_ORDER_LOGS: "FETCH_ORDER_LOGS",
    FETCH_ORDER_LOGS_PENDING: "FETCH_ORDER_LOGS_PENDING",
    FETCH_ORDER_LOGS_FULFILLED: "FETCH_ORDER_LOGS_FULFILLED",

    FETCH_NOTIFICATION_LOGS: "FETCH_NOTIFICATION_LOGS",
    FETCH_NOTIFICATION_LOGS_PENDING: "FETCH_NOTIFICATION_LOGS_PENDING",
    FETCH_NOTIFICATION_LOGS_FULFILLED: "FETCH_NOTIFICATION_LOGS_FULFILLED",

    FETCH_USER_AUDIT_LOGS: "FETCH_USER_AUDIT_LOGS",
    FETCH_USER_AUDIT_LOGS_PENDING: "FETCH_USER_AUDIT_LOGS_PENDING",
    FETCH_USER_AUDIT_LOGS_FULFILLED: "FETCH_USER_AUDIT_LOGS_FULFILLED",
}