import { AuthActionTypes } from '../../actions/usersActions/actionType';
import storage from '../../../utility/storage';

const token = storage.get("token", null);
const refresh_token = storage.get("refresh_token", null);
const user = storage.get("user", null);

export const initialState = {
    token: token,
    refresh_token: refresh_token,
    user: user,
    isloading: false,
    isAdmin: user && user.role === "admin" ? true : false,
    resetPasswordToken: null,
    csrfToken: null
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {


        case AuthActionTypes.LOGIN_PENDING:
            return updateObject(state, { isloading: true });
        case AuthActionTypes.LOGIN_FULFILLED:
            // if (action.payload.user.role === UserRoles.service_provider) {
            // return updateObject(state, { isloading: false, serviceProviderData: action.payload.user });
            // } else {
            return updateObject(state, {
                isloading: false,
                user: action.payload ? action.payload.user : null,
                token: action.payload ? action.payload.token : null,
                refresh_token: action.payload ? action.payload.refresh_token : null,
            });
            // }

        case AuthActionTypes.FETCH_CSRF_TOKEN_PENDING:
            return updateObject(state, { isloading: true });
        case AuthActionTypes.FETCH_CSRF_TOKEN_FULFILLED:
            return updateObject(state, { isloading: false, csrfToken: action.payload && action.payload.csrfToken ? action.payload.csrfToken : null });

        case AuthActionTypes.FORGOT_PASSWORD_PENDING:
            return updateObject(state, { isloading: true });
        case AuthActionTypes.FORGOT_PASSWORD_FULFILLED:
            return updateObject(state, { isloading: false });

        case AuthActionTypes.RESET_PASSWORD_PENDING:
            return updateObject(state, { isloading: true });
        case AuthActionTypes.RESET_PASSWORD_FULFILLED:
            return updateObject(state, { isloading: false });

        case AuthActionTypes.AUTHORIZE:
            const payload = action.payload;
            const isAdmin = payload.user_profile.role === "superadmin" ? true : false;
            return updateObject(state, {
                token: payload.token,
                user: payload.user_profile,
                refresh_token: payload.refresh_token,
                isAdmin: isAdmin
            });

        case AuthActionTypes.UPDATE_ROLE:
            return updateObject(state, { isAdmin: !state.isAdmin });

        case AuthActionTypes.LOGOUT:
            return updateObject(state, {
                token: null,
                refresh_token: null,
                user: null,
                isloading: false,
                isAdmin: false,
            });

        case AuthActionTypes.UPDATE_USER:
            storage.set('user', action.payload);
            return updateObject(state, {
                user: action.payload
            })
        default:
            return state;
    }
}