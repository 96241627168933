import React, { useEffect } from 'react'
import { defaultImagePath } from '../../utility/utility'
import { routes, OrderStatus, formInputErrorStyle, formInputTextErrorStyle, ViewOrderLimitPerPage } from '../../utility/constants/constants'
import LoadingBtn from '../UI/LoadingButton/LoadingButton'
import moment from 'moment'
import 'moment-timezone'

const ReasonToMoveOrderModal = props => {
    useEffect(() => {
        // debugger
        setTimeout(() => {
            if (props.reasonToMoveOrdersModalStatus) {
                console.log('focused')
                props.onClickFocus('reasonText')
            }
        }, [1000])
    }, [props.reasonToMoveOrdersModalStatus])
    return (
        <div class="modal fade ims_modal" id="reason_text_modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="reason_text_modal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <a href="javascript:void(0)" onClick={() => { props.setErrorMsg(''); props.setReasonText(''); props.toggleReasonToMoveOrdersModalStatus(false) }} role="button" class="link_close" data-dismiss="modal" aria-label="Close">
                            <img src={defaultImagePath('icons/icn_close.svg')} alt="Close Icon" />
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="card ims_card border-0">
                            <div class="card-body">
                                <div class="fields mb-4">
                                    {
                                        props.orderToUpdate
                                            ? <h3 style={{ textTransform: 'capitalize' }} class="font-weight-bold">
                                                {props.orderToUpdate.ContactName}
                                            </h3>
                                            : null
                                    }
                                    <h5 class="font-weight-bold">
                                        {
                                            props.movingOrderType === OrderStatus.PICK_CONFIRMED.key
                                                ? `You are moving this order to ${OrderStatus.PICK_CONFIRMED.title}`
                                                : `You are moving this order to ${OrderStatus.INVOICED.title}`
                                        }
                                    </h5>
                                    <p class="text-graylight">(This is for Manual Entry)</p>
                                </div>
                                <div class="form_group_modify">
                                    <label for="ReasonText" class="label_modify">Enter Reason (100 characters)</label>
                                    <input ref={props.reasonTextRef} style={props.errorMsg ? formInputErrorStyle : null} type="text" className="input_modify input_modify_lg parking_slot_input_field" onChange={props.onChangeReasonText} id="ReasonText" value={props.reasonText} />
                                    <span style={formInputTextErrorStyle}>{props.errorMsg}</span>
                                </div>
                            </div>
                            <div class="card-footer text-center">
                                {
                                    props.isOrderUpdating || props.isOrderLoading
                                        ? <LoadingBtn btnClassName="theme_btn theme_tertiary theme_btn_rds30 mnw_250 text-white disable_btn" btnTitle="Please Wait" />
                                        : <a onClick={() => {
                                            if (!props.reasonText) {
                                                props.setErrorMsg('This field is required!')
                                            } else {
                                                if (props.movingOrderType === OrderStatus.PICK_CONFIRMED.key) {
                                                    props.updateOrder(props.orderToUpdate.OrderNumber, { order: { LocalOrderStatus: null, ParkingSlot: null, Reason: props.reasonText } })
                                                        .then(response => {
                                                            let end_date = new Date()
                                                            let start_date = new Date()
                                                            start_date.setDate(end_date.getDate() - 7)
                                                            end_date = moment(end_date).format('MM-DD-YYYY')
                                                            start_date = moment(start_date).format('MM-DD-YYYY')
                                                            props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, 1, OrderStatus.CUSTOMER_ARRIVED.apiKey, start_date, end_date)
                                                                .then(response => {
                                                                    window.$('#reason_text_modal').modal('toggle');
                                                                    props.setReasonText('')
                                                                }).catch(error => {
                                                                    console.log(error)
                                                                })
                                                        }).catch(error => {
                                                            console.log(error)
                                                        })
                                                } else {
                                                    props.updateOrder(props.orderToUpdate.OrderNumber, { order: { OrderStatus: 8, Reason: props.reasonText } })
                                                        .then(response => {
                                                            let end_date = new Date()
                                                            let start_date = new Date()
                                                            start_date.setDate(end_date.getDate() - 7)
                                                            end_date = moment(end_date).format('MM-DD-YYYY')
                                                            start_date = moment(start_date).format('MM-DD-YYYY')
                                                            props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, 1, OrderStatus.CUSTOMER_ARRIVED.apiKey, start_date, end_date)
                                                                .then(response => {
                                                                    window.$('#reason_text_modal').modal('toggle');
                                                                    props.setReasonText('')
                                                                }).catch(error => {
                                                                    console.log(error)
                                                                })
                                                        }).catch(error => {
                                                            console.log(error)
                                                        })
                                                }
                                            }
                                        }} class={props.errorMsg ? "theme_btn theme_tertiary theme_btn_rds30 mnw_250 text-white disabled_btn" : "theme_btn theme_tertiary theme_btn_rds30 mnw_250 text-white"}>Submit</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReasonToMoveOrderModal