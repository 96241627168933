import axios from 'axios';

export const BASE_URL = () => {
  let url;
  if (process.env.REACT_APP_ENV === 'development') {
    url = 'https://ims-api.bitcotapps.com/'
  }
  if (process.env.REACT_APP_ENV === 'staging') {
    url = 'http://10.0.0.212:3005'
  }
  if (process.env.REACT_APP_ENV === 'production') {
    console.log("production if");
    url = 'http://10.0.0.213:3000'
  }
  return url;
};

export const SOCKET_URL = () => {
  let url;
  if (process.env.REACT_APP_ENV === 'development') {
    url = 'https://ims-api.bitcotapps.com'
  }
  if (process.env.REACT_APP_ENV === 'staging') {
    url = 'http://10.0.0.212:3005'
  }
  if (process.env.REACT_APP_ENV === 'production') {
    console.log("production if");
    url = 'http://10.0.0.213:3000'
  }
  return url;
};

export const WEB_URL = () => {
  let url;
  if (process.env.REACT_APP_ENV === 'development') {
    url = 'https://stage.ims.com'
  }
  if (process.env.REACT_APP_ENV === 'staging') {
    url = 'https://stage.ims.com'
  }
  if (process.env.REACT_APP_ENV === 'production') {
    console.log("production if");
    url = 'https://www.ims.com'
  }
  return url;
};

export const API_VERSION = "v1/"

export const FB_LOGIN_APP_ID = () => {
  if (process.env.REACT_APP_ENV === 'development') {
    return ""
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return ""
  } else {
    return ""
    // 153939689234893
  }
}
export const GOOGLE_LOGIN_CLIENT_ID = () => {
  if (process.env.REACT_APP_ENV === 'development') {
    return ""
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return ""
  } else {
    return ""
  }
}

export const GOOGLE_PLACES_API_KEY = () => {
  if (process.env.REACT_APP_ENV === 'development') {
    return ""
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return ""
  } else {
    return "";
  }
}


const instance = axios.create({
  baseURL: BASE_URL()
});

export default instance;