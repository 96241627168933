import React from 'react'
import { defaultImagePath } from '../../../utility/utility'
import Oux from '../../../hoc/Oux/Oux'
import Skeleton from 'react-loading-skeleton';
import { OrderStatus, routes } from '../../../utility/constants/constants';

const pickConfirmed = props => {
    console.log(props.orders)
    const orderDetails = (order, event) => {
        // debugger;
        if (event.target.id !== "action_btn") {
            props.history.push({pathname: `${routes.ORDER_DETAIL}/${order.OrderNumber}`, state: {isInvoice: false}});
        }
        props.orderSummeryDetails(order);
    }
    return (
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="table-responsive">
                    {
                        props.isLoading
                            ? <div style={{ lineHeight: 2 }} className="mb-2">
                                <Skeleton count={5} height={100} />
                            </div>
                            : props.orders && props.orders.length > 0 && props.orders.filter(item => (item.OrderStatus === OrderStatus.PICK_CONFIRMED.apiKey && item.LocalOrderStatus !== OrderStatus.CUSTOMER_ARRIVED.apiKey)).length > 0
                                ? <table class="table table-borderless text-nowrap ims_table ims_grid_table">
                                    <tbody>
                                        {
                                            props.orders.filter(item => (item.OrderStatus === OrderStatus.PICK_CONFIRMED.apiKey && item.LocalOrderStatus !== OrderStatus.CUSTOMER_ARRIVED.apiKey)).map((order, index) => {
                                                return (
                                                    <Oux key={index}>
                                                        <tr className="non_href_a_tag" onClick={(event) => orderDetails(order, event)}>
                                                            <td>
                                                                <article class="ims_art">
                                                                    <h2 class="font-weight-bold mx_eql">
                                                                        {order.CompanyName}
                                                                    </h2>
                                                                    <h5 style={{ textTransform: 'capitalize' }} class="font-weight-bold mb-0">
                                                                        {order.ContactName}
                                                                        {
                                                                            order.ContactNumber
                                                                                ? <button type="button" class="tooltip_btn" data-toggle="tooltip" data-placement="top" title="" data-original-title={order.ContactNumber}>
                                                                                    <img src={defaultImagePath('icons/icn_not.png')} alt="Info Icon" />
                                                                                </button>
                                                                                : null
                                                                        }
                                                                    </h5>
                                                                </article>
                                                            </td>
                                                            <td>
                                                                <div class="ims_grid_id">
                                                                    <h4 class="ft_Weight_600 text-graylight">Order Number</h4>
                                                                    <h4 class="ft_Weight_600 text-graylight">{order.OrderNumber}</h4>
                                                                </div>
                                                            </td>
                                                            <td id="action_btn" class="text-right">
                                                                <div id="action_btn" class="ims_grid_btn">
                                                                    {
                                                                        props.isDirectSearch
                                                                            ? null
                                                                            : <a id="action_btn" onClick={() => { window.$('#arrived_delivery').modal('toggle'); props.storeOrderToUpdate(order); props.toggleDeliveryModalStatus(true) }} class="theme_btn theme_tertiary theme_btn_rds30 text-white">Arrived for Delivery</a>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr class="ims_tr_gap" style={{ height: '15px' }}></tr>
                                                    </Oux>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                : <div class="table table-borderless text-nowrap ims_table ims_grid_table">
                                    <h2 className="text-center"><b>No results found</b></h2>
                                </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default pickConfirmed