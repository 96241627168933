import { HistoryActionTypes, MiscActionTypes } from './actionType';

export const addHistory = (history) => dispatch => {
    dispatch({ type: HistoryActionTypes.ADD_HISTORY, payload: history });
}

export const toggleDeliveryModalStatus = (status) => dispatch => {
    dispatch({ type: MiscActionTypes.ARRIVED_FOR_DELIVERY_MODAL_STATUS, payload: status });
}

export const toggleReasonToMoveOrdersModalStatus = (status, orderType) => dispatch => {
    dispatch({ type: MiscActionTypes.REASON_TO_MOVE_ORDERS_MODAL_STATUS, payload: { status: status, orderType: orderType } });
}

export const toggleSocketStauts = (status) => dispatch => {
    dispatch({ type: MiscActionTypes.SOCKET_STAUS, payload: status });
}
