import { OrderActionTypes } from '../../actions/usersActions/actionType';
import actions from 'redux-form/lib/actions';

export const initialState = {
    orders: [],
    isLoading: false,
    isOrderUpdating: false,
    orderToUpdate: null,
    ready_count: 0,
    arrived_count: 0,
    delivered_count: 0,
    orderSummaryDataSet: [],
    orderSummarResults: null,
    selectedBranch: null,
    isLoadMoreLoading: false,
    totalCount: 0,
    settings: [],
    isSettingsLoading: false,
    branchSettings: null,
    isExporting: false,
    exportData: [],
    orderAllDetails: null,
    logs: [],
    count: 0,
    notification_count: 0,
    log_count: 0,
    audit_logs: [],
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {


        case OrderActionTypes.FETCH_ORDER_BRANCH_ID_PENDING:
            return updateObject(state, { isLoading: true });
        case OrderActionTypes.FETCH_ORDER_BRANCH_ID_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                orders: action.payload && action.payload.orders && action.payload.orders.length > 0 ? action.payload.orders : [],
                totalCount: action.payload.count ? action.payload.count : 0
            });

        case OrderActionTypes.FETCH_ORDER_BY_PHONE_NUMBER_PENDING:
            return updateObject(state, { isLoading: true });
        case OrderActionTypes.FETCH_ORDER_BY_PHONE_NUMBER_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                orders: action.payload && action.payload.orders && action.payload.orders.length > 0 ? action.payload.orders : [],
                totalCount: action.payload.count ? action.payload.count : 0
            });

        case OrderActionTypes.LOAD_MORE_ORDERS_PENDING:
            return updateObject(state, { isLoadMoreLoading: true });
        case OrderActionTypes.LOAD_MORE_ORDERS_FULFILLED:
            if (action.payload && action.payload.orders && action.payload.orders.length > 0) {
                return updateObject(state, {
                    isLoadMoreLoading: false,
                    orders: [...state.orders, ...action.payload.orders],
                    totalCount: action.payload.count
                });
            } else {
                return updateObject(state, {
                    isLoadMoreLoading: false,
                });
            }

        case OrderActionTypes.SEARCH_ORDER_BY_ORDER_NUMBER_PENDING:
            return updateObject(state, { isLoading: true });
        case OrderActionTypes.SEARCH_ORDER_BY_ORDER_NUMBER_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                orders: action.payload && action.payload.orders && action.payload.orders.length > 0 ? action.payload.orders : [],
                totalCount: action.payload && action.payload.orders ? action.payload.orders.length : 0
            });

        case OrderActionTypes.UPDATE_ORDER_PENDING:
            return updateObject(state, { isOrderUpdating: true });
        case OrderActionTypes.UPDATE_ORDER_FULFILLED:
            return updateObject(state, { isOrderUpdating: false });

        case OrderActionTypes.FETCH_ORDER_SUMMARY_PENDING:
            return updateObject(state, { isLoading: true });
        case OrderActionTypes.FETCH_ORDER_SUMMARY_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                ready_count: action.payload && action.payload.ready_count ? action.payload.ready_count : 0,
                arrived_count: action.payload && action.payload.arrived_count ? action.payload.arrived_count : 0,
                delivered_count: action.payload && action.payload.delivered_count ? action.payload.delivered_count : 0,
                orderSummaryDataSet: action.payload && action.payload.datasets ? action.payload.datasets : [],
                orderSummarResults: action.payload && action.payload.result ? action.payload.result : null,
            });

        case OrderActionTypes.STORE_ORDER_TO_UPDATE:
            return updateObject(state, {
                orderToUpdate: action.payload && action.payload.orderToUpdate ? action.payload.orderToUpdate : null
            });

        case OrderActionTypes.SELECTED_BRANCH:
            return updateObject(state, {
                selectedBranch: action.payload && action.payload.branch ? action.payload.branch : null
            });

        case OrderActionTypes.STORE_LATEST_ORDERS:
            return updateObject(state, {
                orders: action.payload && action.payload.orders ? action.payload.orders : []
            });

        case OrderActionTypes.UPDATE_TOTAL_COUNT:
            return updateObject(state, {
                totalCount: action.payload && action.payload.count ? action.payload.count : 0
            });


        case OrderActionTypes.TOGGLE_NOTIFY_PENDING:
            return updateObject(state, { isSettingsLoading: true });
        case OrderActionTypes.TOGGLE_NOTIFY_FULFILLED:
            return updateObject(state, { isSettingsLoading: true });

        case OrderActionTypes.FETCH_SETTINGS_PENDING:
            return updateObject(state, { isLoading: true, isSettingsLoading: true });
        case OrderActionTypes.FETCH_SETTINGS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                settings: action.payload && action.payload.settings ? action.payload.settings : [],
                isSettingsLoading: false
            });

        case OrderActionTypes.TOGGLE_BRANCH_NOTIFICATION_PENDING:
            return updateObject(state, { isSettingsLoading: true });
        case OrderActionTypes.TOGGLE_BRANCH_NOTIFICATION_FULFILLED:
            if (action.payload && action.payload.BranchSettings) {
                return updateObject(state, {
                    isSettingsLoading: false,
                    branchSettings: action.payload.BranchSettings
                });
            } else {
                return updateObject(state, {
                    isSettingsLoading: false
                });
            }

        case OrderActionTypes.FETCH_BRANCH_SETTINGS_PENDING:
            return updateObject(state, { isLoading: true });
        case OrderActionTypes.FETCH_BRANCH_SETTINGS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                branchSettings: action.payload && action.payload.BranchSettings ? action.payload.BranchSettings : null,
            });

        case OrderActionTypes.EXPORT_DATA_PENDING:
            return updateObject(state, { isExporting: true });
        case OrderActionTypes.EXPORT_DATA_FULFILLED:
            return updateObject(state, { isExporting: false, exportData: action.payload && action.payload.orders ? action.payload.orders : [] });

        case OrderActionTypes.FETCH_ORDER_DETAILS_PENDING:
            return updateObject(state, { isLoading: true });
        case OrderActionTypes.FETCH_ORDER_DETAILS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                orderAllDetails: action.payload && action.payload ? action.payload.order : null
            });

        case OrderActionTypes.ORDER_ALL_DETAILS:
            return updateObject(state, {
                orderAllDetails: action.payload && action.payload.orderAllDetails ? action.payload.orderAllDetails : null
            });
        case OrderActionTypes.FETCH_ORDER_LOGS_PENDING:
            return updateObject(state, { isLoading: true, isExporting: true });
        case OrderActionTypes.FETCH_ORDER_LOGS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isExporting: false,
                notification_count: 0,
                count: 0,
                logs:  action.payload.isExportData ? state.logs : getLogsFromRes(action, "order_logs"),
                log_count: action.payload.isExportData ? state.log_count : action.payload.count
            });

        case OrderActionTypes.FETCH_NOTIFICATION_LOGS_PENDING:
            return updateObject(state, { isLoading: true, isExporting: true });
        case OrderActionTypes.FETCH_NOTIFICATION_LOGS_FULFILLED:
            // debugger;
            return updateObject(state, {
                isLoading: false,
                isExporting: false,
                log_count: 0,
                count: 0,
                notification_count: action.payload.isExportData ? state.notification_count : action.payload.count,
                logs:   action.payload.isExportData ? state.logs : getLogsFromRes(action, "notification_logs")
            });

        case OrderActionTypes.FETCH_USER_AUDIT_LOGS_PENDING:
            return updateObject(state, { isLoading: true, isExporting: true });
        case OrderActionTypes.FETCH_USER_AUDIT_LOGS_FULFILLED:
            // debugger;
            return updateObject(state, {
                isLoading: false,
                isExporting: false,
                audit_logs:  action.payload.isExportData ? state.audit_logs : getLogsFromRes(action, "audit_logs"),
                count: action.payload.isExportData ? state.count : action.payload.count,
                notification_count: 0,
                log_count: 0,
            });


        default: return state;
    }
}

function getLogsFromRes(action,  type){
    return action.payload && action.payload[type] && action.payload[type].length > 0 ? action.payload[type] : []
}