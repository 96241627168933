import React from 'react'
import { Form, Formik, Field } from 'formik'
import { validateAddUserForm } from '../../utility/validator/formValidation'
import { routes, formInputTextErrorStyle, formInputErrorStyle } from '../../utility/constants/constants';
import LoadingBtn from '../UI/LoadingButton/LoadingButton';
import $ from 'jquery'
import Oux from '../../hoc/Oux/Oux';
import { showConfirmAlert } from '../../utility/successAlert/confirmAlert';
import { toastMsg } from '../../utility/utility';

const addUser = props => {
    let initialFormValues = {
        first_name: '',
        last_name: '',
        email: '',
        branch_name: '',
        role: '',
        password: ''
    }
    if (props.selectedBranch) {
        if (props.userData) {
            initialFormValues = {
                first_name: props.userData.first_name,
                last_name: props.userData.last_name,
                email: props.userData.email,
                branch_name: props.selectedBranch.Name,
                role: props.userData.role,
                password: ''
            }
        } else {
            initialFormValues = {
                first_name: '',
                last_name: '',
                email: '',
                branch_name: props.selectedBranch.Name,
                role: '',
                password: ''
            }
        }
    }
    return (
        <div class="tab-pane fade show active notification_settings" id="add_user" role="tabpanel" aria-labelledby="add_user_tab">
            <div class="wrap-login100 sp_btwn_wrp">
                <div class="user_details_list_wrp">
                    <article class="limiter_heading_wrp ph_flex_wrp_spw">
                        <h3 class="text-left mb-0"> User Detail</h3>
                        {props.showForm ? null : <a href="javascript:void(0)" class="theme_tertiary theme_btn" onClick={(event) => props.onToggleForm(event, true)} id="triggar_add_new_user">Add New User</a>}
                    </article>
                    <ul class="list-group">
                        {
                            props.usersList && props.usersList.length > 0
                                ? props.usersList.map(user => {
                                    return (
                                        <li onClick={(event) => props.onToggleForm(event, true, user)} style={{ cursor: 'pointer' }} class="list-group-item">
                                            <p class="info_title" style={{ textTransform: 'capitalize' }}>{user.first_name} {user.last_name}</p>
                                            <p class="info_title">{user.email}</p>
                                            {/* {user.phone ? <p class="info_title">Phone: {user.phone}</p> : null} */}
                                            <p class="info_title" style={{ textTransform: 'capitalize' }}>{props.selectedBranch.Name}</p>
                                            <p class="info_title" style={{ textTransform: 'capitalize' }}>{user.role}</p>
                                            <div class="icons_wrp_abslt">
                                                {
                                                    props.isUserLoading && !props.showForm
                                                        ? <LoadingBtn btnClassName="transparent_bg_loading_btn delete_icon_loader" />
                                                        : <Oux>
                                                            <a onClick={(event) => props.onToggleForm(event, true, user)} href="javascript:void(0)"><i class="fa fa-edit"></i></a>
                                                            <a id="delete_icon"
                                                                onClick={() => {
                                                                    showConfirmAlert("Please Confirm", 'Are you sure you want to delete this user?', () => {
                                                                        props.deleteUser(user.id)
                                                                    })
                                                                }}
                                                                href="javascript:void(0)"><i id="delete_icon" class="fa fa-trash"></i></a>
                                                        </Oux>
                                                }
                                            </div>
                                        </li>
                                    )
                                })
                                : <li class="list-group-item">
                                    <p class="info_title">No Users Found</p>
                                </li>
                        }
                    </ul>
                </div>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialFormValues}
                    onSubmit={(values, { resetForm }) => {
                        delete values.branch_name
                        if (!values.password) {
                            delete values.password
                        }
                        values = {
                            ...values,
                            BranchID: props.selectedBranch.BranchID
                        }
                        if (props.userData) {
                            props.updateUser(props.userData.id, { user: { ...values } })
                                .then(response => {
                                    if (response.value.success || response.value.success === "true") {
                                        props.onToggleForm(null, false)
                                        resetForm()
                                        toastMsg('User updated successfully!')
                                    }
                                }).catch(error => {
                                    console.log(error)
                                })
                        } else {
                            props.addUser({ user: { ...values } })
                                .then(response => {
                                    if (response.value.success || response.value.success === "true") {
                                        props.onToggleForm(null, false)
                                        resetForm()
                                        toastMsg('User added successfully!')
                                    }
                                }).catch(error => {
                                    console.log(error)
                                })
                        }
                        console.log(values, 'Values')
                    }}
                    validationSchema={validateAddUserForm}>
                    {(formik_props) => {
                        const errors = formik_props.errors
                        const touched = formik_props.touched
                        return (
                            <Form class="login100-form ph_forms ims_hide_wrp" style={{ display: 'block' }} id="target_add_user">
                                {
                                    props.showForm
                                        ? <Oux>
                                            <article class="limiter_heading_wrp ml-auto mr-auto">
                                                <h3>Add User</h3>
                                            </article>
                                            <div class="inner_form">
                                                <div class="fields">
                                                    <div class="form_group_modify">
                                                        <label for="first_name" class="label_modify">First Name</label>
                                                        <Field id="first_name" type="text" class="input_modify" name="first_name" style={errors.first_name && touched.first_name ? formInputErrorStyle : null} />
                                                        <span style={formInputTextErrorStyle}>{errors.first_name && touched.first_name && errors.first_name}</span>
                                                    </div>
                                                    <div class="form_group_modify">
                                                        <label for="last_name" class="label_modify">Last Name</label>
                                                        <Field id="last_name" type="text" class="input_modify" name="last_name" style={errors.last_name && touched.last_name ? formInputErrorStyle : null} />
                                                        <span style={formInputTextErrorStyle}>{errors.last_name && touched.last_name && errors.last_name}</span>
                                                    </div>
                                                    <div class="form_group_modify">
                                                        <label for="email" class="label_modify">Email</label>
                                                        <Field id="email" type="email" class={props.userData ? "input_modify disabled_input_field" : "input_modify"} disabled={props.userData ? true : false} name="email" style={errors.email && touched.email ? formInputErrorStyle : null} />
                                                        <span style={formInputTextErrorStyle}>{errors.email && touched.email && errors.email}</span>
                                                    </div>
                                                    <div class="form_group_modify">
                                                        <label for="password" class="label_modify">Password</label>
                                                        <Field id="password" type="password" class="input_modify" name="password" style={errors.password && touched.password ? formInputErrorStyle : null} />
                                                        <span style={formInputTextErrorStyle}>{errors.password && touched.password && errors.password}</span>
                                                    </div>
                                                    <div class="form_group_modify">
                                                        <label for="branch_name" class="label_modify">Branch name</label>
                                                        <Field disabled="true" id="branch_name" type="text" class="input_modify disabled_input_field" name="branch_name" style={errors.branch_name && touched.branch_name ? formInputErrorStyle : null} />
                                                        <span style={formInputTextErrorStyle}>{errors.branch_name && touched.branch_name && errors.branch_name}</span>
                                                    </div>
                                                    <div class="form_group_modify">
                                                        <label for="role" class="label_modify">Role</label>
                                                        <Field style={errors.role && touched.role ? formInputErrorStyle : null} as="select" id="role" name="role" class="input_modify cstSelect">
                                                            <option value=''>Select Role</option>
                                                            <option value='associate'>Associate</option>
                                                            <option value='manager'>Manager</option>
                                                            <option value='admin'>Branch Admin</option>
                                                        </Field>
                                                        <span style={formInputTextErrorStyle}>{errors.role && touched.role && errors.role}</span>
                                                    </div>
                                                </div>
                                                <div class="ph_flex_wrp_spw">
                                                    {
                                                        props.isLoading || props.isUserLoading
                                                            ? <LoadingBtn btnClassName="theme_primary theme_btn text-uppercase btn-block" btnTitle="Please Wait..." />
                                                            : <button type="submit" className="theme_primary theme_btn text-uppercase btn-block">{props.userData ? 'Update' : 'Save'}</button>
                                                    }
                                                    {/* <button class="theme_primary theme_btn text-uppercase btn-block" type="submit">Update </button> */}
                                                </div>
                                                <div class="ph_flex_wrp_spw mt-2">
                                                    {
                                                        props.isLoading || props.isUserLoading
                                                            ? <a href="javascript:void(0)" class="theme_tertiary theme_btn text-uppercase btn-block disabled_btn" id="triggar_add_new_user">Cancel</a>
                                                            : props.showForm ? <a href="javascript:void(0)" onClick={() => { props.onToggleForm(null, false); formik_props.resetForm() }} class="theme_tertiary theme_btn text-uppercase btn-block" id="triggar_add_new_user">Cancel</a> : null
                                                    }
                                                    {/* <button class="theme_primary theme_btn text-uppercase btn-block" type="submit">Update </button> */}
                                                </div>
                                            </div>
                                        </Oux>
                                        : null
                                }
                            </Form>
                        )
                    }}
                </Formik>
            </div>

        </div>
    )
}

export default addUser 