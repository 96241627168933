import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { routes, ViewOrderLimitPerPage } from '../../utility/constants/constants'
import NotificationLogs from '../../components/Logs/NotificationLogs'
import AuditLogsPage from '../../components/Logs/AuditLogsPage'
import * as actions from '../../redux/actions/index'
import 'moment-timezone'
import moment from 'moment'
import OrderLogs from '../../components/Logs/OrderLogs'
import storage from '../../utility/storage'


const Logs = props => {

    var endDate = new Date()
    var startDate = new Date()
    startDate.setDate(endDate.getDate() - 7)
    endDate = moment(endDate).format('MM-DD-YYYY')
    startDate = moment(startDate).format('MM-DD-YYYY')
    const defaultParams = {
        start_date: startDate,
        end_date: endDate,
        pageNumber: 1,
        ViewOrderLimitPerPage,
        fromFilter: false
    }
    const [filterParams, setFilterParams] = useState(defaultParams);
    useEffect(() => {
        if (!filterParams.fromFilter) {
            let pageNumber = filterParams.pageNumber;
            const { start_date, end_date, ViewOrderLimitPerPage } = filterParams;
            let branchCode = null
            let branch = storage.get('selectedBranch', null)
            if (props.selectedBranch) {
                branchCode = props.selectedBranch.BranchCode
            } else if (branch) {
                branchCode = branch.BranchCode
            }
            if (routes.NOTIFICATION_LOGS.includes(props.history.location.pathname)) {
                if (!props.notification_count && pageNumber > 1) {
                    setFilterParams(defaultParams)
                    pageNumber = 1;
                }
                props.fetchNotificationLogs(start_date, end_date, pageNumber, ViewOrderLimitPerPage, branchCode ? branchCode : "all")
            } else if (routes.ORDER_LOGS.includes(props.history.location.pathname)) {
                if (!props.log_count && pageNumber > 1) {
                    setFilterParams(defaultParams)
                    pageNumber = 1;
                }
                props.fetchOrderLogs(start_date, end_date, pageNumber, ViewOrderLimitPerPage, branchCode ? branchCode : "all")
            } else if (routes.AUDIT_LOGS.includes(props.history.location.pathname)) {
                if (!props.count && pageNumber > 1) {
                    setFilterParams(defaultParams)
                    pageNumber = 1;
                }
                props.fetchUserAuditLogs(start_date, end_date, pageNumber, ViewOrderLimitPerPage, props.user.id, branchCode ? branchCode : "all")
            }
        }
    }, [filterParams, props.history.location.pathname, props.selectedBranch]);


    return (
        <Switch>
            <Route exact path={routes.NOTIFICATION_LOGS}>
                <NotificationLogs filterParams={filterParams} setPageNumber={setFilterParams} ViewOrderLimitPerPage={ViewOrderLimitPerPage} pageNumber={filterParams.pageNumber} {...props} />
            </Route>
            <Route exact path={routes.AUDIT_LOGS}>
                <AuditLogsPage
                    setPageNumber={setFilterParams}
                    filterParams={filterParams}
                    ViewOrderLimitPerPage={ViewOrderLimitPerPage}
                    pageNumber={filterParams.pageNumber}
                    {...props}
                />
            </Route>
            <Route exact path={routes.ORDER_LOGS}>
                <OrderLogs setPageNumber={setFilterParams} filterParams={filterParams} pageNumber={filterParams.pageNumber} ViewOrderLimitPerPage={ViewOrderLimitPerPage} {...props} />
            </Route>
            <Route path='*' render={() => <Redirect to={routes.NOTIFICATION_LOGS} />} />
        </Switch>
    )
}

const mapStateToProps = (state) => {
    return {
        isExporting: state.orderReducer.isExporting,
        logs: state.orderReducer.logs,
        user: state.authReducer.user,
        audit_logs: state.orderReducer.audit_logs,
        count: state.orderReducer.count,
        notification_count: state.orderReducer.notification_count,
        log_count: state.orderReducer.log_count,
        selectedBranch: state.orderReducer.selectedBranch,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrderLogs: (start_date, end_date, pageNumber, limit, branchCode, isExportData) => dispatch(actions.fetchOrderLogs(start_date, end_date, pageNumber, limit, branchCode, isExportData)),
        fetchNotificationLogs: (start_date, end_date, pageNumber, limit, branchCode, isExportData) => dispatch(actions.fetchNotificationLogs(start_date, end_date, pageNumber, limit, branchCode, isExportData)),
        fetchUserAuditLogs: (start_date, end_date, pageNumber, limit, id, branchCode, isExportData) => dispatch(actions.fetchUserAuditLogs(start_date, end_date, pageNumber, limit, id, branchCode, isExportData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logs)

