import axios, { API_VERSION } from '../config';

export const login = (credentials) => {
    return axios.post(API_VERSION + 'auth/login', credentials, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const fetchCSRFToken = (credentials) => {
    return axios.get(API_VERSION + 'token', {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const signup = (credentials) => {
    return axios.post('/users', credentials, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const forgotPassword = (credentials) => {

    return axios.post('/users/password', credentials, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const resetPassword = (user) => {

    return axios.put('/users/password', user, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const impersonate = (userId) => {
    return axios.get(`api/v1/users/${userId}/impersonate`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};