import axios, { API_VERSION } from '../config';

export const addUser = (user) => {
    return axios.post(API_VERSION + 'user', user, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const fetchUsersList = (branchCode) => {
    return axios.get(API_VERSION + `user?BranchCode=${branchCode}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const updateUser = (userId, user) => {
    return axios.put(API_VERSION + `user/${userId}`, user, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const deleteUser = (userId) => {
    return axios.delete(API_VERSION + `user/${userId}`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};