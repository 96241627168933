import React, { useEffect } from 'react'
import { defaultImagePath } from '../../utility/utility'
import { routes, OrderStatus, formInputErrorStyle, formInputTextErrorStyle, ViewOrderLimitPerPage } from '../../utility/constants/constants'
import LoadingBtn from '../UI/LoadingButton/LoadingButton'

const ArrivedForDeliveryModal = props => {
    useEffect(() => {
        // debugger
        setTimeout(() => {
            if (props.arrivedForDeliveryModalStatus) {
                console.log('focused')
                props.onClickFocus('parkingSlot')
            }
        }, [1000])
    }, [props.arrivedForDeliveryModalStatus])
    return (
        <div class="modal fade ims_modal" id="arrived_delivery" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="arrived_delivery" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <a href="javascript:void(0)" onClick={() => { props.setErrorMsg(''); props.setParkingSlot(''); props.toggleDeliveryModalStatus(false); props.setReasonText('') }} role="button" class="link_close" data-dismiss="modal" aria-label="Close">
                            <img src={defaultImagePath('icons/icn_close.svg')} alt="Close Icon" />
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="card ims_card border-0">
                            <div class="card-body">
                                <div class="fields mb-4">
                                    {
                                        props.orderToUpdate
                                            ? <h3 style={{ textTransform: 'capitalize' }} class="font-weight-bold">
                                                {props.orderToUpdate.ContactName}
                                            </h3>
                                            : null
                                    }
                                    <h5 class="font-weight-bold">
                                        Enter parking slot
                                    </h5>
                                    <p class="text-graylight">(This is for Manual Entry)</p>
                                </div>
                                <div class="form_group_modify">
                                    <label for="Parking" class="label_modify">Parking</label>
                                    <input ref={props.parkingSlotRef} style={props.errorMsg ? formInputErrorStyle : null} type="text" className="input_modify input_modify_lg parking_slot_input_field" onChange={props.onChangeParkingSlot} id="Parking" value={props.parkingSlot} />
                                    <span style={formInputTextErrorStyle}>{props.errorMsg}</span>
                                </div>
                                <div class="form_group_modify">
                                    <label for="reason" class="label_modify">Reason</label>
                                    <input style={props.reasonTextErrorMsg ? formInputErrorStyle : null} type="text" className="input_modify input_modify_lg parking_slot_input_field" onChange={props.onChangeReasonText} id="Parking" value={props.reasonText} />
                                    <span style={formInputTextErrorStyle}>{props.reasonTextErrorMsg}</span>
                                </div>
                            </div>
                            <div class="card-footer text-center">
                                {
                                    props.isOrderUpdating || props.isOrderLoading
                                        ? <LoadingBtn btnClassName="theme_btn theme_tertiary theme_btn_rds30 mnw_250 text-white disable_btn" btnTitle="Please Wait" />
                                        : <a onClick={() => {
                                            if (!props.parkingSlot) {
                                                props.setErrorMsg('This field is required!')
                                            } else if(!props.reasonText){
                                                props.setReasonTextErrorMsg('This field is required!')
                                            } else {
                                                props.updateOrder(props.orderToUpdate.OrderNumber, { order: { ParkingSlot: props.parkingSlot, Reason: props.reasonText } })
                                                    .then(response => {
                                                        props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, 1, OrderStatus.CUSTOMER_ARRIVED.apiKey)
                                                            .then(response => {
                                                                if (response.value.success === 'true' || response.value.success) {
                                                                    window.$('#arrived_delivery').modal('toggle')
                                                                    props.setParkingSlot('')
                                                                    props.setReasonText('')
                                                                    props.toggleDeliveryModalStatus(false)
                                                                    props.updateTotalOrderCount(response.value.count)
                                                                    props.history.push(`${routes.ORDERS}/${OrderStatus.CUSTOMER_ARRIVED.key}`)
                                                                } else {
                                                                    window.$('#arrived_delivery').modal('toggle')
                                                                    props.setParkingSlot('')
                                                                    props.setReasonText('')
                                                                    props.toggleDeliveryModalStatus(false)
                                                                }
                                                            }).catch(error => {
                                                                console.log(error)
                                                            })
                                                    }).catch(error => {
                                                        console.log(error)
                                                    })
                                            }
                                        }} class={props.errorMsg || props.reasonTextErrorMsg ? "theme_btn theme_tertiary theme_btn_rds30 mnw_250 text-white disabled_btn" : "theme_btn theme_tertiary theme_btn_rds30 mnw_250 text-white"}>Submit</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArrivedForDeliveryModal