import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import OrderDetailsPage from '../../components/OrderDetails/OrderDetailsPage'
import * as actions from '../../redux/actions/index'
const OrderDetails = (props) => {

    useEffect(() => {
        let orderNumber = props.match.params.orderNumber;
        props.fetchOrderDetails(orderNumber);
    }, [])


    return (
        <OrderDetailsPage
            {...props}
        />
    )
}
const mapStateToProps = (state) => {
    return {
        orders: state.orderReducer.orders,
        orderAllDetails: state.orderReducer.orderAllDetails,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        orderSummeryDetails: (orderAllDetails) => dispatch(actions.orderSummeryDetails(orderAllDetails)),
        fetchOrderDetails: (orderNumber) => dispatch(actions.fetchOrderDetails(orderNumber)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails)