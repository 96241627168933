import axios, { API_VERSION } from '../config';

export const fetchConfig = () => {
    return axios.get(API_VERSION + 'config', {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    });
};

export const reportError = (error, route, apiUrl, request_body) => {
    return axios.post(API_VERSION + 'error/log', error, route, apiUrl, request_body, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    });
};