import React, { useState, useEffect } from 'react'
import { LogsType, OrderStatus } from '../../utility/constants/constants'
import ExportDataForm from '../ExportDataForm/ExportDataForm'
import Pagination from "react-js-pagination";
import { convertTimeFromMilliseconds } from '../../utility/utility'
import moment from 'moment'
import 'moment-timezone'


const OrderLogs = props => {
    const [loaded, setLoaded] = useState(false);
    const [exportedData, setExportedData] = useState([]);
    const handleResForExport = (res) => {
        let exportData = res.value.order_logs.map(log => {
            let oldStatus = null
            let newStatus = null
            let dateTimeLastModified = moment(log.DateTimeModified).format('MM/DD/YYYY hh:mm A')
            if (OrderStatus.PICK_CONFIRMED.apiKey.toString() === log.OldStatus.toString()) {
                oldStatus = OrderStatus.PICK_CONFIRMED.title
            } else if (OrderStatus.CUSTOMER_ARRIVED.apiKey.toString() === log.OldStatus.toString()) {
                oldStatus = OrderStatus.CUSTOMER_ARRIVED.title
            } else {
                oldStatus = OrderStatus.INVOICED.title
            }
            if (OrderStatus.PICK_CONFIRMED.apiKey.toString() === log.NewStatus.toString()) {
                newStatus = OrderStatus.PICK_CONFIRMED.title
            } else if (OrderStatus.CUSTOMER_ARRIVED.apiKey.toString() === log.NewStatus.toString()) {
                newStatus = OrderStatus.CUSTOMER_ARRIVED.title
            } else {
                newStatus = OrderStatus.INVOICED.title
            }
            let currentTime = new Date()
            let PickUpDateTime = moment(log.order.PickUpDateTime).utc().format('YYYY-MM-DDTHH:mm:ss.000')
            PickUpDateTime = new Date(PickUpDateTime)
            let ArrivedTime = moment(log.order.ArrivedTime).format('YYYY-MM-DDTHH:mm:ss.000')
            ArrivedTime = new Date(ArrivedTime)
            let DeliveryTime = moment(log.order.DeliveryTime).format('YYYY-MM-DDTHH:mm:ss.000')
            DeliveryTime = new Date(DeliveryTime)
            let waitingTimeInMilliseconds;
            if (log.order.DeliveryTime && log.order.ArrivedTime) waitingTimeInMilliseconds = moment(log.order.DeliveryTime).diff(moment(log.order.ArrivedTime), "milliseconds");
            else if (log.order.PickUpDateTime && log.order.ArrivedTime) {
                let PickUpDateTimeLocal = moment(log.order.PickUpDateTime).utc().format("YYYY-MM-DDTHH:mm:ss.000")
                waitingTimeInMilliseconds = moment(PickUpDateTimeLocal).diff(moment(log.order.ArrivedTime), "milliseconds")
            }
            else if (log.order.ArrivedTime) waitingTimeInMilliseconds = moment(currentTime).diff(moment(log.order.ArrivedTime), "milliseconds");
            let WaitingTime = convertTimeFromMilliseconds(waitingTimeInMilliseconds)
            PickUpDateTime = moment(PickUpDateTime).format('MM/DD/YYYY HH:mm:ss')
            ArrivedTime = moment(ArrivedTime).format('MM/DD/YYYY HH:mm:ss')
            DeliveryTime = moment(DeliveryTime).format('MM/DD/YYYY HH:mm:ss')
            return {
                'Order Number': log.order.OrderNumber,
                'Branch Name': log.order.BranchName,
                'Old Status': oldStatus,
                'New Status': newStatus,
                Reason: log.Reason,
                'Date Time Last Modified': dateTimeLastModified,
                PickUpDateTime: PickUpDateTime && PickUpDateTime !== "Invalid date" ? PickUpDateTime : "N/A",
                ArrivedTime: ArrivedTime && ArrivedTime !== "Invalid date" ? ArrivedTime : "N/A",
                DeliveryTime: DeliveryTime && DeliveryTime !== "Invalid date" ? DeliveryTime : "N/A",
                WaitingTime: WaitingTime && WaitingTime !== "Invalid date" ? WaitingTime : "N/A",
            }
        });
        setExportedData(exportData);

    };


    useEffect(() => {
        if (exportedData.length > 0) {
            setLoaded(true)
        }
    }, [exportedData])
    useEffect(() => {
        if (loaded) {
            var downloadLink = document.createElement("a");
            downloadLink.href = document.getElementById("export_logs_data_download").href//$('#export_data_download').attr('href')
            downloadLink.download = `orderLogs.csv`;
            downloadLink.click();
            setLoaded(false);
            setExportedData([]);
        }
    }, [loaded])
    return (
        <section class="theme_nav_mt ims_sec">
            <div class="container-fluid ims_mx30">
                <ExportDataForm {...props} handleResForExport={handleResForExport} exportedData={exportedData} logsType={LogsType.ORDER_LOGS} isLogs={true} />
                <div class="row align-items-center custom_top">
                    <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
                        <article class="ims_art ims_flex ims_flex_v">
                            <h3 class="font-weight-bold">Order Logs</h3>
                        </article>
                    </div>
                    {/* <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2 text-right">
                        <select class="input_modify cstSelect cstSelectW160">
                            <option>All Status</option>
                            <option>Customer Arrived</option>
                            <option>Ready</option>
                            <option>Complete</option>
                        </select>
                    </div> */}
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive ">
                            <table class="table table-borderless table-hover ims_table text-nowrap">
                                <thead class="ot_heading">
                                    <tr>
                                        <th scope="col">Order Number</th>
                                        <th scope="col">Branch Name</th>
                                        <th scope="col">Old Status</th>
                                        <th scope="col">New Status</th>
                                        <th scope="col">Reason</th>
                                        <th scope="col">Date Time Last Modified</th>
                                        <th scope="col">Pick Up Date Time</th>
                                        <th scope="col">Arrived Time</th>
                                        <th scope="col">Delivery Time</th>
                                        <th scope="col">Waiting Time</th>
                                        {/* <th scope="col">Customer Name</th> */}
                                        {/* <th scope="col">line items</th>
                                        <th scope="col">Promise Time</th>
                                        <th scope="col">table_head_1</th>
                                        <th scope="col">table_head_2</th>
                                        <th scope="col">table_head_3</th>
                                        <th scope="col">Status</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {/* <td class="th_space">
                                            <input type="text" class="input_modify" id="Order" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="CustomerName" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Contact" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Date" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Parking" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Delivery" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Waitingtime" />
                                        </td>
                                        <td class="th_space">
                                            <input type="password" class="input_modify" id="lineitems" />
                                        </td>
                                        <td class="th_space">
                                            <input type="password" class="input_modify" id="PromiseTime" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Waitingtime" />
                                        </td>
                                        <td class="th_space">
                                            <input type="password" class="input_modify" id="lineitems" />
                                        </td>
                                        <td class="th_space">
                                            <input type="password" class="input_modify" id="PromiseTime" />
                                        </td>
                                        <td></td> */}
                                    </tr>
                                    {
                                        props.logs && props.logs.length > 0
                                            ? props.logs.map(log => {
                                                let oldStatus = null
                                                let newStatus = null
                                                let dateTimeLastModified = moment(log.DateTimeModified).format('MM/DD/YYYY hh:mm A')
                                                if (log.OldStatus && OrderStatus.PICK_CONFIRMED.apiKey.toString() === log.OldStatus.toString()) {
                                                    oldStatus = OrderStatus.PICK_CONFIRMED.title
                                                } else if (log.OldStatus && OrderStatus.CUSTOMER_ARRIVED.apiKey.toString() === log.OldStatus.toString()) {
                                                    oldStatus = OrderStatus.CUSTOMER_ARRIVED.title
                                                } else {
                                                    oldStatus = OrderStatus.INVOICED.title
                                                }
                                                if (log.NewStatus && OrderStatus.PICK_CONFIRMED.apiKey.toString() === log.NewStatus.toString()) {
                                                    newStatus = OrderStatus.PICK_CONFIRMED.title
                                                } else if (log.NewStatus && OrderStatus.CUSTOMER_ARRIVED.apiKey.toString() === log.NewStatus.toString()) {
                                                    newStatus = OrderStatus.CUSTOMER_ARRIVED.title
                                                } else {
                                                    newStatus = OrderStatus.INVOICED.title
                                                }
                                                let currentTime = new Date()
                                                let PickUpDateTime = moment(log.order.PickUpDateTime).utc().format('YYYY-MM-DDTHH:mm:ss.000')
                                                PickUpDateTime = new Date(PickUpDateTime)
                                                let ArrivedTime = moment(log.order.ArrivedTime).format('YYYY-MM-DDTHH:mm:ss.000')
                                                ArrivedTime = new Date(ArrivedTime)
                                                let DeliveryTime = moment(log.order.DeliveryTime).format('YYYY-MM-DDTHH:mm:ss.000')
                                                DeliveryTime = new Date(DeliveryTime)
                                                let waitingTimeInMilliseconds;
                                                if (log.order.DeliveryTime && log.order.ArrivedTime) waitingTimeInMilliseconds = moment(log.order.DeliveryTime).diff(moment(log.order.ArrivedTime), "milliseconds");
                                                else if (log.order.PickUpDateTime && log.order.ArrivedTime) {
                                                    let PickUpDateTimeLocal = moment(log.order.PickUpDateTime).utc().format("YYYY-MM-DDTHH:mm:ss.000")
                                                    waitingTimeInMilliseconds = moment(PickUpDateTimeLocal).diff(moment(log.order.ArrivedTime), "milliseconds")
                                                }
                                                else if (log.order.ArrivedTime) waitingTimeInMilliseconds = moment(currentTime).diff(moment(log.order.ArrivedTime), "milliseconds");
                                                PickUpDateTime = moment(PickUpDateTime).format('MM/DD/YYYY HH:mm')
                                                ArrivedTime = moment(ArrivedTime).format('MM/DD/YYYY HH:mm')
                                                DeliveryTime = moment(DeliveryTime).format('MM/DD/YYYY HH:mm')
                                                let WaitingTime = convertTimeFromMilliseconds(waitingTimeInMilliseconds)
                                                return (
                                                    <tr>
                                                        <td>{log.order.OrderNumber}</td>
                                                        <td>{log.order.BranchName}</td>
                                                        <td>{oldStatus ? oldStatus : 'N/A'}</td>
                                                        <td>{newStatus ? newStatus : 'N/A'}</td>
                                                        <td>{log.Reason ? log.Reason : 'N/A'}</td>
                                                        <td>{dateTimeLastModified}</td>
                                                        <td>{PickUpDateTime && PickUpDateTime !== "Invalid date" ? PickUpDateTime : "N/A"}</td>
                                                        <td>{ArrivedTime && ArrivedTime !== "Invalid date" ? ArrivedTime : "N/A"}</td>
                                                        <td>{DeliveryTime && DeliveryTime !== "Invalid date" ? DeliveryTime : "N/A"}</td>
                                                        <td>{WaitingTime && WaitingTime !== "Invalid date" ? WaitingTime : "N/A"}</td>
                                                        {/* <td>Topias Kantola</td> */}
                                                        {/* <td>25</td>
                                                        <td>July 8, 1:30pm</td>
                                                        <td>July 3</td>
                                                        <td>-</td>
                                                        <td>25</td>
                                                        <td class="text-primary">Customer Arrived</td> */}
                                                    </tr>
                                                )
                                            })
                                            : <h2 className="text-center"><b>No results found</b></h2>
                                    }
                                </tbody>
                            </table>
                            <div className="float-right">
                                {props.log_count ?
                                    <Pagination
                                        activePage={props.pageNumber}
                                        itemsCountPerPage={props.ViewOrderLimitPerPage}
                                        totalItemsCount={props.log_count}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => props.setPageNumber({ ...props.filterParams, fromFilter: false, pageNumber: e })}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    /> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OrderLogs