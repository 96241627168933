import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import OrdersPage from '../../components/OrdersPage/OrdersPage'
import * as actions from '../../redux/actions/index'
import moment from 'moment'
import 'moment-timezone'
import { OrderStatus, routes, UserRoles, ViewOrderLimitPerPage } from '../../utility/constants/constants'
import { branch } from 'recompose'
import Oux from '../../hoc/Oux/Oux'
import PhoneNumberOrders from '../../components/PhoneNumberOrders/PhoneNumberOrders'

const OrderSearch = props => {

    const didMountRef = useRef(false)
    const [orderStatus, setOrderStatus] = useState(OrderStatus.CUSTOMER_ARRIVED)
    let [timerCount, setTimerCount] = useState(1)
    const [searchText, setSearchText] = useState('')
    const searchTextRef = useRef('')
    const [errorMsg, setErrorMsg] = useState('')
    let phoneNumber = new URLSearchParams(props.history.location.search, { ignoreQueryPrefix: true }).get('phone_number')

    useEffect(() => {
        let orderNumber = new URLSearchParams(props.history.location.search, { ignoreQueryPrefix: true }).get('order_number')
        let phoneNumber = new URLSearchParams(props.history.location.search, { ignoreQueryPrefix: true }).get('phone_number')
        if (didMountRef.current) {
            //DidUpdate
            if (!props.selectedBranch && props.orders && props.orders.length > 0) {
                window.$('[data-toggle="tooltip"]').tooltip()
                props.orders.map(order => {
                    if (props.branches && props.branches.length > 0) {
                        props.branches.map(branch => {
                            if (branch.BranchCode === order.BranchCode) {
                                props.storeSelectedBranch(branch)
                            }
                        })
                    }
                })
            }
        } else {
            didMountRef.current = true
            if (orderNumber) {
                props.searchOrderByOrderNumber(orderNumber, true)
                    .then(response => {
                        window.$('[data-toggle="tooltip"]').tooltip()
                        if (response.value.success || response.value.success === 'true') {
                            if (response.value.orders && response.value.orders.length > 0) {
                                response.value.orders.map(order => {
                                    if (order.LocalOrderStatus === OrderStatus.CUSTOMER_ARRIVED.apiKey && !(order.OrderStatus === OrderStatus.INVOICED.apiKey || order.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)) {
                                        setOrderStatus(OrderStatus.CUSTOMER_ARRIVED)
                                    } else if (order.OrderStatus === OrderStatus.PICK_CONFIRMED.apiKey && order.LocalOrderStatus !== OrderStatus.CUSTOMER_ARRIVED.apiKey) {
                                        setOrderStatus(OrderStatus.PICK_CONFIRMED)
                                    } else {
                                        setOrderStatus(OrderStatus.INVOICED)
                                    }
                                    if (props.branches && props.branches.length > 0) {
                                        props.branches.map(branch => {
                                            if (branch.BranchCode === order.BranchCode) {
                                                props.storeSelectedBranch(branch)
                                            }
                                        })
                                    }
                                })
                            } else {
                                props.history.push(routes.HOME)
                            }
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                console.log(orderNumber, "orderNumber")
            } else if (phoneNumber) {
                props.searchOrderByPhoneNumber(phoneNumber)
                    .then(response => {
                        window.$('[data-toggle="tooltip"]').tooltip()
                        if (response.value.success || response.value.success === 'true') {
                            if (response.value.orders && response.value.orders.length > 0) {
                                response.value.orders.map(order => {
                                    if (props.branches && props.branches.length > 0) {
                                        props.branches.map(branch => {
                                            if (branch.BranchCode === order.BranchCode) {
                                                props.storeSelectedBranch(branch)
                                            }
                                        })
                                    }
                                })
                            } else {
                                props.history.push(routes.HOME)
                            }
                        }
                    }).catch(error => {
                        console.log(error)
                    })
            } else {
                if (props.user) {
                    if (props.user.role.toLowerCase() === UserRoles.admin.toLowerCase()) {
                        props.history.push(routes.DASHBOARD)
                    } else {
                        props.history.push(routes.ORDER_STATUS)
                    }
                } else {
                    props.history.push(routes.LOGIN)
                }
            }
        }
    }, [props.branches])

    const onChangeSearchText = (event) => {
        setSearchText(event.target.value)
        searchTextRef.current = event.target.value
        if (!event.target.value) {
            setErrorMsg('')
        } else if (!/^[0-9\s]+$/.test(event.target.value)) {
            setErrorMsg('Order number should be in digits only')
        }
        else if (event.target.value.length < 5) {
            setErrorMsg('Please add minimum of 5 digit order number')
        } else {
            setErrorMsg('')
        }
    }

    const onClickResetBtn = () => {
        let orderNumber = new URLSearchParams(props.history.location.search, { ignoreQueryPrefix: true }).get('order_number')
        let phoneNumber = new URLSearchParams(props.history.location.search, { ignoreQueryPrefix: true }).get('phone_number')
        if (searchText) {
            setSearchText('')
            setErrorMsg('')
            if (orderNumber) {
                props.searchOrderByOrderNumber(orderNumber, true)
                    .then(response => {
                        window.$('[data-toggle="tooltip"]').tooltip()
                        if (response.value.success || response.value.success === 'true') {
                            if (response.value.orders && response.value.orders.length > 0) {
                                response.value.orders.map(order => {
                                    if (order.LocalOrderStatus === OrderStatus.CUSTOMER_ARRIVED.apiKey && !(order.OrderStatus === OrderStatus.INVOICED.apiKey || order.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)) {
                                        setOrderStatus(OrderStatus.CUSTOMER_ARRIVED)
                                    } else if (order.OrderStatus === OrderStatus.PICK_CONFIRMED.apiKey && order.LocalOrderStatus !== OrderStatus.CUSTOMER_ARRIVED.apiKey) {
                                        setOrderStatus(OrderStatus.PICK_CONFIRMED)
                                    } else {
                                        setOrderStatus(OrderStatus.INVOICED)
                                    }
                                    if (props.branches && props.branches.length > 0) {
                                        props.branches.map(branch => {
                                            if (branch.BranchCode === order.BranchCode) {
                                                props.storeSelectedBranch(branch)
                                            }
                                        })
                                    }
                                })
                            } else {
                                props.history.push(routes.HOME)
                            }
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                console.log(orderNumber, "orderNumber")
            } else if (phoneNumber) {
                props.searchOrderByPhoneNumber(phoneNumber)
                    .then(response => {
                        window.$('[data-toggle="tooltip"]').tooltip()
                        if (response.value.success || response.value.success === 'true') {
                            if (response.value.orders && response.value.orders.length > 0) {
                                response.value.orders.map(order => {
                                    if (props.branches && props.branches.length > 0) {
                                        props.branches.map(branch => {
                                            if (branch.BranchCode === order.BranchCode) {
                                                props.storeSelectedBranch(branch)
                                            }
                                        })
                                    }
                                })
                            } else {
                                props.history.push(routes.HOME)
                            }
                        }
                    }).catch(error => {
                        console.log(error)
                    })
            }
        }
    }

    const onClickSearchBtn = (event) => {
        event.preventDefault()
        let orderNumber = null
        orderNumber = searchText.replace(/\s/g, "")
        if (orderNumber) {
            if (orderStatus.key === OrderStatus.INVOICED.key) {
                props.searchOrderByOrderNumber(orderNumber, false, [OrderStatus.INVOICED.apiKey, OrderStatus.INVOICED.alternativeApiKey])
            } else {
                props.searchOrderByOrderNumber(orderNumber, false, [orderStatus.apiKey])
            }
        }
        else {
            // if (orderStatus.key === OrderStatus.INVOICED.key) {
            //     props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, pageNumber, [orderStatus.apiKey, orderStatus.alternativeApiKey])
            // } else {
            //     props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, pageNumber, orderStatus.apiKey)
            // }
        }
    }
    return (
        <Oux>
            {
                phoneNumber
                    ? <PhoneNumberOrders
                        history={props.history}
                        orderStatus={orderStatus}
                        isLoading={props.isLoading}
                        isOrderUpdating={props.isOrderUpdating}
                        orders={props.orders}
                        storeOrderToUpdate={props.storeOrderToUpdate}
                        user={props.user}
                        searchText={searchText}
                        searchTextRef={searchTextRef}
                        onChangeSearchText={onChangeSearchText}
                        onClickResetBtn={onClickResetBtn}
                        onClickSearchBtn={onClickSearchBtn}
                        errorMsg={errorMsg}
                        updateOrder={props.updateOrder}
                        orderToUpdate={props.orderToUpdate}
                        setOrderStatus={setOrderStatus}
                        totalCount={props.totalCount}
                        toggleDeliveryModalStatus={props.toggleDeliveryModalStatus}
                    />
                    : <OrdersPage
                        isDirectSearch={true}
                        history={props.history}
                        orderStatus={orderStatus}
                        isLoading={props.isLoading}
                        isOrderUpdating={props.isOrderUpdating}
                        orders={props.orders}
                        storeOrderToUpdate={props.storeOrderToUpdate}
                        user={props.user}
                        updateOrder={props.updateOrder}
                        orderToUpdate={props.orderToUpdate}
                        setOrderStatus={setOrderStatus}
                        totalCount={props.totalCount}
                        toggleDeliveryModalStatus={props.toggleDeliveryModalStatus}
                    />
            }
        </Oux>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        branches: state.configReducer.branches,
        orders: state.orderReducer.orders,
        isLoading: state.orderReducer.isLoading,
        isOrderUpdating: state.orderReducer.isOrderUpdating,
        orderToUpdate: state.orderReducer.orderToUpdate,
        totalCount: state.orderReducer.totalCount,
        selectedBranch: state.orderReducer.selectedBranch,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeOrderToUpdate: (orderToUpdate) => dispatch(actions.storeOrderToUpdate(orderToUpdate)),
        storeLatestOrders: (orders) => dispatch(actions.storeLatestOrders(orders)),
        searchOrderByOrderNumber: (orderNumber, directSearch) => dispatch(actions.searchOrderByOrderNumber(orderNumber, directSearch)),
        updateOrder: (orderID, order) => dispatch(actions.updateOrder(orderID, order)),
        toggleDeliveryModalStatus: (status) => dispatch(actions.toggleDeliveryModalStatus(status)),
        storeSelectedBranch: (branch) => dispatch(actions.storeSelectedBranch(branch)),
        searchOrderByPhoneNumber: (phoneNumber) => dispatch(actions.searchOrderByPhoneNumber(phoneNumber))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSearch)