import React, { useState } from 'react'
import './ExportData.css'
import { Form, Formik, Field } from 'formik'
import { exportDataForm } from '../../utility/validator/formValidation'
import { routes, formInputTextErrorStyle, ViewOrderLimitPerPage, LogsType } from '../../utility/constants/constants';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import 'moment-timezone'
import { CSVLink, CSVDownload } from "react-csv";
import LoadingBtn from '../UI/LoadingButton/LoadingButton';
import $ from 'jquery'
// import LoadingBtn from '../UI/LoadingButton/LoadingButton';


const ExportDataForm = props => {
    const defaultVal = {
        start_date: "",
        end_date: ""
    };
    const [isExportData, setExportData] = useState(false);
    const [initialFormValues, setFormValue] = useState(defaultVal);
    const [loaded, setLoaded] = useState(false);

    if (props.isLogs) {
        if (!loaded) {
            setLoaded(true);
            let end_date = new Date()
            let start_date = new Date()
            start_date.setDate(end_date.getDate() - 7)
            setFormValue({
                start_date: start_date,
                end_date: end_date
            })
        }
    }

    // if(props.exportData.length > 0){
    //     debugger
    //     const csvFromArrayOfObjects = convertArrayToCSV(props.exportData);
    //     console.log(csvFromArrayOfObjects, "excelFile")
    // }

    return (
        <section class="export_sec wrap-login100">
            <div class="export_form">
                <article className="ims_art ims_flex ims_flex_v ">
                    <h3 className="font-weight-bold">{
                        props.isLogs ? null : 'Export Data'
                    }</h3>
                </article>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialFormValues}
                    onSubmit={(values, { setFieldError, setFieldTouched }) => {
                        if (!values.start_date) {
                            setFieldError('start_date', 'This field is required')
                        }
                        else if (values.to_date && !values.start_date) {
                            setFieldError('start_date', 'This field is required')
                        }
                        else if (values.start_date && !values.end_date) {
                            setFieldError('end_date', 'This field is required')
                        } else {
                            let startDate = moment(values.start_date).format('MM-DD-YYYY')
                            let endDate = moment(values.end_date).format('MM-DD-YYYY')
                            if (props.isLogs) {
                                let branchCode = "all"
                                if (props.selectedBranch) {
                                    branchCode = props.selectedBranch.BranchCode
                                }
                                props.setPageNumber({ fromFilter: true, ViewOrderLimitPerPage: props.ViewOrderLimitPerPage, pageNumber: isExportData ? props.pageNumber : 1, start_date: startDate, end_date: endDate })
                                if (props.logsType === LogsType.AUDIT_LOGS) {
                                    props.fetchUserAuditLogs(startDate, endDate, props.pageNumber, ViewOrderLimitPerPage, props.user.id, branchCode, isExportData).then(res => {
                                        if (isExportData) {
                                            setExportData(false);
                                            props.handleResForExport(res);
                                        }
                                    })
                                } else if (props.logsType === LogsType.ORDER_LOGS) {
                                    props.fetchOrderLogs(startDate, endDate, props.pageNumber, ViewOrderLimitPerPage, branchCode, isExportData).then(res => {
                                        if (isExportData) {
                                            setExportData(false);
                                            props.handleResForExport(res);
                                        }
                                    })
                                } else {
                                    props.fetchNotificationLogs(startDate, endDate, props.pageNumber, ViewOrderLimitPerPage, branchCode, isExportData).then(res => {
                                        if (isExportData) {
                                            setExportData(false);
                                            props.handleResForExport(res);
                                        }
                                    })
                                }
                            } else {
                                props.fetchExportData(startDate, endDate, props.selectedBranch ? props.selectedBranch.BranchCode : "all")
                                    .then(response => {
                                        if (response.value.orders && response.value.orders.length > 0) {
                                            var downloadLink = document.createElement("a");
                                            downloadLink.href = $('#export_data_download').attr('href')
                                            downloadLink.download = `${props.selectedBranch ? props.selectedBranch.Name : 'All Branches'} ${startDate} to ${endDate}.csv`;
                                            downloadLink.click()
                                        }
                                    }).catch(error => {
                                        console.log(error)
                                    })
                            }
                            console.log(values, 'Values')
                        }
                    }}>
                    {(formik_props) => {
                        const errors = formik_props.errors
                        const touched = formik_props.touched
                        console.log(errors, "errors")
                        return (
                            <Form>
                                <div class="inner_form">
                                    <div class="fields">
                                        <div class="form-row">
                                            <div class="col-md-4 col-6">
                                                <label for="start_date" class="label_modify text-left">Start Date</label>
                                                {/* <Field id="start_date" type="text" class="input_modify" style={errors.start_date && touched.start_date ? formInputErrorStyle : null} name="start_date" /> */}
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        autoOk
                                                        variant="inline"
                                                        name="start_date"
                                                        inputVariant="standard"
                                                        className={errors.start_date && touched.start_date ? "input_modify input_modify_lg end_date_picker export_data_date_picker error" : "input_modify input_modify_lg end_date_picker export_data_date_picker"}
                                                        // InputAdornmentProps={{ position: "start" }}
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        maxDate={new Date()}
                                                        // label="Date picker dialog"
                                                        format="MM/dd/yyyy"
                                                        value={formik_props.values.start_date ? formik_props.values.start_date : null}
                                                        onChange={(date) => {
                                                            formik_props.setFieldValue('start_date', date)
                                                        }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <span style={formInputTextErrorStyle}>{errors.start_date && touched.start_date && errors.start_date}</span>
                                            </div>
                                            <div class="col-md-4 col-6">
                                                <label for="end_date" class="label_modify text-left">End Date</label>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        autoOk
                                                        variant="inline"
                                                        name="end_date"
                                                        inputVariant="standard"
                                                        className={errors.end_date && touched.end_date ? "input_modify input_modify_lg end_date_picker export_data_date_picker error" : "input_modify input_modify_lg end_date_picker export_data_date_picker"}
                                                        // InputAdornmentProps={{ position: "start" }}
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        // label="Date picker dialog"
                                                        format="MM/dd/yyyy"
                                                        minDate={formik_props.values.start_date ? formik_props.values.start_date : null}
                                                        maxDate={new Date()}
                                                        disabled={!formik_props.values.start_date}
                                                        value={formik_props.values.end_date ? formik_props.values.end_date : null}
                                                        onChange={(date) => formik_props.setFieldValue('end_date', date)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                {/* <Field id="end_date" type="text" class="input_modify" style={errors.end_date && touched.end_date ? formInputErrorStyle : null} name="end_date" /> */}
                                                <span style={formInputTextErrorStyle}>{errors.end_date && touched.end_date && errors.end_date}</span>
                                            </div>
                                            <div class="col-md-2 col-12">
                                                {
                                                    props.isExporting
                                                        ? <LoadingBtn btnClassName="custom_btn theme_tertiary theme_btn text-uppercase btn-block" btnTitle="Please Wait" />
                                                        : <button class="custom_btn theme_tertiary theme_btn text-uppercase btn-block" type="submit">Submit</button>
                                                }
                                            </div>
                                            {
                                                props.isLogs && <div class="col-md-2 col-12">
                                                    {
                                                        props.isExporting
                                                            ? <LoadingBtn btnClassName="custom_btn theme_tertiary theme_btn text-uppercase btn-block" btnTitle="Please Wait" />
                                                            : <button class="custom_btn theme_tertiary theme_btn text-uppercase btn-block" type="submit" onClick={() => setExportData(true)}>Export</button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    props.exportData && props.exportData.length > 0
                                        ? <CSVLink
                                            data={props.exportData}
                                            filename={"my-file.csv"}
                                            className="btn btn-primary export_data_download"
                                            id="export_data_download"
                                            target="_blank"
                                        >
                                            Download
                                          </CSVLink>
                                        : null
                                }
                                {
                                    props.exportedData && props.exportedData.length > 0
                                        ? <CSVLink
                                            data={props.exportedData}
                                            filename={"logs-file.csv"}
                                            className="btn btn-primary export_data_download"
                                            id="export_logs_data_download"
                                            target="_blank"
                                        >
                                            Download
                                          </CSVLink>
                                        : null
                                }
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </section>
    )
}

export default ExportDataForm