import React from 'react'
import { defaultImagePath } from '../../utility/utility'
import { OrderStatus, DateFilterTypes } from '../../utility/constants/constants';
import Skeleton from 'react-loading-skeleton';
import Oux from '../../hoc/Oux/Oux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Accessibility from 'highcharts/modules/accessibility'
import SpinnerLoader from '../UI/SpinnerLoader/SpinnerLoader';
import ExportData from '../../containers/ExportData/ExportData';

Accessibility(Highcharts);

const dashboardPage = props => {

    const chartOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Monthly'
        },
        xAxis: {
            categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: 'Ready',
            data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]

        }, {
            name: 'Arrived',
            data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6, 92.3]

        }, {
            name: 'Completed',
            data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2]

        }]
    }

    const reDirect = (status) => {
        props.history.push('/ims-admin', { status: status });
    }
    let customerArrivedTotal = props.arrived_count
    let pickConfirmedTotal = props.ready_count
    let invoicedTotal = props.delivered_count
    let totalOrders = customerArrivedTotal + pickConfirmedTotal + invoicedTotal

    const mystyle = {
        cursor: "pointer"
    };
    return (
        <section className="theme_nav_mt ims_sec">
            <div className="container-fluid ims_mx30">
                {
                    props.isConfigLoading
                        ? <SpinnerLoader />
                        : <Oux>
                            <ExportData />
                            <div className="row align-items-center">
                                <div className="col-md-3 col-lg-4 col-xl-4">
                                    <article className="ims_art ims_flex ims_flex_v">
                                        <h3 className="font-weight-bold">Overview</h3>
                                    </article>
                                </div>
                                <div className="col-md-9 col-lg-8 col-xl-8" >
                                    <div className="ims_filter_cont">
                                        <div className="ims_list img_header_toolbar">
                                            <a onClick={() => props.onClickDateFilter(DateFilterTypes.TODAY)} className={props.activeDateFilter === DateFilterTypes.TODAY ? "non_href_a_tag active" : "non_href_a_tag"}>Today</a>
                                            <span className="ims_Vse">|</span>
                                            <a onClick={() => props.onClickDateFilter(DateFilterTypes.WEEK)} className={props.activeDateFilter === DateFilterTypes.WEEK ? "non_href_a_tag active" : "non_href_a_tag"}>Week</a>
                                            <a onClick={() => props.onClickDateFilter(DateFilterTypes.MONTH)} className={props.activeDateFilter === DateFilterTypes.MONTH ? "non_href_a_tag active" : "non_href_a_tag"}>Month</a>
                                            <a onClick={() => props.onClickDateFilter(DateFilterTypes.YEAR)} className={props.activeDateFilter === DateFilterTypes.YEAR ? "non_href_a_tag active" : "non_href_a_tag"}>Year</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    props.isOrderLoading

                                        ? <Oux>
                                            <div className="col-sm-6 col-lg-4" style={mystyle}>
                                                <Skeleton count={1} width='100%' height={100} />
                                            </div>
                                            <div className="col-sm-6 col-lg-4" style={mystyle}>
                                                <Skeleton count={1} width='100%' height={100} />
                                            </div>
                                            <div className="col-sm-6 col-lg-4" style={mystyle}>
                                                <Skeleton count={1} width='100%' height={100} />
                                            </div>
                                        </Oux>
                                        : <Oux>
                                            <div className="col-sm-6 col-lg-4" onClick={() => reDirect(OrderStatus.PICK_CONFIRMED.apiKey)} style={mystyle}>
                                                <div className="card ims_card ims_icn_box_card">
                                                    <div className="card-body">
                                                        <article className="ims_art ims_icn_box_cnt">
                                                            <h2 className="font-weight-bold">
                                                                Ready
                                                            </h2>
                                                            <h3 className="ft_Weight_600 mb-0 text-tertiary">{pickConfirmedTotal}</h3>
                                                        </article>
                                                        <div className="ims_icn_box">
                                                            <img src={defaultImagePath('icons/icn_copy_check.png')} className="img-fluid" alt="Ready" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-4" onClick={() => reDirect(OrderStatus.CUSTOMER_ARRIVED.apiKey)} style={mystyle}>
                                                <div className="card ims_card ims_icn_box_card">
                                                    <div className="card-body">
                                                        <article className="ims_art ims_icn_box_cnt">
                                                            <h2 className="font-weight-bold">
                                                                Arrived
                                                            </h2>
                                                            <h3 className="ft_Weight_600 mb-0 text-tertiary">{customerArrivedTotal}</h3>
                                                        </article>
                                                        <div className="ims_icn_box">
                                                            <img src={defaultImagePath('icons/icn_arrived.png')} className="img-fluid" alt="Arrived" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-lg-4" onClick={() => reDirect([OrderStatus.INVOICED.apiKey, OrderStatus.INVOICED.alternativeApiKey])} style={mystyle}>
                                                <div className="card ims_card ims_icn_box_card">
                                                    <div className="card-body">
                                                        <article className="ims_art ims_icn_box_cnt">
                                                            <h2 className="font-weight-bold">
                                                                Completed
                                                            </h2>
                                                            <h3 className="ft_Weight_600 mb-0 text-tertiary">{invoicedTotal}</h3>
                                                        </article>
                                                        <div className="ims_icn_box">
                                                            <img src={defaultImagePath('icons/icn_completed.png')} className="img-fluid" alt="Completed" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Oux>
                                }
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card ims_card">
                                        <div className="card-body">
                                            <article className="ims_art">
                                                <h2 className="font-weight-bold">
                                                    Month's Total
                                                </h2>
                                                <h3 className="ft_Weight_600 mb-0 text-tertiary">{totalOrders}</h3>
                                            </article>
                                            <figure className="ims_fig ims_fig_mt">
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={chartOptions}
                                                />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Oux>
                }
            </div>
        </section>
    )
}

export default dashboardPage