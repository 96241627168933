import React from 'react'
import { connect } from 'react-redux'
import LoginForm from '../../components/LoginForm/LoginForm'
import * as actions from '../../redux/actions/index'

const Login = (props) => {

    return (
        <LoginForm login={props.Login} isLoading={props.isLoading} {...props} />
    )

}


const mapStateToProps = (state) => {
    return {
        isLoading: state.authReducer.isloading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (credentials) => dispatch(actions.login(credentials))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)