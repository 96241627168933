import { combineReducers } from 'redux';
import { authReducer } from './users/authReducer'
import { historyReducer } from './users/historyReducer'
import { configReducer } from './users/configReducer'
import { orderReducer } from './users/orderReducer'
import { userReducer } from './users/userReducer'

const reducers = combineReducers({
        authReducer,
        historyReducer,
        configReducer,
        orderReducer,
        userReducer
});

export default reducers;
