import React, { useEffect, useState } from 'react'
import AddUser from '../../components/Settings/AddUser'
import Notification from '../../components/Settings/Notification'
import { connect } from 'react-redux'
import * as actions from '../../redux/actions/index'
import { showConfirmAlert } from '../../utility/successAlert/confirmAlert'
import SpinnerLoader from '../../components/UI/SpinnerLoader/SpinnerLoader'
import { UserRoles } from '../../utility/constants/constants';
import { useRef } from 'react'
import $ from 'jquery';

const BranchSettings = (props) => {

    const didMountRef = useRef(false)
    const [showForm, setShowForm] = useState(false)
    const [userData, setUserData] = useState(null)

    useEffect(() => {
        if (didMountRef.current) {
            if (props.selectedBranch) {
                props.fetchUserList(props.selectedBranch.BranchCode)
                props.fetchBranchSettings(props.selectedBranch.BranchCode)
            }
        } else {
            didMountRef.current = true
            if (props.selectedBranch) {
                props.fetchUserList(props.selectedBranch.BranchCode)
                props.fetchBranchSettings(props.selectedBranch.BranchCode)
            }
        }
    }, [props.selectedBranch])

    const onToggleNotificationSetting = (event) => {

        if (event.target.checked) {
            showConfirmAlert("Please Confirm", 'Are you sure you want to turn on the notifications?', () => {
                props.toggleBranchNotification({ settings: { NotificationStatus: true } }, props.selectedBranch.BranchCode)
            })
        }
        else {
            showConfirmAlert("Please Confirm", 'Are you sure you want to turn off the notifications?', () => {
                props.toggleBranchNotification({ settings: { NotificationStatus: false } }, props.selectedBranch.BranchCode)
            })
        }
    }

    const onToggleReminderNotificationSetting = (event) => {
        if (event.target.checked) {
            showConfirmAlert("Please Confirm", 'Are you sure you want to turn on the notifications?', () => {
                props.toggleBranchNotification({ settings: { ReminderNotificationStatus: true } }, props.selectedBranch.BranchCode)
            })
        }
        else {
            showConfirmAlert("Please Confirm", 'Are you sure you want to turn off the notifications?', () => {
                props.toggleBranchNotification({ settings: { ReminderNotificationStatus: false } }, props.selectedBranch.BranchCode)
            })
        }
    }

    const onToggleReadyNotificationSetting = (event) => {
        if (event.target.checked) {
            showConfirmAlert("Please Confirm", 'Are you sure you want to turn on the notifications?', () => {
                props.toggleBranchNotification({ settings: { ReadyNotificationStatus: true } }, props.selectedBranch.BranchCode)
            })
        }
        else {
            showConfirmAlert("Please Confirm", 'Are you sure you want to turn off the notifications?', () => {
                props.toggleBranchNotification({ settings: { ReadyNotificationStatus: false } }, props.selectedBranch.BranchCode)
            })
        }
    }

    const onToggleConfirmNotificationSetting = (event) => {
        if (event.target.checked) {
            showConfirmAlert("Please Confirm", 'Are you sure you want to turn on the notifications?', () => {
                props.toggleBranchNotification({ settings: { ConfirmNotificationStatus: true } }, props.selectedBranch.BranchCode)
            })
        }
        else {
            showConfirmAlert("Please Confirm", 'Are you sure you want to turn off the notifications?', () => {
                props.toggleBranchNotification({ settings: { ConfirmNotificationStatus: false } }, props.selectedBranch.BranchCode)
            })
        }
    }


    const onToggleForm = (event, value, user) => {
        if (event) {
            if (event.target.id !== 'delete_icon') {
                setUserData(user)
                setShowForm(value)
            }
        } else {
            setUserData(user)
            setShowForm(value)
        }
        if (user) {
            $('html, body').animate({
                scrollTop: $("#target_add_user").offset().top
            }, 2000);
        }
    }

    return (
        <section class="theme_nav_mt">
            <div class="container">
                {
                    props.isLoading || props.isConfigLoading
                        ? <SpinnerLoader />
                        : <div class="vertical_tabs_cont">
                            <div class="vertical_tabs_colL vertical_tabs_col">
                                <div class="tab_list_block tab_list_block_767">
                                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <a class="nav-link active" id="add_user_tab" data-toggle="pill" href="#add_user" role="tab" aria-controls="add_user" aria-selected="false">User</a>
                                        <a class="nav-link" id="notification_settings_tab" data-toggle="pill" href="#notification_settings" role="tab" aria-controls="notification_settings" aria-selected="false">Notification setting</a>
                                    </div>
                                </div>
                            </div>
                            <div class="vertical_tabs_colR vertical_tabs_col">
                                <div class="tab_list_block">
                                    <div class="tab-content mt-72 v_tabs_content input_pro_edit_tabs" id="v-pills-tabContent">
                                    
                                        <AddUser userData={userData} {...props} showForm={showForm} onToggleForm={onToggleForm} />
                                        <Notification
                                            isBranchSetting={true}
                                            onToggleNotificationSetting={onToggleNotificationSetting}
                                            onToggleReminderNotificationSetting={onToggleReminderNotificationSetting}
                                            onToggleReadyNotificationSetting={onToggleReadyNotificationSetting}
                                            onToggleConfirmNotificationSetting={onToggleConfirmNotificationSetting}
                                            {...props}
                                        />
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.orderReducer.isLoading,
        isUserLoading: state.userReducer.isLoading,
        isConfigLoading: state.configReducer.isConfigLoading,
        branchSettings: state.orderReducer.branchSettings,
        selectedBranch: state.orderReducer.selectedBranch,
        usersList: state.userReducer.usersList,
        isSettingsLoading: state.orderReducer.isSettingsLoading,
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleBranchNotification: (status, branchCode) => dispatch(actions.toggleBranchNotification(status, branchCode)),
        fetchBranchSettings: (branchCode) => dispatch(actions.fetchBranchSettings(branchCode)),
        addUser: (user) => dispatch(actions.addUser(user)),
        updateUser: (userId, user) => dispatch(actions.updateUser(userId, user)),
        deleteUser: (userId) => dispatch(actions.deleteUser(userId)),
        fetchUserList: (branchCode) => dispatch(actions.fetchUserList(branchCode)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchSettings)