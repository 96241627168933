import axios from 'axios';
import swal from 'sweetalert';
import { reportError } from '../../redux/actions/usersActions/configAction';
import store from '../../redux/store/store'


export default function errorHandler(error) {
    const storeState = store.getState();
    const history = storeState.historyReducer.history;
    let currentRoute = history.location.pathname
    let request_body = null
    let apiUrl = null
    if (error.config) {
        request_body = error.config.data
        apiUrl = error.config.baseURL + error.config.url
    }
    if (error.config && !error.config.url.includes('error/log')) {
        store.dispatch(reportError({ error: error, route: currentRoute, api: apiUrl, request_body: request_body }))
    }
    if (axios.isCancel(error)) {
        return Promise.reject(error);
    }
    if (error.data && !error.data.error.message.includes('401')) {
        swal({
            icon: '',
            title: 'Oops!',
            text: error.data.error.message,
            className: 'error-handler-modal'
        });
    } else if (error.message && !error.message.includes('401')) {
        swal({
            icon: '',
            title: 'Oops!',
            text: error.message,
            className: 'error-handler-modal'
        });
    }

    return Promise.reject(error.message);
}
