import React from 'react'
import SpinnerLoader from '../UI/SpinnerLoader/SpinnerLoader'
import moment, { utc } from 'moment'
import 'moment-timezone'
import { convertTime, convertTimeFromMilliseconds } from "../../utility/utility";
import { OrderStatus } from '../../utility/constants/constants'
import Oux from '../../hoc/Oux/Oux'

const OrderDetailsPage = ({ history, ...props }) => {
    let content = (
        <section className="mpv_body_warpe">
            <div className="mpv_con">
                <SpinnerLoader />
            </div>
        </section>
    )
    console.log(props.orderAllDetails, "orderAllDetails");
    if (props.orderAllDetails) {

        let arrivedTime = null
        let currentTime = new Date()
        let diffTime = null
        let pickUpDateTime = null
        let deliveryTime = null
        let finalDeliveredTime = null
        let pickConfirmedTime = null
        if (props.orderAllDetails.ArrivedTime) {
            arrivedTime = moment(props.orderAllDetails.ArrivedTime).format("YYYY-MM-DDTHH:mm:ss.000")
            arrivedTime = new Date(arrivedTime)
            // diffTime = moment(currentTime).diff(moment(arrivedTime), "minutes")
            arrivedTime = moment(arrivedTime).format('MM/DD/YYYY hh:mm A')
        }
        if (props.orderAllDetails.ArrivedTime && props.orderAllDetails.DeliveryTime) {
            diffTime = moment(props.orderAllDetails.DeliveryTime).diff(moment(props.orderAllDetails.ArrivedTime), "milliseconds")
        }
        else if (props.orderAllDetails.ArrivedTime && props.orderAllDetails.PickUpDateTime) {
            let PickUpDateTimeLocal = moment(props.orderAllDetails.PickUpDateTime).utc().format("YYYY-MM-DDTHH:mm:ss.000")
            diffTime = moment(PickUpDateTimeLocal).diff(moment(props.orderAllDetails.ArrivedTime), "milliseconds")
        }
        else if (props.orderAllDetails.ArrivedTime) {
            diffTime = moment(currentTime).diff(moment(props.orderAllDetails.ArrivedTime), "milliseconds")
        }
        if (props.orderAllDetails.DateTimePicked) {
            pickConfirmedTime = moment(props.orderAllDetails.DateTimePicked).utc().format('MM/DD/YYYY hh:mm A')
        }
        if (props.orderAllDetails.PickUpDateTime) {
            pickUpDateTime = moment(props.orderAllDetails.PickUpDateTime).utc().format('MM/DD/YYYY hh:mm A')
        }
        if (props.orderAllDetails.OrderStatus === OrderStatus.INVOICED.apiKey || props.orderAllDetails.OrderStatus === OrderStatus.INVOICED.alternativeApiKey) {
            if (props.orderAllDetails.DeliveryTime) {
                arrivedTime = moment(props.orderAllDetails.ArrivedTime).format("YYYY-MM-DDTHH:mm:ss.000")
                arrivedTime = new Date(arrivedTime)
                deliveryTime = moment(props.orderAllDetails.DeliveryTime).format('YYYY-MM-DDTHH:mm:ss.000')
                deliveryTime = new Date(deliveryTime)
                let deliveredTimeMilliseconds = moment(deliveryTime).diff(moment(arrivedTime), "millisecond")
                deliveryTime = moment(deliveryTime).format('MM/DD/YYYY hh:mm A')
                var seconds = Math.floor(deliveredTimeMilliseconds / 1000)
                var deliveredTimeMinutes = Math.floor(Math.round(seconds / 60))
                seconds = seconds % 60
                var deliveredTimeHours = Math.floor(deliveredTimeMinutes / 60)
                deliveredTimeMinutes = deliveredTimeMinutes % 60
                var day = Math.floor(deliveredTimeHours / 24)
                // deliveredTimeHours = deliveredTimeHours % 24
                if (deliveredTimeHours && deliveredTimeHours > 0) {
                    finalDeliveredTime = `${deliveredTimeHours}.${deliveredTimeMinutes} Hrs`
                } else if (deliveredTimeMinutes > 0) {
                    finalDeliveredTime = `${deliveredTimeMinutes} Minutes`
                } else if (seconds > 0) {
                    finalDeliveredTime = `${seconds} Seconds`
                }
                arrivedTime = moment(arrivedTime).format('MM/DD/YYYY hh:mm A')
            }
        }

        content = (
            <div id="wrap">
                <section class="theme_nav_mt order_details_sec">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm-8 col-md-5 mx-auto">
                                <div className="comments_details_wrapper">
                                    <div class="user_details_list_wrp">
                                        <article class="ims_art wow fadeInUp details_title_wrap">
                                            <h3 class="text-left mb-0"> Order details</h3>
                                            <a className="theme_btn theme_primary text-white" onClick={() => history.goBack()}>Back</a>
                                        </article>
                                        <ul class="list-group order_details_list">
                                            <li>
                                                {
                                                    props.orderAllDetails.ContactName
                                                        ? <div class="order_items">
                                                            <p class="info_title">Name</p>
                                                            <p class="info_val">
                                                                {props.orderAllDetails.ContactName}
                                                            </p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    props.orderAllDetails.ContactEmailAddress
                                                        ? <div class="order_items">
                                                            <p class="info_title">Email</p>
                                                            <p class="info_val">
                                                                {props.orderAllDetails.ContactEmailAddress}
                                                            </p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    props.orderAllDetails.ContactNumber
                                                        ? <div class="order_items">
                                                            <p class="info_title">Contact Number</p>
                                                            <p class="info_val">
                                                                {props.orderAllDetails.ContactNumber}
                                                            </p>
                                                        </div>
                                                        : null
                                                }
                                                <div class="order_items">
                                                    <p class="info_title">Order number</p>
                                                    <p class="info_val">{props.orderAllDetails.OrderNumber}</p>
                                                </div>
                                            </li>
                                            <li>
                                                {
                                                    diffTime
                                                        ? <div class="order_items">
                                                            <p class="info_title">Waiting time</p>
                                                            <p class="info_val">{convertTimeFromMilliseconds(diffTime)}</p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    finalDeliveredTime
                                                        ? <div class="order_items">
                                                            <p class="info_title">Delivery Duration</p>
                                                            <p class="info_val">{finalDeliveredTime}</p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    arrivedTime
                                                        ? <div class="order_items">
                                                            <p class="info_title">Arrival time</p>
                                                            <p class="info_val">{arrivedTime}</p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    deliveryTime
                                                        ? <div class="order_items">
                                                            <p class="info_title">Delivery time</p>
                                                            <p class="info_val">{deliveryTime}</p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    pickConfirmedTime
                                                        ? <div class="order_items">
                                                            <p class="info_title">Pick Confirmed time</p>
                                                            <p class="info_val">{pickConfirmedTime}</p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    pickUpDateTime
                                                        ? <div class="order_items">
                                                            <p class="info_title">Pickup time</p>
                                                            <p class="info_val">{pickUpDateTime}</p>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    props.orderAllDetails.ParkingSlot
                                                        ? <div class="order_items">
                                                            <p class="info_title">Parking slot</p>
                                                            <p class="info_val">{props.orderAllDetails.ParkingSlot}</p>
                                                        </div>
                                                        : null
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="user_details_list_wrp">
                                        {
                                            (props.orderAllDetails.order_logs && props.orderAllDetails.order_logs.length > 0) || props.orderAllDetails.DateTimePicked
                                                ? <article class="ims_art wow fadeInUp">
                                                    <h3 class="text-left"> Comments</h3>
                                                </article>
                                                : null
                                        }
                                        {
                                            props.orderAllDetails.DateTimePicked
                                                ? <ul className="list-group order_details_list order_details_list_heading">
                                                    {
                                                        props.orderAllDetails.DateTimePicked
                                                            ? <li>
                                                                <p class="info_val" style={{ fontWeight: '600' }}>Order moved to {OrderStatus.PICK_CONFIRMED.title} at {moment(props.orderAllDetails.DateTimePicked).utc().format('MM/DD/YYYY hh:mm A')}</p>
                                                            </li>
                                                            : null
                                                    }
                                                </ul>
                                                : null
                                        }
                                        {
                                            props.orderAllDetails.order_logs && props.orderAllDetails.order_logs.length > 0
                                                ? <ul className="list-group order_details_list order_details_list_heading">
                                                    {
                                                        props.orderAllDetails.order_logs.map(logs => {
                                                            let statusChangeTime = moment(logs.DateTimeModified).format('MM/DD/YYYY hh:mm A')
                                                            let newStatus = null
                                                            let oldStatus = OrderStatus.CUSTOMER_ARRIVED.title
                                                            let createdAt = moment(logs.created_at).format('MM/DD/YYYY hh:mm A')
                                                            if (OrderStatus.PICK_CONFIRMED.apiKey.toString() === logs.OldStatus.toString()) {
                                                                oldStatus = OrderStatus.PICK_CONFIRMED.title
                                                            } else if (OrderStatus.CUSTOMER_ARRIVED.apiKey.toString() === logs.OldStatus.toString()) {
                                                                oldStatus = OrderStatus.CUSTOMER_ARRIVED.title
                                                            } else {
                                                                oldStatus = OrderStatus.INVOICED.title
                                                            }
                                                            if (OrderStatus.PICK_CONFIRMED.apiKey.toString() === logs.NewStatus.toString()) {
                                                                newStatus = OrderStatus.PICK_CONFIRMED.title
                                                            } else if (OrderStatus.CUSTOMER_ARRIVED.apiKey.toString() === logs.NewStatus.toString()) {
                                                                newStatus = OrderStatus.CUSTOMER_ARRIVED.title
                                                            } else {
                                                                newStatus = OrderStatus.INVOICED.title
                                                            }
                                                            return (
                                                                <li>
                                                                    <p class="info_val" style={{ fontWeight: '600' }}>Status changed from {oldStatus} to {newStatus} At {createdAt}</p>
                                                                    <div class="order_listL">
                                                                        {
                                                                            logs.Reason
                                                                                ? <div class="order_items">
                                                                                    <p class="info_title">Reason</p>
                                                                                    <p class="info_val" style={{ color: 'blue' }}>
                                                                                        {logs.Reason}
                                                                                    </p>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        {/* <div class="order_items">
                                                                            <p class="info_title">Date Modified</p>
                                                                            <p class="info_val">
                                                                                {statusChangeTime}
                                                                            </p>
                                                                        </div> */}
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
    return content
}
export default OrderDetailsPage