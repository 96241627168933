import React from 'react'
import { defaultImagePath } from '../../../utility/utility'
import Oux from '../../../hoc/Oux/Oux'
import Skeleton from 'react-loading-skeleton';
import { OrderStatus, routes } from '../../../utility/constants/constants';
import Moment from 'react-moment';
import 'moment-timezone'
import moment from 'moment'

const invoiced = props => {
    const orderDetails = (order, event) => {
        if (event.target.id !== "action_btn") {
            props.history.push({pathname: `${routes.ORDER_DETAIL}/${order.OrderNumber}`, state: {isInvoice: true}});
        }
        props.orderSummeryDetails(order);
    }
    return (
        <div class="row">
            <div class="col-lg-12">
                <div class="table-responsive">
                    {
                        props.isLoading
                            ? <div style={{ lineHeight: 2 }} className="mb-2">
                                <Skeleton count={5} height={100} />
                            </div>
                            : props.orders && props.orders.length > 0 && props.orders.filter(item => (item.OrderStatus === OrderStatus.INVOICED.apiKey || item.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)).length > 0
                                ? <table class="table table-borderless text-nowrap ims_table ims_grid_table">
                                    <tbody>
                                        {
                                            props.orders.filter(item => (item.OrderStatus === OrderStatus.INVOICED.apiKey || item.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)).map((order, index) => {
                                                let arrivedTime = moment(order.ArrivedTime).format('YYYY-MM-DDTHH:mm:ss.000')
                                                arrivedTime = new Date(arrivedTime)
                                                let deliveryTime = moment(order.DeliveryTime).format('YYYY-MM-DDTHH:mm:ss.000')
                                                deliveryTime = new Date(deliveryTime)
                                                // console.log(arrivedTime, "arrived", deliveryTime, "delivery")
                                                console.log(`${index} => OrderNumber = ${order.OrderNumber} => OrderStatus = ${order.OrderStatus}`)
                                                let deliveredTimeMilliseconds = moment(deliveryTime).diff(moment(arrivedTime), "millisecond")
                                                var seconds = Math.floor(deliveredTimeMilliseconds / 1000)
                                                var deliveredTimeMinutes = Math.floor(Math.round(seconds / 60))
                                                seconds = seconds % 60
                                                var deliveredTimeHours = Math.floor(deliveredTimeMinutes / 60)
                                                deliveredTimeMinutes = deliveredTimeMinutes % 60
                                                var day = Math.floor(deliveredTimeHours / 24)
                                                // deliveredTimeHours = deliveredTimeHours % 24
                                                let finalDeliveredTime = null
                                                if (deliveredTimeHours && deliveredTimeHours > 0) {
                                                    finalDeliveredTime = `Delivery Duration: ${deliveredTimeHours}.${deliveredTimeMinutes} Hrs`
                                                } else if (deliveredTimeMinutes > 0) {
                                                    finalDeliveredTime = `Delivery Duration: ${deliveredTimeMinutes} Minutes`
                                                } else if (seconds > 0) {
                                                    finalDeliveredTime = `Delivery Duration: ${seconds} Seconds`
                                                }
                                                return (
                                                    <Oux key={index}>
                                                        <tr className="non_href_a_tag" onClick={(event) => orderDetails(order, event)}>
                                                            <td>
                                                                <article class="ims_art">
                                                                    <h2 class="font-weight-bold mx_eql">
                                                                        {order.CompanyName}
                                                                    </h2>
                                                                    <h5 class="font-weight-bold mb-0">
                                                                        {order.ContactName}
                                                                        {
                                                                            order.ContactNumber
                                                                                ? <button type="button" class="tooltip_btn" data-toggle="tooltip" data-placement="top" title="" data-original-title={order.ContactNumber}>
                                                                                    <img src={defaultImagePath('icons/icn_not.png')} alt="Info Icon" />
                                                                                </button>
                                                                                : null
                                                                        }
                                                                    </h5>
                                                                </article>
                                                            </td>
                                                            <td>
                                                                <div class="ims_grid_id">
                                                                    <h4 class="ft_Weight_600 text-graydark1">Order Number</h4>
                                                                    <h4 class="ft_Weight_600 text-graydark1 mb-0">{order.OrderNumber}</h4>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="ims_grid_id">
                                                                    <div class="ims_info my-0">
                                                                        <h4 class="ft_Weight_600"><span class="ims_info_title">Parking Slot</span></h4>
                                                                        <h4 class="ft_Weight_600 mb-0"><span class="ims_info_val">{order.ParkingSlot ? `${order.ParkingSlot}` : '-'}</span></h4>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="text-right">
                                                                <div class="ims_grid_btn">
                                                                    <p class="ft_Weight_600 cstmr_ar_para">{order.ArrivedTime ? <span>Customer Arrived - <Moment format='MMM DD, YYYY'>{arrivedTime}</Moment></span> : 'Customer Arrived - N/A'}</p>
                                                                    <a href="#" class="theme_btn tick_success theme_btn_rds30"><img src={defaultImagePath('icons/icn_tick.svg')} alt="Tick Icon" />{finalDeliveredTime ? finalDeliveredTime : 'Delivery Duration - N/A'}</a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr class="ims_tr_gap" style={{ height: '15px' }}></tr>
                                                    </Oux>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                                : <div class="table table-borderless text-nowrap ims_table ims_grid_table">
                                    <h2 className="text-center"><b>No results found</b></h2>
                                </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default invoiced