import React, { useEffect, useState } from 'react'
import ExportDataForm from '../ExportDataForm/ExportDataForm'
import Pagination from "react-js-pagination";
import { LogsType } from '../../utility/constants/constants';
import moment from 'moment'
import 'moment-timezone'
const NotificationLogs = props => {
    const [loaded, setLoaded] = useState(false);
    const [exportedData, setExportedData] = useState([]);
    const handleResForExport = (res) => {
        let exportData = res.value.notification_logs.map(log => {
            let NotificationSentTime = moment(log.NotificationSentTime).format('MM/DD/YYYY hh:mm A')            
            return {
                'Order Number': log.order.OrderNumber,
                'Branch Name': log.order.BranchName,
                'Notification Category': log.Category,
                'Notification Type': log.NotificationType,
                'Notification Sent Time': NotificationSentTime,
                Recipient: log.Recipient
            }
        });
        setExportedData(exportData);

    };


    useEffect(() => {
        if (exportedData.length > 0) {
            setLoaded(true)
        }
    }, [exportedData])
    useEffect(() => {
        if (loaded) {
            var downloadLink = document.createElement("a");
            downloadLink.href = document.getElementById("export_logs_data_download").href//$('#export_data_download').attr('href')
            downloadLink.download = `notificationLogs.csv`;
            downloadLink.click();
            setLoaded(false);
            setExportedData([]);
        }
    }, [loaded])
    return (
        <section class="theme_nav_mt ims_sec">
            <div class="container-fluid ims_mx30">
                <ExportDataForm {...props} handleResForExport={handleResForExport} exportedData={exportedData} logsType={LogsType.NOTIFICATION_LOGS} isLogs={true} />
                <div class="row align-items-center custom_top">
                    <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
                        <article class="ims_art ims_flex ims_flex_v">
                            <h3 class="font-weight-bold">Notification Logs</h3>
                        </article>
                    </div>
                    {/* <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2 text-right">
                        <select class="input_modify cstSelect cstSelectW160">
                            <option>All Status</option>
                            <option>Customer Arrived</option>
                            <option>Ready</option>
                            <option>Complete</option>
                        </select>
                    </div> */}
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive ">
                            <table class="table table-borderless table-hover ims_table text-nowrap">
                                <thead class="ot_heading">
                                    <tr>
                                        <th scope="col">Order Number</th>
                                        <th scope="col">Branch Name</th>
                                        <th scope="col">Notification Category</th>
                                        <th scope="col">Notification Type</th>
                                        <th scope="col">Notification Sent Time</th>
                                        <th scope="col">Recipient</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Order" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="CustomerName" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Contact" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Date" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Parking" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Delivery" />
                                        </td>
                                    </tr> */}
                                    {
                                        props.logs && props.logs.length > 0
                                            ? props.logs.map(log => {
                                                return (
                                                    <tr>
                                                        <td>{log.order.OrderNumber}</td>
                                                        <td>{log.order.BranchName}</td>
                                                        <td>{log.Category}</td>
                                                        <td>{log.NotificationType}</td>
                                                        <td>{moment(log.NotificationSentTime).format('MM/DD/YYYY hh:mm A')}</td>
                                                        <td>{log.Recipient}</td>
                                                    </tr>
                                                )
                                            })
                                            : <h2 className="text-center"><b>No results found</b></h2>
                                    }
                                </tbody>
                            </table>
                            <div className="float-right">
                                {props.notification_count ?
                                    <Pagination
                                        activePage={props.pageNumber}
                                        itemsCountPerPage={props.ViewOrderLimitPerPage}
                                        totalItemsCount={props.notification_count}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => props.setPageNumber({...props.filterParams, fromFilter: false, pageNumber: e})}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    /> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NotificationLogs