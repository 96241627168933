import { UserActionTypes } from './actionType';
import * as API from '../../../api/userAPI';
import storage from '../../../utility/storage';
import { toastMsg } from '../../../utility/utility';
import store from '../../store/store';

function getHistory() {
    const storeState = store.getState();
    const history = storeState.historyReducer.history;
    return history;
}

export const addUser = (user) => dispatch => dispatch({
    type: UserActionTypes.ADD_USER,
    payload: API.addUser(user)
        .then(response => {
            if (response.data.success || response.data.success === "true") {
                const storeState = store.getState()
                const selectedBranch = storeState.orderReducer.selectedBranch;
                dispatch(fetchUserList(selectedBranch.BranchCode))
                return { ...response.data, stopLoader: false }
            } else {
                return { ...response.data, stopLoader: true }
            }
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return { ...error, stopLoader: true }
        })
});

export const fetchUserList = (branchCode) => dispatch => dispatch({
    type: UserActionTypes.FETCH_USERS_LIST,
    payload: API.fetchUsersList(branchCode)
        .then(response => {
            if (response.data.success || response.data.success === "true") {
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const updateUser = (userId, user) => dispatch => dispatch({
    type: UserActionTypes.UPDATE_USER,
    payload: API.updateUser(userId, user)
        .then(response => {
            if (response.data.success || response.data.success === "true") {
                const storeState = store.getState()
                const selectedBranch = storeState.orderReducer.selectedBranch;
                dispatch(fetchUserList(selectedBranch.BranchCode))
                return { ...response.data, stopLoader: false }
            } else {
                return { ...response.data, stopLoader: true }
            }
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return { ...error, stopLoader: true }
        })
});

export const deleteUser = (userId) => dispatch => dispatch({
    type: UserActionTypes.DELETE_USER,
    payload: API.deleteUser(userId)
        .then(response => {
            if (response.data.success || response.data.success === "true") {
                const storeState = store.getState()
                const selectedBranch = storeState.orderReducer.selectedBranch;
                dispatch(fetchUserList(selectedBranch.BranchCode))
                return { ...response.data, stopLoader: false }
            } else {
                return { ...response.data, stopLoader: true }
            }
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return { ...error, stopLoader: false }
        })
});