import React, { useEffect } from 'react'
import IMSAdminPage from '../../components/IMSAdminPage/IMSAdminPage'
import { connect } from 'react-redux'
import * as actions from '../../redux/actions/index'
import { routes } from '../../utility/constants/constants'
import moment from 'moment'
import ComingSoon from '../ComingSoon/ComingSoon'


const IMSAdmin = (props) => {

    useEffect(() => {
        console.log(props)
        if (props.history.location.state) {
            let status = props.history.location.state.status
            let end_date = new Date()
            let start_date = new Date().setDate(end_date.getDate() - 30)
            start_date = new Date(start_date)
            start_date = moment(start_date).format('MM-DD-YYYY')
            end_date = moment(end_date).format('MM-DD-YYYY')

            if (props.selectedBranch) {
                props.fetchOrdersByBranchID(props.selectedBranch.BranchID, null, null, status, start_date, end_date)
            }
        } else {
            props.history.push(routes.DASHBOARD)
        }
    }, [props.selectedBranch])

    return (
        // <IMSAdminPage {...props} />
        <ComingSoon />
    )

}


const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        orders: state.orderReducer.orders,
        isLoading: state.orderReducer.isLoading,
        isConfigLoading: state.configReducer.isisConfigLoading,
        selectedBranch: state.orderReducer.selectedBranch,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrdersByBranchID: (branchID, limit, pageNumber, status, start_date, end_date) => dispatch(actions.fetchOrdersByBranchID(branchID, limit, pageNumber, status, start_date, end_date)),
        storeLatestOrders: (orders) => dispatch(actions.storeLatestOrders(orders))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IMSAdmin)