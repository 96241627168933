import { OrderActionTypes } from './actionType';
import * as API from '../../../api/ordersAPI';
import storage from '../../../utility/storage';
import { convertTimeFromMilliseconds, toastMsg } from '../../../utility/utility';
import store from '../../store/store';
import moment from 'moment'
import 'moment-timezone'
import { NotificationCategories, OrderStatus } from '../../../utility/constants/constants';

function getHistory() {
    const storeState = store.getState();
    const history = storeState.historyReducer.history;
    return history;
}

export const fetchOrdersByBranchID = (branchID, limit, pageNumber, status, start_date, end_date) => dispatch => dispatch({
    type: OrderActionTypes.FETCH_ORDER_BRANCH_ID,
    payload: API.fetchOrdersByBranchID(branchID, limit, pageNumber, status, start_date, end_date)
        .then(response => {
            if (response.data.error || response.data.code) {
                // errorHandler(response.data);
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            storage.set('fetchOrdersByBranchIdError', error)
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const searchOrderByOrderNumber = (orderNumber, directSearch, status) => dispatch => dispatch({
    type: OrderActionTypes.FETCH_ORDER_BRANCH_ID,
    payload: API.searchOrderByOrderNumber(orderNumber, directSearch, status)
        .then(response => {
            if (response.data.error || response.data.code) {
                // errorHandler(response.data);
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            storage.set('searchOrderByOrderNumber', error)
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const searchOrderByPhoneNumber = (phoneNumber) => dispatch => dispatch({
    type: OrderActionTypes.FETCH_ORDER_BY_PHONE_NUMBER,
    payload: API.searchOrderByPhoneNumber(phoneNumber)
        .then(response => {
            if (response.data.error || response.data.code) {
                // errorHandler(response.data);
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            storage.set('searchOrderByPhoneNumber', error)
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const loadMoreOrders = (branchID, limit, pageNumber, status, start_date, end_date) => dispatch => dispatch({
    type: OrderActionTypes.LOAD_MORE_ORDERS,
    payload: API.fetchOrdersByBranchID(branchID, limit, pageNumber, status, start_date, end_date)
        .then(response => {
            if (response.data.error || response.data.code) {
                // errorHandler(response.data);
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            storage.set('loadMoreOrders', error)
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const updateOrder = (orderID, order) => dispatch => dispatch({
    type: OrderActionTypes.UPDATE_ORDER,
    payload: API.updateOrder(orderID, order)
        .then(response => {
            if (response.data.error || response.data.code) {
                // errorHandler(response.data);
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            storage.set('updateOrder', error)
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const fetchOrderSummary = (start_date, end_date, branchID) => dispatch => dispatch({
    type: OrderActionTypes.FETCH_ORDER_SUMMARY,
    payload: API.fetchOrderSummary(start_date, end_date, branchID)
        .then(response => {
            if (response.data.error || response.data.code) {
                // errorHandler(response.data);
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            storage.set('fetchOrderSummary', error)
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const storeOrderToUpdate = (orderToUpdate) => dispatch => dispatch({
    type: OrderActionTypes.STORE_ORDER_TO_UPDATE,
    payload: {
        orderToUpdate: orderToUpdate
    }
});

export const storeSelectedBranch = (branch) => dispatch => dispatch({
    type: OrderActionTypes.SELECTED_BRANCH,
    payload: {
        branch: branch
    }
});

export const storeLatestOrders = (orders) => dispatch => dispatch({
    type: OrderActionTypes.STORE_LATEST_ORDERS,
    payload: {
        orders: orders
    }
});

export const updateTotalOrderCount = (count) => dispatch => dispatch({
    type: OrderActionTypes.UPDATE_TOTAL_COUNT,
    payload: {
        count: count
    }
});


export const toggleNotifycation = (status) => dispatch => dispatch({
    type: OrderActionTypes.TOGGLE_NOTIFY,
    payload: API.toggleNotifycation(status)
        .then(response => {
            if (response.data.error || response.data.code) {
                // errorHandler(response.data);
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            storage.set('toggleNotifycation', error)
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const toggleBranchNotification = (status, branchCode) => dispatch => dispatch({
    type: OrderActionTypes.TOGGLE_BRANCH_NOTIFICATION,
    payload: API.toggleBranchNotification(status, branchCode)
        .then(response => {
            if (response.data.error || response.data.code) {
                // errorHandler(response.data);
                return response.data;
            } else {
                return response.data;
            }
        })
        .catch(error => {
            storage.set('toggleBranchNotification', error)
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const fetchSettings = () => dispatch => dispatch({
    type: OrderActionTypes.FETCH_SETTINGS,
    payload: API.fetchSettings()
        .then(response => {
            if (response.data.success || response.data.success === "true") {
                return response.data;
            } else {
                // errorHandler(response.data);
                return response.data;
            }
        })
        .catch(error => {
            storage.set('fetchSettings', error)
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const fetchBranchSettings = (branchCode) => dispatch => dispatch({
    type: OrderActionTypes.FETCH_BRANCH_SETTINGS,
    payload: API.fetchBranchSettings(branchCode)
        .then(response => {
            if (response.data.success || response.data.success === "true") {
                return response.data;
            } else {
                // errorHandler(response.data);
                return response.data;
            }
        })
        .catch(error => {
            storage.set('fetchBranchSettings', error)
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const exportData = (start_date, end_date, BranchID) => dispatch => dispatch({
    type: OrderActionTypes.EXPORT_DATA,
    payload: API.exportData(start_date, end_date, BranchID)
        .then(response => {
            if (response.data.success || response.data.success === "true") {
                let dataToDownload = []
                if (response.data.orders && response.data.orders.length === 0) {
                    toastMsg('No data found for given date!', true)
                } else {
                    response.data.orders.map(order => {
                        let currentTime = new Date()
                        let PickUpDateTime = moment(order.PickUpDateTime).utc().format('YYYY-MM-DDTHH:mm:ss.000')
                        PickUpDateTime = new Date(PickUpDateTime)
                        let ArrivedTime = moment(order.ArrivedTime).format('YYYY-MM-DDTHH:mm:ss.000')
                        ArrivedTime = new Date(ArrivedTime)
                        let DeliveryTime = moment(order.DeliveryTime).format('YYYY-MM-DDTHH:mm:ss.000')
                        DeliveryTime = new Date(DeliveryTime)
                        let waitingTimeInMilliseconds;
                        if (order.DeliveryTime && order.ArrivedTime) waitingTimeInMilliseconds = moment(order.DeliveryTime).diff(moment(order.ArrivedTime), "milliseconds");
                        else if (order.PickUpDateTime && order.ArrivedTime) {
                            let PickUpDateTimeLocal = moment(order.PickUpDateTime).utc().format("YYYY-MM-DDTHH:mm:ss.000")
                            waitingTimeInMilliseconds = moment(PickUpDateTimeLocal).diff(moment(order.ArrivedTime), "milliseconds")
                        }
                        // else if (order.ArrivedTime) waitingTimeInMilliseconds = moment(currentTime).diff(moment(order.ArrivedTime), "minutes");
                        let WaitingTime = convertTimeFromMilliseconds(waitingTimeInMilliseconds)
                        // var seconds = Math.floor(deliveredTimeMilliseconds / 1000)
                        // var deliveredTimeMinutes = Math.floor(seconds / 60)
                        // seconds = seconds % 60
                        // var deliveredTimeHours = Math.floor(deliveredTimeMinutes / 60)
                        // deliveredTimeMinutes = deliveredTimeMinutes % 60
                        // var day = Math.floor(deliveredTimeHours / 24)
                        // deliveredTimeHours = deliveredTimeHours % 24
                        // let finalDeliveredTime = null
                        // if (deliveredTimeHours && deliveredTimeHours > 0) {
                        //     finalDeliveredTime = `Delivery Duration: ${deliveredTimeHours}.${deliveredTimeMinutes} Hrs`
                        // } else if (deliveredTimeMinutes > 0) {
                        //     finalDeliveredTime = `Delivery Duration: ${deliveredTimeMinutes} Minutes`
                        // } else if (seconds > 0) {
                        //     finalDeliveredTime = `Delivery Duration: ${seconds} Seconds`
                        // }
                        PickUpDateTime = moment(PickUpDateTime).format('MM/DD/YYYY HH:mm:ss')
                        ArrivedTime = moment(ArrivedTime).format('MM/DD/YYYY HH:mm:ss')
                        DeliveryTime = moment(DeliveryTime).format('MM/DD/YYYY HH:mm:ss')
                        let notifications = []
                        let confirm = false
                        let ready = false
                        let reminder = false
                        let finalOrderLogs = []
                        if (order.notification_logs && order.notification_logs.length > 0) {
                            order.notification_logs.map(notification => {
                                let notificationSentTime = moment(notification.NotificationSentTime).toDate()
                                notificationSentTime = moment(notificationSentTime).format('MM/DD/YYYY HH:mm')
                                if (notification.Category.toLowerCase() === NotificationCategories.Confirm.toLowerCase()) {
                                    confirm = true
                                    notifications[`${NotificationCategories.Confirm} Notification Sent Time`] = notificationSentTime
                                    console.log(notifications, "notificationsConfirmed")
                                } else if (notification.Category.toLowerCase() === NotificationCategories.Ready.toLowerCase()) {
                                    ready = true
                                    notifications[`${NotificationCategories.Ready} Notification Sent Time`] = notificationSentTime
                                    console.log(notifications, "notificationsReady")
                                } else {
                                    reminder = true
                                    notifications[`${NotificationCategories.Reminder} Notification Sent Time`] = notificationSentTime
                                    console.log(notifications, "notificationsReminder")
                                }

                            })
                            if (!confirm) {
                                notifications[`${NotificationCategories.Confirm} Notification Sent Time`] = 'N/A'
                            }
                            if (!ready) {
                                notifications[`${NotificationCategories.Ready} Notification Sent Time`] = 'N/A'
                            }
                            if (!reminder) {
                                notifications[`${NotificationCategories.Reminder} Notification Sent Time`] = 'N/A'
                            }
                        } else {
                            notifications[`${NotificationCategories.Confirm} Notification Sent Time`] = 'N/A'
                            notifications[`${NotificationCategories.Ready} Notification Sent Time`] = 'N/A'
                            notifications[`${NotificationCategories.Reminder} Notification Sent Time`] = 'N/A'
                        }
                        if (order.order_logs && order.order_logs.length > 0) {
                            order.order_logs.map((orderLogs, index) => {
                                let newStatus = null
                                let oldStatus = OrderStatus.CUSTOMER_ARRIVED.title
                                let createdAt = moment(orderLogs.created_at).format('MM/DD/YYYY hh:mm A')
                                if (OrderStatus.PICK_CONFIRMED.apiKey.toString() === orderLogs.OldStatus.toString()) {
                                    oldStatus = OrderStatus.PICK_CONFIRMED.title
                                } else if (OrderStatus.CUSTOMER_ARRIVED.apiKey.toString() === orderLogs.OldStatus.toString()) {
                                    oldStatus = OrderStatus.CUSTOMER_ARRIVED.title
                                } else {
                                    oldStatus = OrderStatus.INVOICED.title
                                }
                                if (OrderStatus.PICK_CONFIRMED.apiKey.toString() === orderLogs.NewStatus.toString()) {
                                    newStatus = OrderStatus.PICK_CONFIRMED.title
                                } else if (OrderStatus.CUSTOMER_ARRIVED.apiKey.toString() === orderLogs.NewStatus.toString()) {
                                    newStatus = OrderStatus.CUSTOMER_ARRIVED.title
                                } else {
                                    newStatus = OrderStatus.INVOICED.title
                                }
                                finalOrderLogs[`Status Logs ${index + 1}`] = `Status changed from ${oldStatus} to ${newStatus} At ${createdAt}`
                                if (orderLogs.Reason) {
                                    finalOrderLogs[`Reason Logs ${index + 1}`] = orderLogs.Reason
                                } else {
                                    finalOrderLogs[`Reason Logs ${index + 1}`] = 'N/A'
                                }
                            })
                        } 
                        let requiredData = {
                            OrderNumber: order.OrderNumber,
                            OrderStatus: order.OrderStatus,
                            BranchCode: order.BranchCode,
                            PickUpDateTime: PickUpDateTime.toLowerCase() !== "Invalid Date".toLowerCase() ? PickUpDateTime : 'N/A',
                            PromiseTime: order.udfPromiseTime,
                            ArrivedTime: ArrivedTime.toLowerCase() !== "Invalid Date".toLowerCase() ? ArrivedTime : 'N/A',
                            DeliveryTime: DeliveryTime.toLowerCase() !== "Invalid Date".toLowerCase() ? DeliveryTime : 'N/A',
                            WaitingTime,//: finalDeliveredTime ? finalDeliveredTime : 'N/A',
                            NotificationType: order.NotificationType ? order.NotificationType : 'N/A',
                            PhoneOrEmail: order.PhoneOrEmail ? order.PhoneOrEmail : 'N/A',
                            ...notifications,
                            ...finalOrderLogs
                        }
                        dataToDownload.push({ ...requiredData })
                    })
                    console.log(dataToDownload)
                }
                return { orders: dataToDownload };
            } else {
                // errorHandler(response.data);
                return response.data;
            }
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});


export const fetchOrderDetails = (orderNumber) => dispatch => dispatch({
    type: OrderActionTypes.FETCH_ORDER_DETAILS,
    payload: API.fetchOrderDetails(orderNumber)
        .then(response => {
            if (response.data.success || response.data.success === "true") {
                return response.data;
            } else {
                // errorHandler(response.data);
                return response.data;
            }
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
})

export const fetchOrderLogs = (start_date, end_date, pageNumber, limit, branchCode, isExportData) => dispatch => dispatch({
    type: OrderActionTypes.FETCH_ORDER_LOGS,
    payload: API.fetchOrderLogs(start_date, end_date, (isExportData ? 1 : pageNumber), (isExportData ? 10000 : limit), branchCode)
        .then(response => {
            if (response.data.error || response.data.code) {
                return response.data;
            } else {
                return { ...response.data, isExportData };
            }
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const orderSummeryDetails = (orderAllDetails) => dispatch => dispatch({
    type: OrderActionTypes.ORDER_ALL_DETAILS,
    payload: {
        orderAllDetails: orderAllDetails
    }
});

export const fetchNotificationLogs = (start_date, end_date, pageNumber, limit, branchCode, isExportData) => dispatch => dispatch({
    type: OrderActionTypes.FETCH_NOTIFICATION_LOGS,
    payload: API.fetchNotificationLogs(start_date, end_date, (isExportData ? 1 : pageNumber), (isExportData ? 10000 : limit), branchCode)
        .then(response => {
            if (response.data.error || response.data.code) {
                return response.data;
            } else {
                return { ...response.data, isExportData };
            }
        })
        .catch(error => {
            console.log(error);
            return error;
        })
});

export const fetchUserAuditLogs = (start_date, end_date, pageNumber, limit, id, branchCode, isExportData) => dispatch => dispatch({
    type: OrderActionTypes.FETCH_USER_AUDIT_LOGS,
    payload: API.fetchUserAuditLogs(start_date, end_date, (isExportData ? 1 : pageNumber), (isExportData ? 10000 : limit), id, branchCode)
        .then(response => {
            if (response.data.error || response.data.code) {
                return response.data;
            } else {
                return { ...response.data, isExportData };
            }
        })
        .catch(error => {
            console.log(error);
            return error;
        })
});