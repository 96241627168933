import React from 'react'
import Oux from '../../hoc/Oux/Oux'
import { UserRoles } from '../../utility/constants/constants'
import LoadingBtn from '../UI/LoadingButton/LoadingButton'


const Notification = (props) => {
    let message = "Turning Off will stop all the notifications sent to the customer for all the branches. In please confirm popup Confirm will trigger action Cancel will not do any changes."
    let reminderMessage = null
    let readyForPickUpMessage = null
    let orderConfirmationMessage = null
    if (!props.notificationSetting) {
        message = "Turning on will start all the notifications sent to the customer for all the branches. In please confirm popup Confirm will trigger action Cancel will not do any changes."
    }
    if (props.isBranchSetting && props.selectedBranch) {
        message = `Turning Off will stop all the notifications sent to the customer for ${props.selectedBranch.Name}. In please confirm popup Confirm will trigger action Cancel will not do any changes.`
        reminderMessage = <span>Turning Off will stop all the <span style={{ fontWeight: 700 }}>reminder notifications</span> sent to the customer for {props.selectedBranch.Name}. In please confirm popup Confirm will trigger action Cancel will not do any changes.</span>
        orderConfirmationMessage = <span>Turning Off will stop all the <span style={{ fontWeight: 700 }}>order confirmation notifications</span> sent to the customer for {props.selectedBranch.Name}. In please confirm popup Confirm will trigger action Cancel will not do any changes.</span>
        readyForPickUpMessage = <span>Turning Off will stop all the <span style={{ fontWeight: 700 }}>ready for pickup notifications</span> sent to the customer for {props.selectedBranch.Name}. In please confirm popup Confirm will trigger action Cancel will not do any changes.</span>
        if (!props.notificationSetting) {
            message = `Turning on will start all the notifications sent to the customer for ${props.selectedBranch.Name}. In please confirm popup Confirm will trigger action Cancel will not do any changes.`
        }
        if (props.ReminderNotificationStatus !== 1) {
            reminderMessage = <span>Turning on will start all the <span style={{ fontWeight: 700 }}>reminder notifications</span> sent to the customer for <bold>{props.selectedBranch.Name}</bold>. In please confirm popup Confirm will trigger action Cancel will not do any changes.</span>
        }
        if (props.ConfirmNotificationStatus !== 1) {
            orderConfirmationMessage = <span>Turning on will start all the <span style={{ fontWeight: 700 }}>order confirmation notifications</span> sent to the customer for {props.selectedBranch.Name}. In please confirm popup Confirm will trigger action Cancel will not do any changes.</span>
        }
        if (props.ReadyNotificationStatus !== 1) {
            readyForPickUpMessage = <span>Turning on will start all the <span style={{ fontWeight: 700 }}>ready for pickup notifications</span> sent to the customer for {props.selectedBranch.Name}. In please confirm popup Confirm will trigger action Cancel will not do any changes.</span>
        }
    }
    let flag = false
    if (!props.isBranchSetting) {
        if (props.settings && props.settings.length > 0) {
            props.settings.map(setting => {
                if (setting.Name.toLowerCase() === 'global_notification'.toLowerCase() && setting.Status === "1") {
                    flag = true
                }
            })
        }
    }

    console.log(props.notificationSetting, "notificationSetting")
    return (
        <div class={props.isBranchSetting ? "tab-pane fade notification_settings" : "tab-pane fade show active notification_settings"} id="notification_settings" role="tabpanel" aria-labelledby="notification_settings_tab">
            <div class="wrap-login100">
                <form class="login100-form ph_forms" id="login_form">
                    <article class="limiter_heading_wrp ml-auto mr-auto wow fadeInUp">
                        <h3>Notification setting</h3>
                    </article>
                    {
                        props.isBranchSetting
                            ? <div className="branch_settings">
                                {/* <div class="inner_form">
                                    <div class="fields">
                                        <div class="ph_flex_wrp_spw ph_switch_wrp align-items-start switch_wrp">
                                            <div class="ph_switch_text">
                                                <p class="mb-2">
                                                    {message}
                                                </p>
                                            </div>
                                            <div class="ph_switch_con ph_crud_wrp">
                                                <label class="ph_switch">
                                                    {
                                                        props.isSettingsLoading
                                                            ? <LoadingBtn btnClassName="transparent_bg_loading_btn delete_icon_loader" />
                                                            : props.isBranchSetting
                                                                ? <Oux>
                                                                    <input type="checkbox" checked={props.branchSettings && props.branchSettings.NotificationStatus === 1} onChange={(event) => props.onToggleNotificationSetting(event)} />
                                                                    <span class="ph_slider round"></span>
                                                                </Oux>
                                                                : <Oux>
                                                                    <input type="checkbox" checked={flag} onChange={(event) => props.onToggleNotificationSetting(event)} />
                                                                    <span class="ph_slider round"></span>
                                                                </Oux>

                                                    }
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div class="inner_form">
                                    <div class="fields">
                                        <div class="ph_flex_wrp_spw ph_switch_wrp align-items-start switch_wrp">
                                            <div class="ph_switch_text">
                                                <p class="mb-2">
                                                    {reminderMessage}
                                                </p>
                                            </div>
                                            <div class="ph_switch_con ph_crud_wrp">
                                                <label class="ph_switch">
                                                    {
                                                        props.isSettingsLoading
                                                            ? <LoadingBtn btnClassName="transparent_bg_loading_btn delete_icon_loader" />
                                                            : props.isBranchSetting
                                                                ? <Oux>
                                                                    <input type="checkbox" checked={props.branchSettings && props.branchSettings.ReminderNotificationStatus === 1} onChange={(event) => props.onToggleReminderNotificationSetting(event)} />
                                                                    <span class="ph_slider round"></span>
                                                                </Oux>
                                                                : <Oux>
                                                                    <input type="checkbox" checked={flag} onChange={(event) => props.onToggleReminderNotificationSetting(event)} />
                                                                    <span class="ph_slider round"></span>
                                                                </Oux>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="ph_flex_wrp_spw">
                        <button class="theme_primary theme_btn text-uppercase btn-block" type="button">Update </button>
                    </div> */}
                                </div>
                                <div class="inner_form">
                                    <div class="fields">
                                        <div class="ph_flex_wrp_spw ph_switch_wrp align-items-start switch_wrp">
                                            <div class="ph_switch_text">
                                                <p class="mb-2">
                                                    {readyForPickUpMessage}
                                                </p>
                                            </div>
                                            <div class="ph_switch_con ph_crud_wrp">
                                                <label class="ph_switch">
                                                    {
                                                        props.isSettingsLoading
                                                            ? <LoadingBtn btnClassName="transparent_bg_loading_btn delete_icon_loader" />
                                                            : props.isBranchSetting
                                                                ? <Oux>
                                                                    <input type="checkbox" checked={props.branchSettings && props.branchSettings.ReadyNotificationStatus === 1} onChange={(event) => props.onToggleReadyNotificationSetting(event)} />
                                                                    <span class="ph_slider round"></span>
                                                                </Oux>
                                                                : <Oux>
                                                                    <input type="checkbox" checked={flag} onChange={(event) => props.onToggleReadyNotificationSetting(event)} />
                                                                    <span class="ph_slider round"></span>
                                                                </Oux>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="ph_flex_wrp_spw">
                        <button class="theme_primary theme_btn text-uppercase btn-block" type="button">Update </button>
                    </div> */}
                                </div>
                                <div class="inner_form">
                                    <div class="fields">
                                        <div class="ph_flex_wrp_spw ph_switch_wrp align-items-start switch_wrp">
                                            <div class="ph_switch_text">
                                                <p class="mb-2">
                                                    {orderConfirmationMessage}
                                                </p>
                                            </div>
                                            <div class="ph_switch_con ph_crud_wrp">
                                                <label class="ph_switch">
                                                    {
                                                        props.isSettingsLoading
                                                            ? <LoadingBtn btnClassName="transparent_bg_loading_btn delete_icon_loader" />
                                                            : props.isBranchSetting
                                                                ? <Oux>
                                                                    <input type="checkbox" checked={props.branchSettings && props.branchSettings.ConfirmNotificationStatus === 1} onChange={(event) => props.onToggleConfirmNotificationSetting(event)} />
                                                                    <span class="ph_slider round"></span>
                                                                </Oux>
                                                                : <Oux>
                                                                    <input type="checkbox" checked={flag} onChange={(event) => props.onToggleConfirmNotificationSetting(event)} />
                                                                    <span class="ph_slider round"></span>
                                                                </Oux>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="ph_flex_wrp_spw">
                        <button class="theme_primary theme_btn text-uppercase btn-block" type="button">Update </button>
                    </div> */}
                                </div>
                            </div>

                            : <div class="inner_form">
                                <div class="fields">
                                    <div class="ph_flex_wrp_spw ph_switch_wrp align-items-start switch_wrp">
                                        <div class="ph_switch_text">
                                            <p class="mb-2">
                                                {message}
                                            </p>
                                        </div>
                                        <div class="ph_switch_con ph_crud_wrp">
                                            <label class="ph_switch">
                                                {
                                                    props.isSettingsLoading
                                                        ? <LoadingBtn btnClassName="transparent_bg_loading_btn delete_icon_loader" />
                                                        : props.isBranchSetting
                                                            ? <Oux>
                                                                <input type="checkbox" checked={props.branchSettings && props.branchSettings.NotificationStatus === 1} onChange={(event) => props.onToggleNotificationSetting(event)} />
                                                                <span class="ph_slider round"></span>
                                                            </Oux>
                                                            : <Oux>
                                                                <input type="checkbox" checked={flag} onChange={(event) => props.onToggleNotificationSetting(event)} />
                                                                <span class="ph_slider round"></span>
                                                            </Oux>

                                                }
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="ph_flex_wrp_spw">
                            <button class="theme_primary theme_btn text-uppercase btn-block" type="button">Update </button>
                        </div> */}
                            </div>
                    }
                </form>
            </div>
        </div>

    )
}

export default Notification