import store from '../../redux/store/store';
import { isTokenValid } from '../utility';
import * as actions from '../../redux/actions/usersActions/authAction';
import { defaultProps } from 'recompose';

const authInterceptor = (config) => {
    const state = store.getState();
    // if (state.authReducer.csrfToken) {
    //     config.headers.common['csrf-token'] = state.authReducer.csrfToken;
    // }
    if (state.authReducer.token) {
        if (isTokenValid(state.authReducer.token)) {
            config.headers.common['Authorization'] = 'Bearer ' + state.authReducer.token;
        } else {
            store.dispatch(actions.logout())
        }
    }
    return config;
}

export default authInterceptor;