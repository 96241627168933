import { ConfigActionTypes } from '../../actions/usersActions/actionType';
import actions from 'redux-form/lib/actions';
const cloneDeep = require('clone-deep');

const initialState = {
    isConfigLoading: false,
    branches: [],
    isErrorReporting: false
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case ConfigActionTypes.PULL_CONFIG_PENDING:
            return updateObject(state,
                {
                    isConfigLoading: true
                });
        case ConfigActionTypes.PULL_CONFIG_FULFILLED:
            if (action.payload && (action.payload.success || action.payload.success === "true")) {
                return updateObject(state,
                    {
                        isConfigLoading: false,
                        branches: action.payload.branches
                    });
            }
            return updateObject(state,
                {
                    isConfigLoading: false
                });;

        case ConfigActionTypes.REPORT_ERROR_PENDING:
            return updateObject(state,
                {
                    isErrorReporting: true
                });
        case ConfigActionTypes.REPORT_ERROR_FULFILLED:
            return updateObject(state,
                {
                    isErrorReporting: true
                });

        default: return state;
    }
}