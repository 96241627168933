import React, { useEffect, useState } from 'react'
import ExportDataForm from '../ExportDataForm/ExportDataForm';
import Pagination from "react-js-pagination";
import moment from 'moment'
import 'moment-timezone'
import { LogsType } from '../../utility/constants/constants';


const AuditLogsPage = props => {
    const [loaded, setLoaded] = useState(false);
    const [exportedData, setExportedData] = useState([]);
    const handleResForExport = (res) => {
        let exportData = res.value.audit_logs.map(log => {
            let Time = moment(log.time).format('MM/DD/YYYY hh:mm A');
            return {
                'User Name': log.updated_user && log.updated_user.first_name ? `${log.updated_user.first_name} ${log.updated_user.last_name}` : '',
                'Branch Name': log.updated_user && log.updated_user.BranchName ? log.updated_user.BranchName : "",
                'Action': log.ChildAction,
                Time,
                'Updated By': log.updated_by && log.updated_by.first_name ? `${log.updated_by.first_name} ${log.updated_by.last_name}` : '',
                'Role': log.updated_user && log.updated_user.role ? log.updated_user.role : '',
            }
        });
        setExportedData(exportData);

    };


    useEffect(() => {
        if (exportedData.length > 0) {
            setLoaded(true)
        }
    }, [exportedData])
    useEffect(() => {
        if (loaded) {
            var downloadLink = document.createElement("a");
            downloadLink.href = document.getElementById("export_logs_data_download").href//$('#export_data_download').attr('href')
            downloadLink.download = `auditLogs.csv`;
            downloadLink.click();
            setLoaded(false);
            setExportedData([]);
        }
    }, [loaded])
    return (
        <section class="theme_nav_mt ims_sec">
            <div class="container-fluid ims_mx30">
                <ExportDataForm isLogs={true} {...props} handleResForExport={handleResForExport} exportedData={exportedData} logsType={LogsType.AUDIT_LOGS} />
                <div class="row align-items-center custom_top">
                    <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
                        <article class="ims_art ims_flex ims_flex_v">
                            <h3 class="font-weight-bold">Audit Logs</h3>
                        </article>
                    </div>
                    {/* <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2 text-right">
                        <select class="input_modify cstSelect cstSelectW160">
                            <option>All Status</option>
                            <option>Customer Arrived</option>
                            <option>Ready</option>
                            <option>Complete</option>
                        </select>
                    </div> */}
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive ">
                            <table class="table table-borderless table-hover ims_table text-nowrap">
                                <thead class="ot_heading">
                                    <tr>
                                        <th scope="col">Username</th>
                                        <th scope="col">Branch Name</th>
                                        <th scope="col">Action</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Updated By</th>                                        
                                        <th scope="col">Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {/* <td class="th_space">
                                            <input type="text" class="input_modify" id="Order" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="CustomerName" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Contact" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Date" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Parking" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Delivery" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Waitingtime" />
                                        </td>
                                        <td class="th_space">
                                            <input type="password" class="input_modify" id="lineitems" />
                                        </td>
                                        <td class="th_space">
                                            <input type="password" class="input_modify" id="PromiseTime" />
                                        </td>
                                        <td class="th_space">
                                            <input type="text" class="input_modify" id="Waitingtime" />
                                        </td>
                                        <td class="th_space">
                                            <input type="password" class="input_modify" id="lineitems" />
                                        </td>
                                        <td class="th_space">
                                            <input type="password" class="input_modify" id="PromiseTime" />
                                        </td>
                                        <td></td> */}
                                    </tr>
                                    {
                                        props.audit_logs && props.audit_logs.length > 0
                                            ? props.audit_logs.map(audit_logs => {
                                                let time = moment(audit_logs.Time).format('MM/DD/YYYY hh:mm A')
                                                let created_at = moment(audit_logs.created_at).format('MM/DD/YYYY hh:mm A')
                                                return (
                                                    <tr>
                                                        <td>{audit_logs.updated_user && audit_logs.updated_user.first_name ? `${audit_logs.updated_user.first_name} ${audit_logs.updated_user.last_name}` : ''}</td>
                                                        <td>{audit_logs.updated_user && audit_logs.updated_user.BranchName ? audit_logs.updated_user.BranchName : ""}</td>
                                                        <td>{audit_logs.ChildAction}</td>
                                                        <td>{time}</td>
                                                        <td>{audit_logs.updated_by ? `${audit_logs.updated_by.first_name} ${audit_logs.updated_by.last_name}` : ''}</td>
                                                        <td className="text-capitalize">{audit_logs.updated_user && audit_logs.updated_user.role ? audit_logs.updated_user.role : ''}</td>
                                                    </tr>
                                                )
                                            })
                                            : <h2 className="text-center"><b>No results found</b></h2>
                                    }
                                </tbody>
                            </table>
                            <div className="float-right">
                                {props.count ?
                                    <Pagination
                                        activePage={props.pageNumber}
                                        itemsCountPerPage={props.ViewOrderLimitPerPage}
                                        totalItemsCount={props.count}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => props.setPageNumber({...props.filterParams, fromFilter: false, pageNumber: e})}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    /> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AuditLogsPage