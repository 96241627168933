import React from 'react'
import Oux from '../../hoc/Oux/Oux'
import { formInputErrorStyle, formInputTextErrorStyle, OrderStatus, routes, UserRoles, ViewOrderLimitPerPage } from '../../utility/constants/constants'
import { defaultImagePath } from '../../utility/utility'
import SpinnerLoader from '../UI/SpinnerLoader/SpinnerLoader'
import Timer from 'react-compound-timer'
import Skeleton from 'react-loading-skeleton';
import moment from 'moment'
import 'moment-timezone'
import Moment from 'react-moment'
import LoadingBtn from '../UI/LoadingButton/LoadingButton'

const PhoneNumberOrders = props => {
    let totalOrders = props.totalCount
    const orderDetails = (order, event) => {
        // debugger;
        if (event.target.id !== "action_btn") {
            props.history.push(`${routes.ORDER_DETAIL}/${order.OrderNumber}`);
        }
        props.orderSummeryDetails(order);
    }
    return (
        <section class={props.isSocketDisconnected ? "home_hero disconnected_socket_banner_visible" : "home_hero"} style={{ backgroundImage: `url(${defaultImagePath('background/hero.png')})` }}>
            <div class="container-fluid home_hero_inner">
                <div class="row align-items-center">
                    <div class="col-sm-12 col-md-12 col-lg-10">
                        <div class="search_wrp ims_flex ims_flex_v">
                            <article class="ims_art heading_with_badge">
                                <h1 class="font-weight-bold position-relative">Orders</h1>
                                {/* <span class="badge badge_tertiary theme_badge">{totalOrders}</span> */}
                            </article>
                            {
                                props.isDirectSearch
                                    ? null
                                    : <form className="search_form_container">
                                        <div className="form-group search_form_wrapper" style={{ marginBottom: '0' }}>
                                            <div className="search_wrapper">
                                                <div class="search_icon close_icon" onClick={props.onClickResetBtn}>
                                                    <img src={defaultImagePath('custom_images/icn_close_gray.svg')} class="si_inner" alt="search" />
                                                </div>
                                                {/* <div className="search_icon">
                                        <img src={defaultImagePath('custom_images/icn_search_gray.svg')} class="si_inner" alt="search" />
                                    </div> */}
                                                <input type="text" onChange={props.onChangeSearchText} style={props.errorMsg ? formInputErrorStyle : null} class="form-control search_inline search_field" value={props.searchText} placeholder="Enter the order number" />
                                                {props.errorMsg ? <span style={{ ...formInputTextErrorStyle, marginLeft: '15px' }}>{props.errorMsg}</span> : null}
                                            </div>
                                            <button type="submit" disabled={props.errorMsg || !props.searchText ? true : false} onClick={props.onClickSearchBtn} class={props.errorMsg || !props.searchText ? "theme_btn theme_tertiary search_btn theme_btn_rds30 text-white ml-2 desktop_search_btn disabled_btn" : "theme_btn theme_tertiary search_btn theme_btn_rds30 text-white ml-2 desktop_search_btn"}>Search</button>
                                        </div>
                                    </form>
                            }
                        </div>
                    </div>
                    {/* <div class="col-sm-12 col-md-12 col-lg-2">
                        <div class="ims_filter_cont search_mobile_btn_wrapper">
                            {
                                props.isDirectSearch
                                    ? null
                                    : <input type="submit" disabled={props.errorMsg || !props.searchText ? true : false} onClick={props.onClickSearchBtn} className={props.errorMsg || !props.searchText ? "theme_btn theme_tertiary search_btn theme_btn_rds30 text-white ml-2 mobile_search_btn disabled_btn" : "theme_btn theme_tertiary search_btn theme_btn_rds30 text-white ml-2 mobile_search_btn"} value="Search" />
                            }
                            {props.isDirectSearch
                                ? null
                                : <ul class="profile_dropdown avatar_dropdown">
                                    <li class="dropdown">
                                        <a class="dropdown-toggle user_menu_dropdown nav-link" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                            <img src={defaultImagePath('icons/icn_filter.svg')} alt="Icon Filter" />
                                        </a>
                                        <ul class="dropdown-menu dropdown-menu-right">
                                            <li role="presentation" class={props.orderStatus.key === OrderStatus.CUSTOMER_ARRIVED.key ? "list_item active" : "list_item"}>
                                                <a role="menuitem" tabindex="-1" className="non_href_a_tag" onClick={() => props.toggleOrderStatus(OrderStatus.CUSTOMER_ARRIVED)}>Customer Arrived</a>
                                            </li>
                                            <li role="presentation" class={props.orderStatus.key === OrderStatus.PICK_CONFIRMED.key ? "list_item active" : "list_item"}>
                                                <a role="menuitem" tabindex="-1" className="non_href_a_tag" onClick={() => props.toggleOrderStatus(OrderStatus.PICK_CONFIRMED)}>Pick confirmed</a>
                                            </li>
                                            <li role="presentation" class={props.orderStatus.key === OrderStatus.INVOICED.key ? "list_item active" : "list_item"}>
                                                <a role="menuitem" tabindex="-1" className="non_href_a_tag" onClick={() => props.toggleOrderStatus(OrderStatus.INVOICED)}>Invoiced</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            }
                        </div>
                    </div> */}
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive">
                            {
                                props.isLoading
                                    ? <div style={{ lineHeight: 2 }} className="mb-2">
                                        <Skeleton count={5} height={100} />
                                    </div>
                                    : props.orders && props.orders.length > 0 && props.orders.filter(item => item.LocalOrderStatus === OrderStatus.CUSTOMER_ARRIVED.apiKey && !(item.OrderStatus === OrderStatus.INVOICED.apiKey || item.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)).length > 0
                                        ? <table class="table table-borderless text-nowrap ims_table ims_grid_table">
                                            <tbody>
                                                {
                                                    props.orders.filter(item => item.LocalOrderStatus === OrderStatus.CUSTOMER_ARRIVED.apiKey && !(item.OrderStatus === OrderStatus.INVOICED.apiKey || item.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)).map((order, index) => {
                                                        let arrivedTime = moment(order.ArrivedTime).tz('UTC').format('YYYY-MM-DDTHH:mm:ss.000')
                                                        arrivedTime = new Date(arrivedTime)
                                                        let currentTime = new Date()
                                                        console.log(arrivedTime, "arrivedTime", currentTime, "currentTime")
                                                        console.log(order.OrderNumber)
                                                        // console.log(arrivedTime, "arrivedTime")
                                                        // console.log(currentTime, "currentTime")
                                                        let diffTime = moment(currentTime).diff(moment(arrivedTime), "minutes")
                                                        // console.log(diffTime, "diffTime")
                                                        let arrivedBorderColorClass = "ims_Lborder Lborder_succsess"
                                                        if (diffTime > 10 && diffTime <= 20) {
                                                            arrivedBorderColorClass = "ims_Lborder Lborder_warning"
                                                        } else if (diffTime > 20 && diffTime <= 40) {
                                                            arrivedBorderColorClass = "ims_Lborder Lborder_red"
                                                        } else if (diffTime > 40) {
                                                            arrivedBorderColorClass = "ims_Lborder Lborder_blue"
                                                        }
                                                        let disableTableRow = false
                                                        if (props.orderToUpdate) {
                                                            disableTableRow = props.orderToUpdate.OrderNumber === order.OrderNumber && props.isOrderUpdating
                                                        }

                                                        return (
                                                            <Oux key={index}>
                                                                <tr style={disableTableRow ? { opacity: 0.5 } : null} className="non_href_a_tag" onClick={(event) => orderDetails(order, event)}>
                                                                    <td class={arrivedBorderColorClass}>
                                                                        <article class="ims_art">
                                                                            <h6 class="font-weight-bold text-uppercase text-primary mb-0">Arrived <Moment format="hh:mm A">{arrivedTime}</Moment> <img class="ml-1" src={defaultImagePath('custom_images/icn_clock.svg')} /></h6>
                                                                            <h2 class="font-weight-bold mx_eql">
                                                                                {order.CompanyName}
                                                                            </h2>
                                                                            <h5 class="ft_Weight_600 mb-0">
                                                                                {order.ContactName}
                                                                                {
                                                                                    order.ContactNumber
                                                                                        ? <button type="button" class="tooltip_btn" data-toggle="tooltip" data-placement="top" title="" data-original-title={order.ContactNumber}>
                                                                                            <img src={defaultImagePath('icons/icn_not.png')} alt="Info Icon" />
                                                                                        </button>
                                                                                        : null
                                                                                }
                                                                            </h5>
                                                                        </article>
                                                                    </td>
                                                                    <td>
                                                                        <div class="ims_grid_id">
                                                                            <h4 class="ft_Weight_600 text-graydark1">Order Number</h4>
                                                                            <h4 class="ft_Weight_600 text-graydark1 mb-0">#{order.OrderNumber}</h4>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="ims_grid_id">
                                                                            <h4 class="ft_Weight_600 text-graydark1">Order Status</h4>
                                                                            <h4 class="ft_Weight_600 text-graydark1 mb-0">Customer Arrived</h4>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="ims_grid_id">
                                                                            <div class="ims_info my-0">
                                                                                <h4 class="ft_Weight_600"><span class="ims_info_title">Parking Slot</span></h4>
                                                                                <h4 class="ft_Weight_600 mb-0"><span class="ims_info_val">{order.ParkingSlot ? `#${order.ParkingSlot}` : '-'}</span></h4>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <div class="ims_grid_id">
                                                                            <div class="ims_info ims_time_info my-0">
                                                                                <h4 class="ft_Weight_600"><span class="ims_info_title">Waiting time</span></h4>
                                                                                <h4 class="ft_Weight_600 text-graylight mb-0">
                                                                                    <span class="ims_info_val text-tertiary mx-auto">
                                                                                        <Timer initialTime={diffTime}
                                                                                            direction="forward">
                                                                                            {() => (
                                                                                                <span>{diffTime} Minutes</span>
                                                                                            )}
                                                                                        </Timer>
                                                                                    </span>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        props.user && props.user.role.toLowerCase() === UserRoles.manager.toLowerCase()
                                                                            ? <td id="action_btn">
                                                                                <div class="dropdown ims_drop_inner" id="action_btn">
                                                                                    {
                                                                                        disableTableRow
                                                                                            ? <SpinnerLoader className="ht_30" />
                                                                                            : <a class="dropdown-toggle" id="action_btn" role="button" id="actionMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                <span class="icn_w20" id="action_btn">
                                                                                                    <img id="action_btn" src={defaultImagePath('icons/icn_more.png')} alt="More" />
                                                                                                </span>
                                                                                            </a>
                                                                                    }
                                                                                    <div id="action_btn" class="dropdown-menu" aria-labelledby="actionMenu">
                                                                                        <a id="action_btn" class="dropdown-item non_href_a_tag" onClick={() => {
                                                                                            window.$('#reason_text_modal').modal('toggle');
                                                                                            props.storeOrderToUpdate(order)
                                                                                            props.toggleReasonToMoveOrdersModalStatus(true, OrderStatus.PICK_CONFIRMED.key)
                                                                                        }}>Pick Confirmed</a>
                                                                                        <a id="action_btn" class="dropdown-item non_href_a_tag" onClick={() => {
                                                                                            window.$('#reason_text_modal').modal('toggle');
                                                                                            props.storeOrderToUpdate(order)
                                                                                            props.toggleReasonToMoveOrdersModalStatus(true, OrderStatus.INVOICED.key)
                                                                                        }}>Order Completed </a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            : null
                                                                    }
                                                                </tr>
                                                                <tr class="ims_tr_gap" style={{ height: '15px' }}></tr>
                                                            </Oux>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        : null
                            }
                            {
                                props.isLoading
                                    ? null
                                    : props.orders && props.orders.length > 0 && props.orders.filter(item => (item.OrderStatus === OrderStatus.INVOICED.apiKey || item.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)).length > 0
                                        ? <table class="table table-borderless text-nowrap ims_table ims_grid_table">
                                            <tbody>
                                                {
                                                    props.orders.filter(item => (item.OrderStatus === OrderStatus.INVOICED.apiKey || item.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)).map((order, index) => {
                                                        let arrivedTime = moment(order.ArrivedTime).tz('UTC').format('YYYY-MM-DDTHH:mm:ss.000')
                                                        arrivedTime = new Date(arrivedTime)
                                                        let deliveryTime = moment(order.DeliveryTime).tz('UTC').format('YYYY-MM-DDTHH:mm:ss.000')
                                                        deliveryTime = new Date(deliveryTime)
                                                        // console.log(arrivedTime, "arrived", deliveryTime, "delivery")
                                                        console.log(`${index} => OrderNumber = ${order.OrderNumber} => OrderStatus = ${order.OrderStatus}`)
                                                        let deliveredTimeMilliseconds = moment(deliveryTime).diff(moment(arrivedTime), "millisecond")
                                                        var seconds = Math.floor(deliveredTimeMilliseconds / 1000)
                                                        var deliveredTimeMinutes = Math.floor(seconds / 60)
                                                        seconds = seconds % 60
                                                        var deliveredTimeHours = Math.floor(deliveredTimeMinutes / 60)
                                                        deliveredTimeMinutes = deliveredTimeMinutes % 60
                                                        var day = Math.floor(deliveredTimeHours / 24)
                                                        // deliveredTimeHours = deliveredTimeHours % 24
                                                        let finalDeliveredTime = null
                                                        if (deliveredTimeHours && deliveredTimeHours > 0) {
                                                            finalDeliveredTime = `Delivery Duration: ${deliveredTimeHours}.${deliveredTimeMinutes} Hrs`
                                                        } else if (deliveredTimeMinutes > 0) {
                                                            finalDeliveredTime = `Delivery Duration: ${deliveredTimeMinutes} Minutes`
                                                        } else if (seconds > 0) {
                                                            finalDeliveredTime = `Delivery Duration: ${seconds} Seconds`
                                                        }
                                                        return (
                                                            <Oux key={index}>
                                                                <tr className="non_href_a_tag" onClick={(event) => orderDetails(order, event)}>
                                                                    <td>
                                                                        <article class="ims_art">
                                                                            <h2 class="font-weight-bold mx_eql">
                                                                                {order.CompanyName}
                                                                            </h2>
                                                                            <h5 class="font-weight-bold mb-0">
                                                                                {order.ContactName}
                                                                                {
                                                                                    order.ContactNumber
                                                                                        ? <button type="button" class="tooltip_btn" data-toggle="tooltip" data-placement="top" title="" data-original-title={order.ContactNumber}>
                                                                                            <img src={defaultImagePath('icons/icn_not.png')} alt="Info Icon" />
                                                                                        </button>
                                                                                        : null
                                                                                }
                                                                            </h5>
                                                                        </article>
                                                                    </td>
                                                                    <td>
                                                                        <div class="ims_grid_id">
                                                                            <h4 class="ft_Weight_600 text-graydark1">Order Number</h4>
                                                                            <h4 class="ft_Weight_600 text-graydark1 mb-0">#{order.OrderNumber}</h4>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="ims_grid_id">
                                                                            <h4 class="ft_Weight_600 text-graydark1">Order Status</h4>
                                                                            <h4 class="ft_Weight_600 text-graydark1 mb-0">Invoiced</h4>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="ims_grid_id">
                                                                            <div class="ims_info my-0">
                                                                                <h4 class="ft_Weight_600"><span class="ims_info_title">Parking Slot</span></h4>
                                                                                <h4 class="ft_Weight_600 mb-0"><span class="ims_info_val">{order.ParkingSlot ? `#${order.ParkingSlot}` : '-'}</span></h4>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td class="text-right">
                                                                        <div class="ims_grid_btn">
                                                                            <p class="ft_Weight_600 cstmr_ar_para">{order.ArrivedTime ? <span>Customer Arrived - <Moment format='MMM DD, YYYY'>{arrivedTime}</Moment></span> : 'Customer Arrived - N/A'}</p>
                                                                            <a href="#" class="theme_btn tick_success theme_btn_rds30"><img src={defaultImagePath('icons/icn_tick.svg')} alt="Tick Icon" />{finalDeliveredTime ? finalDeliveredTime : 'Delivery Duration - N/A'}</a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="ims_tr_gap" style={{ height: '15px' }}></tr>
                                                            </Oux>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                        : null
                            }
                            {
                                props.isLoading
                                    ? null
                                    : props.orders && props.orders.length > 0 && props.orders.filter(item => (item.OrderStatus === OrderStatus.PICK_CONFIRMED.apiKey && item.LocalOrderStatus !== OrderStatus.CUSTOMER_ARRIVED.apiKey)).length > 0
                                        ? <table class="table table-borderless text-nowrap ims_table ims_grid_table">
                                            <tbody>
                                                {
                                                    props.orders.filter(item => (item.OrderStatus === OrderStatus.PICK_CONFIRMED.apiKey && item.LocalOrderStatus !== OrderStatus.CUSTOMER_ARRIVED.apiKey)).map((order, index) => {
                                                        return (
                                                            <Oux key={index}>
                                                                <tr className="non_href_a_tag" onClick={(event) => orderDetails(order, event)}>
                                                                    <td>
                                                                        <article class="ims_art">
                                                                            <h2 class="font-weight-bold mx_eql">
                                                                                {order.CompanyName}
                                                                            </h2>
                                                                            <h5 style={{ textTransform: 'capitalize' }} class="font-weight-bold mb-0">
                                                                                {order.ContactName}
                                                                                {
                                                                                    order.ContactNumber
                                                                                        ? <button type="button" class="tooltip_btn" data-toggle="tooltip" data-placement="top" title="" data-original-title={order.ContactNumber}>
                                                                                            <img src={defaultImagePath('icons/icn_not.png')} alt="Info Icon" />
                                                                                        </button>
                                                                                        : null
                                                                                }
                                                                            </h5>
                                                                        </article>
                                                                    </td>
                                                                    <td>
                                                                        <div class="ims_grid_id">
                                                                            <h4 class="ft_Weight_600 text-graylight">Order Number</h4>
                                                                            <h4 class="ft_Weight_600 text-graylight">#{order.OrderNumber}</h4>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="ims_grid_id">
                                                                            <h4 class="ft_Weight_600 text-graydark1">Order Status</h4>
                                                                            <h4 class="ft_Weight_600 text-graydark1 mb-0">Pick Confirmed</h4>
                                                                        </div>
                                                                    </td>
                                                                    <td id="action_btn" class="text-right">
                                                                        <div id="action_btn" class="ims_grid_btn">
                                                                            {
                                                                                props.isDirectSearch
                                                                                    ? null
                                                                                    : <a id="action_btn" onClick={() => { window.$('#arrived_delivery').modal('toggle'); props.storeOrderToUpdate(order); props.toggleDeliveryModalStatus(true) }} class="theme_btn theme_tertiary theme_btn_rds30 text-white">Arrived for Delivery</a>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr class="ims_tr_gap" style={{ height: '15px' }}></tr>
                                                            </Oux>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        : null
                            }
                        </div>
                    </div>
                </div>
                {
                    !props.isLoading && (props.orders && props.orders.length >= (ViewOrderLimitPerPage * props.currentPage))
                        ? <div style={{ float: 'right' }} className="mb-2">
                            <a onClick={props.onClickLoadMore} class="theme_btn theme_tertiary theme_btn_rds30 text-white">Load More</a>
                        </div>
                        : props.isLoadMoreLoading
                            ? <div style={{ float: 'right' }} className="mb-2">
                                <LoadingBtn btnClassName="theme_btn theme_tertiary theme_btn_rds30 text-white" btnTitle="Please wait..." />
                            </div>
                            : null
                }
            </div>
        </section>
    )
}

export default PhoneNumberOrders