import React, { useEffect } from 'react';
import './App.css';
import AppRouter from './router/router';
import { removeConsoleLog, toastMsg } from './utility/utility';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import RouteChangeListener from './utility/RouteChangeListener';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import * as miscActions from './redux/actions/usersActions/miscAction';
import * as actions from './redux/actions/index'
import Oux from './hoc/Oux/Oux';
import socketIOClient from "socket.io-client";
import { SOCKET_URL } from './config';
import { UserRoles } from './utility/constants/constants';
import { useRef } from 'react';

const App = (props) => {

    const didMountRef = useRef(false)
    const socket = socketIOClient(SOCKET_URL(), { 'transports': ['websocket', 'polling'] });

    useEffect(() => {
        if (didMountRef.current) {
            //didUpdate
        } else {
            didMountRef.current = true
            if (process.env.REACT_APP_ENV === 'production') {
                removeConsoleLog();
            }
            // props.fetchCSRFToken()
            props.fetchConfig()
            props.addHistory(props.history)
            // socket.on('user_connection', (msg) => {
            //   console.log(msg, "socketMsg")
            // })
        }

    }, [])

    return (
        <Oux>
            <RouteChangeListener />
            <ReactNotification />
            <AppRouter {...props} />
        </Oux>
    );
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user,
    token: state.authReducer.token
});

const mapStateToDispatch = (dispatch) => ({
    addHistory: (history) => dispatch(miscActions.addHistory(history)),
    fetchConfig: () => dispatch(actions.fetchConfig()),
    fetchCSRFToken: () => dispatch(actions.fetchCSRFToken())
});

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(App));