import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ExportDataForm from '../../components/ExportDataForm/ExportDataForm'
import * as actions from '../../redux/actions/index'

const ExportData = props => {

    useEffect(() => {

    }, [props.exportData])

    return (
        <ExportDataForm {...props} />
    )
}

const mapStateToProps = (state) => {
    return {
        isExporting: state.orderReducer.isExporting,
        selectedBranch: state.orderReducer.selectedBranch,
        exportData: state.orderReducer.exportData,
        user: state.userReducer.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchExportData: (start_date, end_date, branchID) => dispatch(actions.exportData(start_date, end_date, branchID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportData)

