import { ConfigActionTypes } from "./actionType";
import * as API from '../../../api/configAPI';
import errorHandler from '../../../utility/errorHandler/errorHandler';
import storage from "../../../utility/storage";

export const fetchConfig = () => dispatch => dispatch({
    type: ConfigActionTypes.PULL_CONFIG,
    payload: API.fetchConfig()
        .then(response => {
            return response.data;
        })
        .catch(error => {
            storage.set('fetchConfig', error)
            console.log(error);
            errorHandler(error);
            return error;
        })
});

export const reportError = (error, route, apiUrl, request_body) => dispatch => dispatch({
    type: ConfigActionTypes.REPORT_ERROR,
    payload: API.reportError(error, route, apiUrl, request_body)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.log(error);
            errorHandler(error);
            return error;
        })
});