import React, { useEffect, useState } from 'react'
import AddUser from '../../components/Settings/AddUser'
import Notification from '../../components/Settings/Notification'
import { connect } from 'react-redux'
import * as actions from '../../redux/actions/index'
import { showConfirmAlert } from '../../utility/successAlert/confirmAlert'
import SpinnerLoader from '../../components/UI/SpinnerLoader/SpinnerLoader'

const PlatformSettings = (props) => {

    useEffect(() => {
        props.fetchSettings()
    }, [])

    const onToggleNotificationSetting = (event) => {

        if (event.target.checked) {
            showConfirmAlert("Please Confirm", 'Are you sure you want to turn on the notifications?', () => {
                props.toggleNotifycation("start")
                    .then(response => {
                        if (response.value.success || response.value.success === "true") {
                            props.fetchSettings()
                        }
                    }).catch(error => {
                        console.log(error)
                    })
            })
        }
        else {
            showConfirmAlert("Please Confirm", 'Are you sure you want to turn off the notifications?', () => {
                props.toggleNotifycation("stop")
                    .then(response => {
                        if (response.value.success || response.value.success === "true") {
                            props.fetchSettings()
                        }
                    }).catch(error => {
                        console.log(error)
                    })
            })
        }
    }

    return (
        <section class="theme_nav_mt">
            <div class="container">
                {
                    props.isLoading && (!props.settings || (props.settings && props.settings.length === 0))
                        ? <SpinnerLoader />
                        : <div class="vertical_tabs_cont">
                            <div class="vertical_tabs_colL vertical_tabs_col">
                                <div class="tab_list_block tab_list_block_767">
                                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        {/* <a class="nav-link active" id="add_user_tab" data-toggle="pill" href="#add_user" role="tab" aria-controls="add_user" aria-selected="false">Add User</a> */}
                                        <a class="nav-link active" id="notification_settings_tab" data-toggle="pill" href="#notification_settings" role="tab" aria-controls="notification_settings" aria-selected="false">Notification settings</a>
                                    </div>
                                </div>
                            </div>
                            <div class="vertical_tabs_colR vertical_tabs_col">
                                <div class="tab_list_block">
                                    <div class="tab-content mt-72 v_tabs_content input_pro_edit_tabs" id="v-pills-tabContent">
                                        {/* <AddUser {...props} /> */}
                                        <Notification
                                            onToggleNotificationSetting={onToggleNotificationSetting}
                                            {...props}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.orderReducer.isLoading,
        settings: state.orderReducer.settings,
        selectedBranch: state.orderReducer.selectedBranch,
        isSettingsLoading: state.orderReducer.isSettingsLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleNotifycation: (status) => dispatch(actions.toggleNotifycation(status)),
        fetchSettings: () => dispatch(actions.fetchSettings())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformSettings)