import React, { useState, useEffect, useRef, useCallback } from 'react'
import OrdersPage from '../../components/OrdersPage/OrdersPage'
import { routes, OrderStatus, ViewOrderLimitPerPage } from '../../utility/constants/constants'
import { connect, useSelector } from 'react-redux';
import * as actions from '../../redux/actions/index'
import socketIOClient from "socket.io-client";
import { SOCKET_URL } from '../../config';
import moment from 'moment'
import 'moment-timezone'
import store from '../../redux/store/store';

const Orders = props => {
    const didMountRef = useRef(false);
    const [orderStatus, setOrderStatus] = useState(OrderStatus.CUSTOMER_ARRIVED)
    const [searchText, setSearchText] = useState('')
    const searchTextRef = useRef('')
    const [errorMsg, setErrorMsg] = useState('')
    let [pageNumber, setPageNumber] = useState(1)
    let socketOrders = []
    let isSocketComputing = useRef(false);
    let socketInitialized = useRef(false);
    let prevOrdersRef = useRef()
    const socket = socketIOClient(SOCKET_URL(), { 'transports': ['websocket', 'polling'] });

    useEffect(() => {
        const timer = () => setTimeout(() => window.location.reload(), 30000);
        const timerId = timer();
        return () => {
            clearTimeout(timerId);
        };

    });

    useEffect(() => {
        prevOrdersRef.current = props.orders;
    }, [props.orders])

    const prevOrders = prevOrdersRef.current;

    useEffect(() => {
        let status = props.match.params.status;
        let end_date = new Date()
        let start_date = new Date()
        start_date.setDate(end_date.getDate() - 7)
        end_date = moment(end_date).format('MM-DD-YYYY')
        start_date = moment(start_date).format('MM-DD-YYYY')
        if (status === OrderStatus.CUSTOMER_ARRIVED.key && orderStatus !== OrderStatus.CUSTOMER_ARRIVED.key) {
            props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, 1, OrderStatus.CUSTOMER_ARRIVED.apiKey)
                .then(response => {
                    window.$('[data-toggle="tooltip"]').tooltip()
                }).catch(error => {
                    console.log(error)
                })
            setOrderStatus(OrderStatus.CUSTOMER_ARRIVED);
            setSearchText('')
        } else if (status === OrderStatus.INVOICED.key && orderStatus !== OrderStatus.INVOICED.key) {
            props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, 1, [OrderStatus.INVOICED.apiKey, OrderStatus.INVOICED.alternativeApiKey], start_date, end_date)
                .then(response => {
                    window.$('[data-toggle="tooltip"]').tooltip()
                }).catch(error => {
                    console.log(error)
                })
            setOrderStatus(OrderStatus.INVOICED);
            setSearchText('')
        } else if (status === OrderStatus.PICK_CONFIRMED.key && orderStatus !== OrderStatus.PICK_CONFIRMED.key) {
            props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, 1, OrderStatus.PICK_CONFIRMED.apiKey)
                .then(response => {
                    window.$('[data-toggle="tooltip"]').tooltip()
                }).catch(error => {
                    console.log(error)
                })
            setOrderStatus(OrderStatus.PICK_CONFIRMED);
            setSearchText('')
        }
    }, [props.match.params.status])

    useEffect(() => {
        let status = props.match.params.status;
        if (didMountRef.current) {
            if (!socketInitialized.current) {
                socketInitialized.current = true
                socket.on('user_connection', (msg) => {
                    console.log(msg, "socketMsg")
                    props.toggleSocketStatus(false)
                    socket.on(OrderStatus.CUSTOMER_ARRIVED.socket_key, (socket_orders) => {
                        if (!searchTextRef.current) {
                            console.log(socket_orders, "arrivedSocket")
                            socketOrders = [...socketOrders, ...socket_orders]
                            if (!isSocketComputing.current) {
                                isSocketComputing.current = true
                                updateSocketOrders(OrderStatus.CUSTOMER_ARRIVED.key)
                            }
                        }
                    });
                    socket.on(OrderStatus.INVOICED.socket_key, (socket_orders) => {
                        if (!searchTextRef.current) {
                            console.log(socket_orders, "invoicedSocket")
                            socketOrders = [...socketOrders, ...socket_orders]
                            if (!isSocketComputing.current) {
                                isSocketComputing.current = true
                                updateSocketOrders(OrderStatus.INVOICED.key)
                            }
                        }
                    });
                    socket.on(OrderStatus.PICK_CONFIRMED.socket_key, (socket_orders) => {
                        if (!searchTextRef.current) {
                            console.log(socket_orders, "confirmedSocket")
                            socketOrders = [...socketOrders, ...socket_orders]
                            if (!isSocketComputing.current) {
                                isSocketComputing.current = true
                                updateSocketOrders(OrderStatus.PICK_CONFIRMED.key)
                            }
                        }
                    });
                    socket.on('disconnect', function () {
                        console.log('Got disconnect!');
                        props.toggleSocketStatus(true)
                    });
                })
            }
            window.$('[data-toggle="tooltip"]').tooltip()
        } else {
            didMountRef.current = true
            let end_date = new Date()
            let start_date = new Date()
            start_date.setDate(end_date.getDate() - 7)
            end_date = moment(end_date).format('MM-DD-YYYY')
            start_date = moment(start_date).format('MM-DD-YYYY')
            let status = props.match.params.status;
            let allowedStatus = Object.keys(OrderStatus);
            if (!status || (status && !allowedStatus.includes(status.toUpperCase()))) {
                props.history.push(routes.ORDERS + `/${orderStatus.key}`);
                if (((props.orders && props.orders.length === 0) || !props.orders) && !props.isLoading) {
                    props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, pageNumber, orderStatus.apiKey)
                        .then(response => {
                            window.$('[data-toggle="tooltip"]').tooltip()
                        }).catch(error => {
                            console.log(error)
                        })
                }
            } else {
                if (status === OrderStatus.CUSTOMER_ARRIVED.key) {
                    setOrderStatus(OrderStatus.CUSTOMER_ARRIVED)
                    if (((props.orders && props.orders.length === 0) || !props.orders) && !props.isLoading) {
                        props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, pageNumber, OrderStatus.CUSTOMER_ARRIVED.apiKey)
                            .then(response => {
                                window.$('[data-toggle="tooltip"]').tooltip()
                            }).catch(error => {
                                console.log(error)
                            })
                    }
                } else if (status === OrderStatus.INVOICED.key && !props.isLoading) {
                    setOrderStatus(OrderStatus.INVOICED)
                    if ((props.orders && props.orders.length === 0) || !props.orders) {
                        props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, pageNumber, [OrderStatus.INVOICED.apiKey, OrderStatus.INVOICED.alternativeApiKey], start_date, end_date)
                            .then(response => {
                                window.$('[data-toggle="tooltip"]').tooltip()
                            }).catch(error => {
                                console.log(error)
                            })
                    }
                } else if (status === OrderStatus.PICK_CONFIRMED.key && !props.isLoading) {
                    setOrderStatus(OrderStatus.PICK_CONFIRMED)
                    if ((props.orders && props.orders.length === 0) || !props.orders) {
                        props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, pageNumber, OrderStatus.PICK_CONFIRMED.apiKey)
                            .then(response => {
                                window.$('[data-toggle="tooltip"]').tooltip()
                            }).catch(error => {
                                console.log(error)
                            })
                    }
                }
            }
        }
    }, [props.orders])

    const updateSocketOrders = (socketType) => {
        console.log(props.orders, "reduxPropsOrders")
        const storeState = store.getState();
        let ordersList = storeState.orderReducer.orders
        let selectedSocketOrder = socketOrders[0]
        let count = storeState.orderReducer.totalCount
        let removedOrder = null
        console.log(ordersList, "ordersListBefore")
        if (socketOrders && socketOrders.length > 0) {
            ordersList = ordersList.filter((orderItem, index) => {
                var flag = false
                if (selectedSocketOrder.OrderNumber === orderItem.OrderNumber) {
                    flag = true
                    removedOrder = ordersList[index]
                    if (orderStatus.key === OrderStatus.INVOICED.key) {
                        console.log("invoicedIf")
                        if (orderStatus.apiKey === orderItem.OrderStatus || orderStatus.alternativeApiKey === orderItem.OrderStatus) {
                            console.log("oldCountBeforeRemove", count)
                            count = count - 1
                            console.log(count, "countAfterRemoving")
                        }
                    } else if (orderStatus.key === OrderStatus.PICK_CONFIRMED.key) {
                        console.log("confirmedIf")
                        if (orderStatus.apiKey === orderItem.OrderStatus) {
                            console.log("oldCountBeforeRemove", count)
                            count = count - 1
                            console.log(count, "countAfterRemoving")
                        }
                    } else {
                        console.log("arrivedIf")
                        if (orderStatus.apiKey === orderItem.LocalOrderStatus) {
                            console.log("oldCountBeforeRemove", count)
                            count = count - 1
                            console.log(count, "countAfterRemoving")
                        }
                    }
                }
                return !flag
            });
            if (socketType === orderStatus.key) {
                if (orderStatus.key === OrderStatus.INVOICED.key) {
                    console.log("invoicedIf")
                    if (orderStatus.apiKey === selectedSocketOrder.OrderStatus || orderStatus.alternativeApiKey === selectedSocketOrder.OrderStatus) {
                        console.log("oldCountBeforeAdd", count)
                        if (removedOrder.OrderStatus !== selectedSocketOrder.OrderStatus) {
                            count = count + 1
                        }
                        console.log(count, "countAfterAdding")
                    }
                }
                else if (orderStatus.key === OrderStatus.PICK_CONFIRMED.key) {
                    console.log("confirmedIf")
                    if (orderStatus.apiKey === selectedSocketOrder.OrderStatus && !selectedSocketOrder.LocalOrderStatus) {
                        console.log("oldCountBeforeAdd", count)
                        if (removedOrder.OrderStatus !== selectedSocketOrder.OrderStatus || (!removedOrder.LocalOrderStatus && !selectedSocketOrder.LocalOrderStatus)) {
                            count = count + 1
                        }
                        console.log(count, "countAfterAdding")
                    }
                } else {
                    console.log("arrivedIf")
                    if (orderStatus.apiKey === selectedSocketOrder.LocalOrderStatus) {
                        console.log("oldCountBeforeAdd", count)
                        if (removedOrder.OrderStatus !== selectedSocketOrder.OrderStatus || (!removedOrder.LocalOrderStatus && selectedSocketOrder.LocalOrderStatus)) {
                            count = count + 1
                        }
                        console.log(count, "countAfterAdding")
                    }
                }
            }
            if (socketType === OrderStatus.INVOICED.key && orderStatus.key === OrderStatus.INVOICED.key) {
                if (ordersList.length < 40) {
                    ordersList = [selectedSocketOrder, ...ordersList]
                }
            } else {
                if (ordersList.length < 40) {
                    ordersList = [...ordersList, selectedSocketOrder]
                }
            }
            socketOrders.shift()
            if (socketOrders.length > 0) {
                updateSocketOrders(socketType)
            } else {
                isSocketComputing.current = false
                // ordersList = [...ordersList, ...socketOrders];
                console.log(ordersList, "ordersListAfter")
                props.storeLatestOrders([...ordersList])
                if (!count || count < 0) {
                    count = 0
                }
                props.updateTotalOrderCount(count)
            }
        }
    }

    const changePageNumber = () => {
        setPageNumber(pageNumber + 1)
    }

    const toggleOrderStatus = (status) => {
        setOrderStatus(status)
        setSearchText('')
        setPageNumber(1)
        // if (status.key === OrderStatus.INVOICED.key) {
        //     let end_date = new Date()
        //     let start_date = new Date()
        //     start_date.setDate(end_date.getDate() - 7)
        //     end_date = moment(end_date).format('MM-DD-YYYY')
        //     start_date = moment(start_date).format('MM-DD-YYYY')
        //     console.log(end_date, "end_date", start_date, "start_date")
        //     props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, 1, [status.apiKey, status.alternativeApiKey], start_date, end_date)
        // } else {
        //     props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, 1, status.apiKey)
        // }
        props.history.push(routes.ORDERS + `/${status.key}`);
    }

    const onChangeSearchText = (event) => {
        var regExp = /[a-zA-Z]/g;
        setSearchText(event.target.value)
        searchTextRef.current = event.target.value
        if (!event.target.value) {
            setErrorMsg('')
        }
        // else if (!/^[0-9\s]+$/.test(event.target.value)) {
        //     setErrorMsg('Order number should be in digits only')
        // }
        else if (!regExp.test(event.target.value) && event.target.value.length < 5) {
            setErrorMsg('Please add minimum of 5 digit order number')
        } else {
            setErrorMsg('')
        }
    }

    const onClickLoadMore = () => {
        let newPageNumber = pageNumber + 1
        console.log(newPageNumber)
        setPageNumber(newPageNumber)
        let end_date = new Date()
        let start_date = new Date()
        start_date.setDate(end_date.getDate() - 7)
        end_date = moment(end_date).format('MM-DD-YYYY')
        start_date = moment(start_date).format('MM-DD-YYYY')
        if (orderStatus.key === OrderStatus.INVOICED.key) {
            console.log("invoicedLoadMore")
            props.loadMoreOrders(props.user.BranchID, ViewOrderLimitPerPage, newPageNumber, [orderStatus.apiKey, orderStatus.alternativeApiKey], start_date, end_date)
        } else {
            props.loadMoreOrders(props.user.BranchID, ViewOrderLimitPerPage, newPageNumber, orderStatus.apiKey)
        }
    }

    const onClickResetBtn = () => {
        if (searchText) {
            setSearchText('')
            setErrorMsg('')
            setPageNumber(1)
            if (orderStatus.key === OrderStatus.INVOICED.key) {
                let end_date = new Date()
                let start_date = new Date()
                start_date.setDate(end_date.getDate() - 7)
                end_date = moment(end_date).format('MM-DD-YYYY')
                start_date = moment(start_date).format('MM-DD-YYYY')
                props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, 1, [orderStatus.apiKey, orderStatus.alternativeApiKey], start_date, end_date)
            } else {
                props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, 1, orderStatus.apiKey)
            }
        }
    }

    const onClickSearchBtn = (event) => {
        event.preventDefault()
        let orderNumber = null
        var regExp = /[a-zA-Z]/g;
        regExp.test(searchText) ? orderNumber = searchText : orderNumber = searchText.replace(/\s/g, "")
        if (orderNumber) {
            if (orderStatus.key === OrderStatus.INVOICED.key) {
                props.searchOrderByOrderNumber(orderNumber, false, [OrderStatus.INVOICED.apiKey, OrderStatus.INVOICED.alternativeApiKey])
            } else {
                props.searchOrderByOrderNumber(orderNumber, false, [orderStatus.apiKey])
            }
        }
        else {
            // if (orderStatus.key === OrderStatus.INVOICED.key) {
            //     props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, pageNumber, [orderStatus.apiKey, orderStatus.alternativeApiKey])
            // } else {
            //     props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, pageNumber, orderStatus.apiKey)
            // }
        }
    }
    return (
        <OrdersPage
            history={props.history}
            orderStatus={orderStatus}
            isLoading={props.isLoading}
            isOrderUpdating={props.isOrderUpdating}
            orders={props.orders}
            storeOrderToUpdate={props.storeOrderToUpdate}
            searchText={searchText}
            currentPage={pageNumber}
            user={props.user}
            isLoadMoreLoading={props.isLoadMoreLoading}
            errorMsg={errorMsg}
            updateOrder={props.updateOrder}
            orderToUpdate={props.orderToUpdate}
            fetchOrdersByBranchID={props.fetchOrdersByBranchID}
            onClickLoadMore={onClickLoadMore}
            onClickSearchBtn={onClickSearchBtn}
            onClickResetBtn={onClickResetBtn}
            setOrderStatus={setOrderStatus}
            totalCount={props.totalCount}
            match={props.match}
            movingOrderType={props.movingOrderType}
            toggleReasonToMoveOrdersModalStatus={props.toggleReasonToMoveOrdersModalStatus}
            toggleDeliveryModalStatus={props.toggleDeliveryModalStatus}
            onChangeSearchText={onChangeSearchText}
            isSocketDisconnected={props.isSocketDisconnected}
            toggleOrderStatus={toggleOrderStatus}
            orderSummeryDetails={props.orderSummeryDetails}
        />

    )
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        branches: state.configReducer.branches,
        orders: state.orderReducer.orders,
        isLoading: state.orderReducer.isLoading,
        isLoadMoreLoading: state.orderReducer.isLoadMoreLoading,
        isOrderUpdating: state.orderReducer.isOrderUpdating,
        orderToUpdate: state.orderReducer.orderToUpdate,
        totalCount: state.orderReducer.totalCount,
        movingOrderType: state.historyReducer.movingOrderType,
        isSocketDisconnected: state.historyReducer.isSocketDisconnected
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrdersByBranchID: (branchID, limit, pageNumber, status, start_date, end_date) => dispatch(actions.fetchOrdersByBranchID(branchID, limit, pageNumber, status, start_date, end_date)),
        storeOrderToUpdate: (orderToUpdate) => dispatch(actions.storeOrderToUpdate(orderToUpdate)),
        storeLatestOrders: (orders) => dispatch(actions.storeLatestOrders(orders)),
        searchOrderByOrderNumber: (orderNumber, directSearch, status) => dispatch(actions.searchOrderByOrderNumber(orderNumber, directSearch, status)),
        loadMoreOrders: (branchID, limit, pageNumber, status, start_date, end_date) => dispatch(actions.loadMoreOrders(branchID, limit, pageNumber, status, start_date, end_date)),
        updateOrder: (orderID, order) => dispatch(actions.updateOrder(orderID, order)),
        toggleDeliveryModalStatus: (status) => dispatch(actions.toggleDeliveryModalStatus(status)),
        updateTotalOrderCount: (count) => dispatch(actions.updateTotalOrderCount(count)),
        toggleReasonToMoveOrdersModalStatus: (status, orderType) => dispatch(actions.toggleReasonToMoveOrdersModalStatus(status, orderType)),
        toggleSocketStatus: (status) => dispatch(actions.toggleSocketStauts(status)),
        orderSummeryDetails: (orderAllDetails) => dispatch(actions.orderSummeryDetails(orderAllDetails)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)