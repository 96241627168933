import React, { Component, useState, useEffect } from 'react';
import Aux from '../Oux/Oux';
import { routes, UserRoles, ViewOrderLimitPerPage, OrderStatus } from '../../utility/constants/constants';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { defaultImagePath } from '../../utility/utility';
import ArrivedForDeliveryModal from '../../components/Modal/ArrivedForDeliveryModal';
import * as actions from '../../redux/actions/index'
import { NavLink } from 'react-router-dom';
import storage from '../../utility/storage';
import Oux from '../Oux/Oux';
import ReasonToMoveOrderModal from '../../components/Modal/ReasonToMoveOrdersModal';

const Header = props => {
    let headerContent = (
        <header className="main_header">
            <nav className="theme_navigation -closed navbar navbar-expand-lg">
                <a onClick={props.onClickLogo} className="logo">
                    <img src={defaultImagePath('thumbnails/logo.png')} alt="BitCot Logo" className="header__logo" />
                </a>
                {props.selectedBranch
                    ? <div class="nav_both_wrp">
                        <ul class="nav navbar-nav justify-content-end">
                            <li class="nav-item ims_location">
                                <ul class="profile_dropdown avatar_dropdown branch_id_dropdown">
                                    <li class="nav-item ims_location">
                                        <a class="nav-link a-btn not_line">
                                            IMS, {props.selectedBranch.Name}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    : null}
            </nav>
        </header>
    )
    if (props.user) {
        if (props.user.role && (props.user.role.toLowerCase() === UserRoles.admin.toLowerCase() || props.user.role.toLowerCase() === UserRoles.branchadmin.toLowerCase()) ) {
            headerContent = (
                <header className="main_header">
                    <nav className="theme_navigation -closed navbar navbar-expand-lg">
                        <a onClick={props.onClickLogo} className="logo">
                            <img src={defaultImagePath('thumbnails/logo.png')} alt="BitCot Logo" className="header__logo" />
                        </a>
                        <span className="navigation-toggler">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </span>
                        <div class="nav_both_wrp">
                            {
                                props.selectedBranch
                                    ? <ul class="nav navbar-nav">
                                        <li class="nav-item"> <NavLink className="nav-link" to={routes.DASHBOARD}>Dashboard</NavLink> </li>
                                        <li class="nav-item"> <NavLink className="nav-link" to={routes.SETTINGS}>Setting</NavLink> </li>
                                    </ul>
                                    : null
                            }
                            <ul class="nav navbar-nav justify-content-end">
                                <li class="nav-item ims_admin">
                                    <ul class="profile_dropdown avatar_dropdown">
                                        <li class="dropdown">
                                            <a href="#" class="dropdown-toggle nav-link user_menu_dropdown not_line" data-toggle="dropdown"
                                                aria-haspopup="true" role="button" aria-expanded="false">
                                                <span class="avatar_circle">
                                                    <img src={defaultImagePath('icons/admin.svg')} alt="User" />
                                                </span>
                                            </a>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                {
                                                    props.user.role.toLowerCase() === UserRoles.admin.toLowerCase() ? 
                                                    <li role="presentation" class="list_item active">
                                                        <NavLink role="menuitem" onClick={() => props.onChangeBranch(null)} className="non_href_a_tag" to={routes.PLATFORM_SETTINGS} tabindex="-1">Platform Setting</NavLink>
                                                    </li> 
                                                    : null
                                                }
                                                <li role="presentation" class="list_item">
                                                    <a role="menuitem" className="non_href_a_tag" onClick={props.logout} tabindex="-1">Log Out</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item ims_location">
                                    <ul class="profile_dropdown avatar_dropdown branch_id_dropdown">
                                        <li class="dropdown">
                                            <a href="#" class="dropdown-toggle user_menu_dropdown nav-link a-btn a-btn-primry-b not_line" data-toggle="dropdown"
                                                aria-haspopup="true" role="button" aria-expanded="false">
                                                Logs
                                            </a>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                <li role="presentation" class="list_item">
                                                    <NavLink role="menuitem" className="non_href_a_tag" to={routes.NOTIFICATION_LOGS} tabindex="-1">Notification Logs</NavLink>
                                                </li>
                                                <li role="presentation" class="list_item">
                                                    <NavLink role="menuitem" className="non_href_a_tag" to={routes.ORDER_LOGS} tabindex="-1">Order Logs</NavLink>
                                                </li>
                                                <li role="presentation" class="list_item">
                                                    <NavLink role="menuitem" className="non_href_a_tag" to={routes.AUDIT_LOGS} tabindex="-1">Audit Logs</NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                {
                                    props.user.role.toLowerCase() === UserRoles.admin.toLowerCase() ?
                                    <li class="nav-item ims_location">
                                        <ul class="profile_dropdown avatar_dropdown branch_id_dropdown">
                                            <li class="dropdown">
                                                {
                                                    props.selectedBranch
                                                        ? <a href="#" class="dropdown-toggle user_menu_dropdown nav-link a-btn a-btn-primry-b not_line" data-toggle="dropdown"
                                                            aria-haspopup="true" role="button" aria-expanded="false">
                                                            Branch - {props.selectedBranch.Name}
                                                        </a>
                                                        : <a href="#" class="dropdown-toggle user_menu_dropdown nav-link a-btn a-btn-primry-b not_line" data-toggle="dropdown"
                                                            aria-haspopup="true" role="button" aria-expanded="false">
                                                            All Branches
                                                        </a>
                                                }
                                                <ul class="dropdown-menu dropdown-menu-right">
                                                    <li onClick={() => props.onChangeBranch(null)} role="presentation" class="list_item">
                                                        <a role="menuitem" className="non_href_a_tag" tabindex="-1">All Branches</a>
                                                    </li>
                                                    {
                                                        props.branches && props.branches.length > 0
                                                            ? props.branches.map((branch, index) => {
                                                                return (
                                                                    <li onClick={() => props.onChangeBranch(branch)} role="presentation" key={index} class="list_item">
                                                                        <a role="menuitem" className="non_href_a_tag" tabindex="-1">{branch.Name}</a>
                                                                    </li>
                                                                )
                                                            })
                                                            : null
                                                    }
                                                </ul>
                                            </li>
                                        </ul>
                                    </li> :
                                    
                                    props.branches && props.branches.length > 0 ? 
                                    props.branches.map((branch, index) => {
                                        if (props.user.BranchID === branch.BranchID || props.user.BranchID === branch.BranchID.toString()) {
                                            return (
                                                <li key={index} class="nav-item ims_location">
                                                    <a class="nav-link a-btn not_line">
                                                        IMS, {branch.Name}
                                                    </a>
                                                </li>
                                            )
                                        }
                                    })
                                    : null
                                }
                            </ul>
                        </div>
                    </nav>
                </header>

            )
        } else {
            headerContent = (
                <header class="main_header">
                    <nav class="theme_navigation -closed navbar navbar-expand-lg">
                        <a onClick={props.onClickLogo} class="logo">
                            <img src={defaultImagePath('thumbnails/logo.png')} alt="BitCot Logo" class="header__logo" />
                        </a>
                        <span class="navigation-toggler">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </span>
                        <div class="nav_both_wrp">
                            <ul class="nav navbar-nav">
                                <li class="nav-item"> <a class="nav-link active">Willcall Orders</a> </li>

                            </ul>
                            <ul class="nav navbar-nav justify-content-end">
                                {
                                    props.branches && props.branches.length > 0
                                        ? props.branches.map((branch, index) => {
                                            if (props.user.BranchID === branch.BranchID || props.user.BranchID === branch.BranchID.toString()) {
                                                return (
                                                    <li key={index} class="nav-item ims_location">
                                                        <a class="nav-link a-btn not_line">
                                                            IMS, {branch.Name}
                                                        </a>
                                                    </li>
                                                )
                                            }
                                        })
                                        : null
                                }
                                <li class="nav-item ims_location">
                                    <a onClick={props.logout} class="nav-link a-btn not_line">
                                        LOGOUT
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>

            )
        }
    }
    return headerContent
}

const Sub_header = props => {
    return (
        <div class="Socket_disconnect_banner " id="Socket_disconnect">
            <h3 class="" style={{ fontSize: '20px' }}>Socket disconnected! Please wait for few minutes or check your internet connection and refresh the page to reconnect.</h3>
        </div>
    )
}

const Footer = props => {
    return (
        <footer className="main_footer wow fadeIn">
            <div className="link_list_group">
                <span className="ph_textcopy footer_link">©2020 Industrial Metal Supply Company - V 4.2</span>
            </div>
        </footer>
    )
}

const Layout = (props) => {
    const [parkingSlot, setParkingSlot] = useState('')
    const [reasonText, setReasonText] = useState('')
    const [selectedBranch, setSelectedBranch] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [reasonTextErrorMsg, setReasonTextErrorMsg] = useState('')
    let parkingSlotRef = React.createRef()
    let reasonTextRef = React.createRef()

    useEffect(() => {
        let branch = storage.get('selectedBranch', null)
        if (branch && (!selectedBranch || (selectedBranch && selectedBranch.BranchID !== branch.BranchID))) {
            setSelectedBranch(branch)
            props.storeSelectedBranch(branch)
        } else if(props.selectedBranch){
            setSelectedBranch(props.selectedBranch)
            storage.set('selectedBranch', props.selectedBranch)
        }
        else if (props.branches && props.branches.length > 0 && !selectedBranch && props.user) {
            if (props.user.role !== UserRoles.admin) {
                props.branches.map(branch => {
                    if (branch.BranchID.toString() === props.user.BranchID) {
                        props.storeSelectedBranch(branch)
                        setSelectedBranch(branch)
                        storage.set('selectedBranch', branch)
                    }
                })
            }
        }
    }, [props.branches, props.user])

    const onClickFocus = (ref) => {
        if (ref === 'parkingSlot') {
            parkingSlotRef.current.focus()
        } else {
            reasonTextRef.current.focus()
        }
    }

    const onChangeParkingSlot = (event) => {
        if (event.target.value.match('^[a-zA-Z0-9.]*$')) {
            let flag = false
            if (event.target.value == Math.floor(event.target.value)) {
                if (props.selectedBranch.Name.toLowerCase() === 'SUN VALLEY'.toLowerCase()) {
                    flag = true
                }
                if (flag) {
                    if (event.target.value.match('^[0-9.]*$') && event.target.value > 0 && event.target.value < 13) {
                        setErrorMsg('')
                    } else {
                        setErrorMsg('Parking slot should be between 1-12')
                    }
                } else {
                    setErrorMsg('')
                }
            } else {
                setErrorMsg('Invalid Parking Slot!')
            }
        } else {
            setErrorMsg('Parking Slot should not contain any special charaters!')
        }
        setParkingSlot(event.target.value)
    }

    const onChangeReasonText = (event) => {
        setReasonText(event.target.value)
        if (!event.target.value) {
            setReasonTextErrorMsg('This field is required')
        }
        else if (event.target.value.length > 100) {
            setReasonTextErrorMsg('Only 100 chars are allowed')
        }
        else if (event.target.value) {
            setReasonTextErrorMsg('')
        }
    }

    const onChangeBranch = (branch) => {
        props.history.push(routes.DASHBOARD)
        setSelectedBranch(branch)
        props.storeSelectedBranch(branch)
        storage.set('selectedBranch', branch)
    }

    const onClickLogo = () => {
        if (props.user.role === UserRoles.admin) {
            props.history.push(routes.DASHBOARD)
        }
        else if (!(props.history.location.pathname.includes(OrderStatus.CUSTOMER_ARRIVED.key))) {
            props.fetchOrdersByBranchID(props.user.BranchID, ViewOrderLimitPerPage, 1, OrderStatus.CUSTOMER_ARRIVED.apiKey)
            props.history.push(`${routes.ORDERS}/${OrderStatus.CUSTOMER_ARRIVED.key}`)
        }
    }

    return (
        <Aux>

            <div className="limiter">
                <div className="container-login100">
                    <Header onClickLogo={onClickLogo} onChangeBranch={onChangeBranch} selectedBranch={selectedBranch} {...props} />
                    <div id="wrap">
                        {props.isSocketDisconnected ? <Sub_header /> : null}
                        {props.children}
                    </div>
                    <Footer {...props} />
                </div>
            </div>
            {
                props.user && props.user.role.toLowerCase() !== UserRoles.admin.toLowerCase()
                    ? <Oux>
                        <ArrivedForDeliveryModal
                            reasonText={reasonText}
                            reasonTextRef={reasonTextRef}
                            setReasonText={setReasonText}
                            onChangeReasonText={onChangeReasonText}
                            parkingSlot={parkingSlot}
                            errorMsg={errorMsg}
                            reasonTextErrorMsg={reasonTextErrorMsg}
                            setReasonTextErrorMsg={setReasonTextErrorMsg}
                            onClickFocus={onClickFocus}
                            arrivedForDeliveryModalStatus={props.arrivedForDeliveryModalStatus}
                            parkingSlotRef={parkingSlotRef}
                            setParkingSlot={setParkingSlot}
                            onChangeParkingSlot={onChangeParkingSlot}
                            updateOrder={props.updateOrder}
                            history={props.history}
                            setErrorMsg={setErrorMsg}
                            toggleDeliveryModalStatus={props.toggleDeliveryModalStatus}
                            fetchOrdersByBranchID={props.fetchOrdersByBranchID}
                            user={props.user}
                            updateTotalOrderCount={props.updateTotalOrderCount}
                            isOrderUpdating={props.isOrderUpdating}
                            isOrderLoading={props.isOrderLoading}
                            orderToUpdate={props.orderToUpdate} />
                        <ReasonToMoveOrderModal
                            reasonText={reasonText}
                            errorMsg={reasonTextErrorMsg}
                            onClickFocus={onClickFocus}
                            reasonToMoveOrdersModalStatus={props.reasonToMoveOrdersModalStatus}
                            reasonTextRef={reasonTextRef}
                            setReasonText={setReasonText}
                            onChangeReasonText={onChangeReasonText}
                            updateOrder={props.updateOrder}
                            history={props.history}
                            setErrorMsg={setErrorMsg}
                            toggleReasonToMoveOrdersModalStatus={props.toggleReasonToMoveOrdersModalStatus}
                            fetchOrdersByBranchID={props.fetchOrdersByBranchID}
                            user={props.user}
                            movingOrderType={props.movingOrderType}
                            updateTotalOrderCount={props.updateTotalOrderCount}
                            isOrderUpdating={props.isOrderUpdating}
                            isOrderLoading={props.isOrderLoading}
                            orderToUpdate={props.orderToUpdate} />
                    </Oux>
                    : null
            }

        </Aux>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        branches: state.configReducer.branches,
        isOrderUpdating: state.orderReducer.isOrderUpdating,
        orderToUpdate: state.orderReducer.orderToUpdate,
        isOrderLoading: state.orderReducer.isLoading,
        arrivedForDeliveryModalStatus: state.historyReducer.arrivedForDeliveryModalStatus,
        selectedBranch: state.orderReducer.selectedBranch,
        reasonToMoveOrdersModalStatus: state.historyReducer.reasonToMoveOrdersModalStatus,
        movingOrderType: state.historyReducer.movingOrderType,
        isSocketDisconnected: state.historyReducer.isSocketDisconnected
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(actions.logout()),
        updateOrder: (orderID, order) => dispatch(actions.updateOrder(orderID, order)),
        fetchOrdersByBranchID: (branchID, limit, pageNumber, status) => dispatch(actions.fetchOrdersByBranchID(branchID, limit, pageNumber, status)),
        storeSelectedBranch: (branch) => dispatch(actions.storeSelectedBranch(branch)),
        toggleDeliveryModalStatus: (status) => dispatch(actions.toggleDeliveryModalStatus(status)),
        toggleReasonToMoveOrdersModalStatus: (status) => dispatch(actions.toggleReasonToMoveOrdersModalStatus(status)),
        updateTotalOrderCount: (count) => dispatch(actions.updateTotalOrderCount(count))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));