import React from 'react';
import Layout from '../hoc/Layout/Layout'
import { Route, Switch, Redirect } from 'react-router-dom'
import { routes, UserRoles } from '../utility/constants/constants';
import Home from '../containers/Home/Home';
import Dashboard from '../containers/Dashboard/Dashboard';
import Login from '../containers/Login/Login';
import IMSAdmin from '../containers/IMSAdmin/IMSAdmin';
import Orders from '../containers/Orders/Orders';
import PlatformSettings from '../containers/Settings/PlatformSettings';
import BranchSettings from '../containers/Settings/BranchSettings';
import ComingSoon from '../containers/ComingSoon/ComingSoon';
import OrderSearch from '../containers/OrderSearch/OrderSearch';
import OrderDetails from '../containers/OrderDetails/OrderDetails'
import Logs from '../containers/Logs/Logs';


const Router = props => {
    let routeList = null;
    let user = props.user
    if (user) {
        //when user is logged in
        if (user.role.toLowerCase() === UserRoles.admin.toLowerCase() || user.role.toLowerCase() === UserRoles.branchadmin.toLowerCase()) {
            routeList = (
                <Switch>
                    <Route exact path={routes.ROOT} render={(props) => <Redirect to={routes.DASHBOARD} />} />
                    {/* <Route exact path={routes.HOME} component={Home} /> */}
                    <Route exact path={routes.DASHBOARD} component={Dashboard} />
                    <Route exact path={routes.PLATFORM_SETTINGS} component={PlatformSettings} />
                    <Route exact path={routes.SETTINGS} component={BranchSettings} />
                    <Route exact path={routes.IMS_ADMIN} component={IMSAdmin} />
                    <Route exact path={routes.ORDER_SEARCH} component={OrderSearch} />
                    <Route exact path={routes.COMING_SOON} component={ComingSoon} />
                    {/* <Route exact path={routes.AUDIT_LOGS} component={AuditLogs} /> */}
                    <Route path={routes.LOGS} component={Logs} />
                    <Route path='*' render={() => <Redirect to={routes.DASHBOARD} />} />

                </Switch>
            )
        } else {
            routeList = (
                <Switch>
                    <Route exact path={routes.ROOT} render={(props) => <Redirect to={routes.ORDER_STATUS} />} />
                    {/* <Route exact path={routes.HOME} component={Home} /> */}
                    <Route exact path={routes.ORDER_STATUS} component={Orders} />
                    <Route exact path={routes.ORDER_SEARCH} component={OrderSearch} />
                    <Route exact path={routes.ORDER_DETAILS} component={OrderDetails} />
                    <Route path='*' render={() => <Redirect to={routes.ORDER_STATUS} />} />
                </Switch>
            )
        }

    } else {
        //when user is not logged in
        routeList = (
            <Switch>
                <Route exact path={routes.ROOT} render={() => <Redirect to={routes.LOGIN} />} />
                <Route exact path={routes.LOGIN} component={Login} />
                <Route exact path={routes.ORDER_SEARCH} component={OrderSearch} />
                <Route path='*' render={(props) => <Redirect to={routes.LOGIN} />} />
            </Switch>
        )
    }

    return (
        <Layout>
            {routeList}
        </Layout >
    )
}

export default Router

export const NotFound = () => {
    return (
        <h1 className="text-center" style={{ margin: '100px' }}>404. Page not found.</h1>
    );
};