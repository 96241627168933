import React from 'react'
import { connect } from 'react-redux'
import ComingSoonPage from '../../components/ComingSoonPage/ComingSoonPage'

const ComingSoon = props => {
    return (
        <ComingSoonPage {...props} />
    )
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoon)

