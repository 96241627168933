import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import authInterceptor from './utility/interceptors/authInterceptor';
import tokenInterceptor from './utility/interceptors/tokenInterceptor';
import errorHandler from './utility/errorHandler/errorHandler';
import axios, { API_VERSION } from './config';
import store from './redux/store/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import * as actions from './redux/actions/index'
import './assets/custom-styles/sass/style.scss'
import './assets/custom-styles/custom.css'
import storage from './utility/storage';


let refresh_token = storage.get('refresh_token', null)

const refreshAuthLogic = failedRequest =>
  axios.post(API_VERSION + 'auth/refresh_token', {}, {
    headers: {
      'x-refreshtoken': refresh_token
    }
  })
    .then(tokenRefreshResponse => {
      if (tokenRefreshResponse.data.success) {
        storage.set('token', tokenRefreshResponse.data.token);
        storage.set('refresh_token', tokenRefreshResponse.data.refresh_token);
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
      } else {
        store.dispatch(actions.logout())
      }
      return Promise.resolve();
    }).catch(error => {
      store.dispatch(actions.logout())
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic);

axios.interceptors.request.use(authInterceptor, error => Promise.reject(error));
axios.interceptors.response.use(tokenInterceptor, error => errorHandler(error));


const app = (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
