import React from 'react'
import { OrderViewTypes, OrderStatus, routes, ViewOrderLimitPerPage, formInputTextErrorStyle, formInputErrorStyle } from '../../utility/constants/constants'
import { defaultImagePath } from '../../utility/utility'
import CustomersArrived from './CustomersArrived/CustomersArrived'
import PickConfirmed from './PickConfirmed/PickConfirmed'
import Invoiced from './Invoiced/Invoiced'
import LoadingBtn from '../UI/LoadingButton/LoadingButton'

const ordersPage = props => {
    let content = null
    let totalOrders = props.isDirectSearch ? 1 : props.totalCount
    if (props.orderStatus.key === OrderStatus.CUSTOMER_ARRIVED.key) {
        content = <CustomersArrived {...props} />
        // totalOrders = props.orders.filter(item => item.LocalOrderStatus === OrderStatus.CUSTOMER_ARRIVED.apiKey && !(item.OrderStatus === OrderStatus.INVOICED.apiKey || item.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)).length
    } else if (props.orderStatus.key === OrderStatus.INVOICED.key) {
        content = <Invoiced {...props} />
        // totalOrders = props.orders.filter(item => (item.OrderStatus === OrderStatus.INVOICED.apiKey || item.OrderStatus === OrderStatus.INVOICED.alternativeApiKey)).length
    } else if (props.orderStatus.key === OrderStatus.PICK_CONFIRMED.key) {
        content = <PickConfirmed {...props} />
        // totalOrders = props.orders.filter(item => (item.OrderStatus === OrderStatus.PICK_CONFIRMED.apiKey && item.LocalOrderStatus !== OrderStatus.CUSTOMER_ARRIVED.apiKey)).length
    }
    return (
        <section class={props.isSocketDisconnected ? "home_hero disconnected_socket_banner_visible" : "home_hero"} style={{ backgroundImage: `url(${defaultImagePath('background/hero.png')})` }}>
            <div class="container-fluid home_hero_inner">
                <div class="row align-items-center">
                    <div class="col-sm-12 col-md-12 col-lg-10">
                        <div class="search_wrp ims_flex ims_flex_v">
                            <article class="ims_art heading_with_badge">
                                <h1 class="font-weight-bold position-relative">{props.orderStatus.title}</h1>
                                <span class="badge badge_tertiary theme_badge">{totalOrders}</span>
                            </article>
                            {
                                props.isDirectSearch
                                    ? null
                                    : <form className="search_form_container">
                                        <div className="form-group search_form_wrapper" style={{ marginBottom: '0' }}>
                                            <div className="search_wrapper">
                                                <div class="search_icon close_icon" onClick={props.onClickResetBtn}>
                                                    <img src={defaultImagePath('custom_images/icn_close_gray.svg')} class="si_inner" alt="search" />
                                                </div>
                                                {/* <div className="search_icon">
                                        <img src={defaultImagePath('custom_images/icn_search_gray.svg')} class="si_inner" alt="search" />
                                    </div> */}
                                                <input type="text" onChange={props.onChangeSearchText} style={props.errorMsg ? formInputErrorStyle : null} class="form-control search_inline search_field" value={props.searchText} placeholder="Enter the order number or customer name" />
                                                {props.errorMsg ? <span style={{ ...formInputTextErrorStyle, marginLeft: '15px' }}>{props.errorMsg}</span> : null}
                                            </div>
                                            <button type="submit" disabled={props.errorMsg || !props.searchText ? true : false} onClick={props.onClickSearchBtn} class={props.errorMsg || !props.searchText ? "theme_btn theme_tertiary search_btn theme_btn_rds30 text-white ml-2 desktop_search_btn disabled_btn" : "theme_btn theme_tertiary search_btn theme_btn_rds30 text-white ml-2 desktop_search_btn"}>Search</button>
                                        </div>
                                    </form>
                            }
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-2">
                        <div class="ims_filter_cont search_mobile_btn_wrapper">
                            {
                                props.isDirectSearch
                                    ? null
                                    : <input type="submit" disabled={props.errorMsg || !props.searchText ? true : false} onClick={props.onClickSearchBtn} className={props.errorMsg || !props.searchText ? "theme_btn theme_tertiary search_btn theme_btn_rds30 text-white ml-2 mobile_search_btn disabled_btn" : "theme_btn theme_tertiary search_btn theme_btn_rds30 text-white ml-2 mobile_search_btn"} value="Search" />
                            }
                            {props.isDirectSearch
                                ? null
                                : <ul class="profile_dropdown avatar_dropdown">
                                    <li class="dropdown">
                                        <a class="dropdown-toggle user_menu_dropdown nav-link" data-toggle="dropdown" aria-haspopup="true" role="button" aria-expanded="false">
                                            <img src={defaultImagePath('icons/icn_filter.svg')} alt="Icon Filter" />
                                        </a>
                                        <ul class="dropdown-menu dropdown-menu-right">
                                            <li role="presentation" class={props.orderStatus.key === OrderStatus.CUSTOMER_ARRIVED.key ? "list_item active" : "list_item"}>
                                                <a role="menuitem" tabindex="-1" className="non_href_a_tag" onClick={() => props.toggleOrderStatus(OrderStatus.CUSTOMER_ARRIVED)}>Customer Arrived</a>
                                            </li>
                                            <li role="presentation" class={props.orderStatus.key === OrderStatus.PICK_CONFIRMED.key ? "list_item active" : "list_item"}>
                                                <a role="menuitem" tabindex="-1" className="non_href_a_tag" onClick={() => props.toggleOrderStatus(OrderStatus.PICK_CONFIRMED)}>Pick confirmed</a>
                                            </li>
                                            <li role="presentation" class={props.orderStatus.key === OrderStatus.INVOICED.key ? "list_item active" : "list_item"}>
                                                <a role="menuitem" tabindex="-1" className="non_href_a_tag" onClick={() => props.toggleOrderStatus(OrderStatus.INVOICED)}>Invoiced</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            }
                        </div>
                    </div>
                </div>
                {content}
                {
                    !props.isLoading && (props.orders && props.orders.length >= (ViewOrderLimitPerPage * props.currentPage))
                        ? <div style={{ float: 'right' }} className="mb-2">
                            <a onClick={props.onClickLoadMore} class="theme_btn theme_tertiary theme_btn_rds30 text-white">Load More</a>
                        </div>
                        : props.isLoadMoreLoading
                            ? <div style={{ float: 'right' }} className="mb-2">
                                <LoadingBtn btnClassName="theme_btn theme_tertiary theme_btn_rds30 text-white" btnTitle="Please wait..." />
                            </div>
                            : null
                }
            </div>
        </section>
    )
}

export default ordersPage